import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IEmployee } from "../services/authService";

interface EmployeeState {
  employee: IEmployee | null;
  setEmployee: (employee: IEmployee) => void;
  removeEmployee: () => void;
  reset: () => void;
}

const useEmployeeStore = create<EmployeeState>()(
  devtools(
    persist(
      (set) => ({
        employee: null,
        setEmployee: (employee: IEmployee) => set({ employee }),
        removeEmployee: () => set({ employee: null }),
        reset: () => {
          set({ employee: null });
        },
      }),
      {
        name: "employee-storage",
      }
    )
  )
);

export default useEmployeeStore;
