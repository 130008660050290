import { API_URL } from "../utils/constants";

export interface IParkingSpace {
  parking_id: number;
  unit_id: number | null;
  property_id: number;
  parking_code: string;
  created_at: string;
}

export const fetchParkingSpaces = async (
  property_id: number | null = null,
  unit_id: number | null = null
): Promise<IParkingSpace[]> => {
  const params = new URLSearchParams();

  if (property_id !== null) {
    params.append("propertyId", property_id.toString());
  }

  if (unit_id !== null) {
    params.append("unitId", unit_id.toString());
  }

  const response = await fetch(
    `${API_URL}/parking-spaces?${params.toString()}`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.parkingSpaces;
};

export const deleteParkingSpace = async (
  parking_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/parking-spaces/${parking_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const createParkingSpace = async (
  property_id: number,
  unit_id: number | null,
  parking_code: string
): Promise<IParkingSpace> => {
  const response = await fetch(`${API_URL}/parking-spaces`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      property_id,
      unit_id,
      parking_code,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.code || data.message);
};
