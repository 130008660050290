import './file-upload.css';
import React, { CSSProperties, useState } from 'react';
import DocumentUploadIcon from '../../assets/icons/document-upload.png';
import { useTranslation } from 'react-i18next';

export interface UploadedFile {
    file: File;
    filename: string;
    filesize: string;
    progress: number;
    uploaded: boolean;
    url?: string;
    valid_until?: Date | null;
    internal_document?: boolean;
}

interface FileUploadProps {
    style?: CSSProperties | undefined;
    id: string;
    allowedTypes?: string;
    maxFilesToUpload?: number;
    onFileSelected: (files: UploadedFile[]) => void;
    currentFileCount: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
    style,
    id,
    allowedTypes,
    maxFilesToUpload,
    onFileSelected,
    currentFileCount
}) => {
    const { t } = useTranslation();

    // States
    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(false);
        const files = Array.from(event.dataTransfer.files);

        // Check if max files limit is reached
        if (maxFilesToUpload && (files.length + currentFileCount) > maxFilesToUpload) {
            alert(t('components.file_upload.max_files_reached', { maxFilesToUpload }));
            return;
        }

        const uploadedFiles = files.map(file => convertSelectedFile(file));
        onFileSelected(uploadedFiles);
    };

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);

            // Check if max files limit is reached
            if (maxFilesToUpload && (files.length + currentFileCount) > maxFilesToUpload) {
                alert(t('components.file_upload.max_files_reached', { maxFilesToUpload }));
                return;
            }

            const uploadedFiles = files.map(file => convertSelectedFile(file));
            onFileSelected(uploadedFiles);
        }
        event.target.value = '';
    };

    const convertSelectedFile = (selectedFile: File): UploadedFile => {
        const fileSizeInKB = selectedFile.size / 1024;
        const fileSizeDisplay = fileSizeInKB < 1024
            ? `${fileSizeInKB.toFixed(2)} KB`
            : `${(fileSizeInKB / 1024).toFixed(2)} MB`;

        return {
            file: selectedFile,
            filename: selectedFile.name,
            filesize: fileSizeDisplay,
            progress: 100,
            uploaded: true,
        };
    };

    return (
        <label
            htmlFor={id}
            style={style}
            className={`dashed-upload-container d-flex flex-column align-items-center justify-content-center p-3 cursor-pointer ${dragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                id={id}
                type="file"
                multiple
                accept={allowedTypes}
                className="file-upload-input"
                onChange={handleFileInput}
                hidden
            />
            <img src={DocumentUploadIcon} alt="Upload" className="upload-icon mb-2" />
            <p className="my-0 text-upload">
                <span className="text-upload-blue me-1">{t("components.file_upload.click_to_upload_button")}</span>
                {t("components.file_upload.or_drag_and_drop")}
            </p>
            <small className="max-file-size">{t("components.file_upload.max_file_size")}</small>
        </label>
    );
};

export default FileUpload;
