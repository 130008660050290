// appStore.ts
import create from "zustand";

interface AppState {
  isSidebarVisible: boolean;
  setSidebarVisible: (isVisible: boolean) => void;
}

const useAppStore = create<AppState>((set) => ({
  isSidebarVisible: false,
  setSidebarVisible: (isVisible: boolean) =>
    set({ isSidebarVisible: isVisible }),
}));

export default useAppStore;
