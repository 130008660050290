import '../base-modal.css';
import './payment-plan-modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateSelect from '../../components/date-select/date-select';
import { IPaymentPlanItem, createPaymentPlanItem, updatePaymentPlanItem } from '../../services/paymentPlanService';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';

interface ModalProps {
    property_id: number;
    payment_plan_item: IPaymentPlanItem | null;
    onClose: () => void;
    onCreated: (item: IPaymentPlanItem) => void;
    onUpdated: (item: IPaymentPlanItem) => void;
}

const PaymentPlanModal: React.FC<ModalProps> = ({ property_id, payment_plan_item, onClose, onCreated, onUpdated }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const [description, setDescription] = useState<string>("");
    const [percentage, setPercentage] = useState<string>("");
    const [fixedAmount, setFixedAmount] = useState<string>("");

    // Error States
    const [dueDateError, setDueDateError] = useState<string>("");
    const [descriptionError, setDescriptionError] = useState<string>("");

    useEffect(() => {
        if (payment_plan_item) {
            setDueDate(new Date(payment_plan_item.due_date));
            setDescription(payment_plan_item.description);
            setPercentage(formatNumber(payment_plan_item.percentage ?? undefined));
            setFixedAmount(payment_plan_item.fixed_amount?.toString() ?? "");
        }
    }, [payment_plan_item]);

    // Functions
    const formatNumber = (value: number | undefined | null) => {
        if (value === undefined || value === null) return "";
        const formattedValue = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(value);

        return formattedValue;
    };

    const onHandleSavePaymentPlanItem = useCallback(async () => {
        let isValid = true;

        // Check if the description is not empty
        if (!description.trim()) {
            setDescriptionError(t("invalid_input.description_empty"));
            isValid = false;
        }

        if (!dueDate) {
            setDueDateError(t("invalid_input.due_date_empty"));
            isValid = false;
        }

        // Check if either percentage or fixed amount is set
        if (!percentage && !fixedAmount) {
            setPercentage(t("invalid_input.percentage_or_fixed_amount_required"));
            setFixedAmount(t("invalid_input.percentage_or_fixed_amount_required"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                if (payment_plan_item) {
                    const updatedItem = await updatePaymentPlanItem(
                        payment_plan_item.id,
                        dueDate!.toLocaleString(),
                        description,
                        percentage.length > 0 ? Number(percentage) : undefined,
                        fixedAmount.length > 0 ? Number(fixedAmount) : undefined
                    );
                    onUpdated(updatedItem);
                } else {
                    const createdItem = await createPaymentPlanItem(
                        property_id,
                        dueDate!.toLocaleString(),
                        description,
                        percentage.length > 0 ? Number(percentage) : undefined,
                        fixedAmount.length > 0 ? Number(fixedAmount) : undefined
                    );
                    onCreated(createdItem);
                }
            } catch (error) {
                console.log(`Error while creating a new payment plan item for project (${property_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [dueDate, description, percentage, fixedAmount, payment_plan_item, property_id, t, onCreated]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t(payment_plan_item ? "modals.payment_plan.edit_title" : "modals.payment_plan.add_title")}</h3>
                <p className="mb-4">{t("modals.payment_plan.message")}</p>
                <div className="row">
                    <div className="col-12">
                        <DateSelect
                            id="add-payment-plan-due-date"
                            label={t("labels.due_date")}
                            placeholder={t("placeholders.select_date")}
                            date={dueDate ?? null}
                            onChange={(date) => {
                                setDueDate(date);
                                setDueDateError("");
                            }}
                            onClear={() => {
                                setDueDate(null);
                                setDueDateError("");
                            }}
                            errorMessage={dueDateError}
                            selectMonthYearOnly
                            labelOnTop
                            required
                        />
                    </div>

                    <div className="col-12">
                        <SidebarInputField
                            id="add-payment-plan-description"
                            type="text"
                            label={t("labels.description")}
                            placeholder="Rate 1"
                            value={description}
                            errorMessage={descriptionError}
                            onClear={() => {
                                setDescription("");
                                setDescriptionError("");
                            }}
                            onChange={(value: string) => {
                                setDescription(value);
                                setDescriptionError("");
                            }}
                            labelOnTop
                            required
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <SidebarInputField
                            id="add-payment-plan-percentage"
                            type="number"
                            label={t("labels.percentage")}
                            placeholder="10%"
                            value={percentage}
                            onClear={() => setPercentage("")}
                            onChange={setPercentage}
                            labelOnTop
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <SidebarInputField
                            id="add-payment-plan-fixed-amount"
                            type="number"
                            label={t("labels.fixed_amount")}
                            placeholder="AED 4,500.00"
                            value={fixedAmount}
                            onClear={() => setFixedAmount("")}
                            onChange={setFixedAmount}
                            labelOnTop
                        />
                    </div>
                </div>
                <div className="text-end mt-4">
                    <button
                        className={`btn btn-primary add-btn me-2 ${isLoading ? 'disabled' : ''}`}
                        onClick={onHandleSavePaymentPlanItem}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t(payment_plan_item ? "buttons.save" : "buttons.create")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose()}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentPlanModal;
