import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import { AuditLogEntityName } from '../../../../services/auditLogService';
import useAuditLogFilterStore from '../../../../stores/auditLogFilterStore';
import DateSelect from '../../../../components/date-select/date-select';
import { useEmployees } from '../../../../hooks/useEmployees';
import { format, parse } from 'date-fns';

interface AuditLogFiltersProps {
    onFilterUpdated: () => void;
}

const AuditLogFilters: React.FC<AuditLogFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    const entityNameOptions: SidebarSelectFieldOptions[] = Object.values(AuditLogEntityName).map((entityName) => ({
        label: entityName.replace(/-/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
        value: entityName,
    }));

    // Zustand store
    const { start_date, end_date, time, entity_id, entity_name, employee_id, isExpanded, setFilters, setIsExpanded } = useAuditLogFilterStore();

    // Local state for filters
    const [localStartDate, setLocalStartDate] = useState(start_date);
    const [localEndDate, setLocalEndDate] = useState(end_date);
    const [localTime, setLocalTime] = useState(time);
    const [localEntityId, setLocalEntityId] = useState(entity_id);
    const [localEntityName, setLocalEntityName] = useState(entity_name);
    const [localEmployeeId, setLocalEmployeeId] = useState(employee_id);

    const { employees } = useEmployees(null, "");

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    const employeeOptions = useMemo(() => {
        return employees.map(employee => ({
            label: `${employee.first_name} ${employee.last_name}`,
            value: employee.employee_id.toString()
        }));
    }, [employees]);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localStartDate !== start_date ||
            localEndDate !== end_date ||
            localTime !== time ||
            localEntityId !== entity_id ||
            localEntityName !== entity_name ||
            localEmployeeId !== employee_id;

        setFiltersChanged(hasChanges);
    }, [localStartDate, localEndDate, localTime, localEntityId, localEntityName, localEmployeeId, start_date, end_date, time, entity_id, entity_name, employee_id]);

    const handleApplyFilters = () => {
        setFilters(localStartDate, localEndDate, localTime, localEntityId, localEntityName, localEmployeeId);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row justify-content-start">
                {/* Date from Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <DateSelect
                        id="audit-log-date-from-filter"
                        label={t('labels.filter.start_date')}
                        placeholder={t("placeholders.select_date")}
                        date={localStartDate.length > 0 ? parse(localStartDate, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date: Date) => setLocalStartDate(format(date, 'dd.MM.yyyy'))}
                        onClear={() => setLocalStartDate("")}
                        labelOnTop
                    />
                </div>

                {/* Date to Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <DateSelect
                        id="audit-log-date-to-filter"
                        label={t('labels.filter.end_date')}
                        placeholder={t("placeholders.select_date")}
                        date={localEndDate.length > 0 ? parse(localEndDate, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date: Date) => setLocalEndDate(format(date, 'dd.MM.yyyy'))}
                        onClear={() => setLocalEndDate("")}
                        labelOnTop
                    />
                </div>

                {/* Time Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="audit-log-time-filter"
                        label={t('labels.filter.time')}
                        placeholder="20:00-21:00"
                        value={localTime}
                        onChange={setLocalTime}
                        onClear={() => setLocalTime("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Entity Name Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="audit-log-entity-name-filter"
                        label={t("labels.filter.entity_name")}
                        placeholder={t("placeholders.select")}
                        value={localEntityName}
                        onChange={setLocalEntityName}
                        options={entityNameOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Entity ID Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="audit-log-entity-id-filter"
                        label={t('labels.filter.entity_id')}
                        placeholder="5"
                        value={localEntityId}
                        onChange={setLocalEntityId}
                        onClear={() => setLocalEntityId("")}
                        type="number"
                    />
                </div>
            </div>
            <div className="row justify-content-start">
                {/* Employee Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="audit-log-employee-filter"
                        label={t("labels.filter.employee_name")}
                        placeholder={t("placeholders.select")}
                        value={localEmployeeId}
                        onChange={setLocalEmployeeId}
                        options={employeeOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default AuditLogFilters;
