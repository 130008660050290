import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomPhoneInput from '../../../../components/custom-phone-input/custom-phone-input';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import CustomButton from '../../../../components/custom-button/custom-button';
import useEditPermission from '../../../../hooks/useEditPermission';
import useEmployeeStore from '../../../../stores/employeeStore';
import { EmployeeRole } from '../../../../services/employeeService';
import { updateProperty } from '../../../../services/propertyService';
import useAlertStore from '../../../../stores/alertStore';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import { IoConstructOutline } from 'react-icons/io5';
import {
    PropertyDetailsCard,
    ImageContainer,
    Image,
    Badge,
    PropertyInfo,
    PropertyName,
    PropertyPlotNo,
    Divider,
    Stats,
    StatItem,
    CustomProgress,
    ProgressBar,
    PropertyLeasedProgress,
    ContactDetails,
    UnderConstructionOverlay
} from './property-details.styles';

interface IPropertyDetails {
    property_id: number;
    image: string;
    name: string;
    email: string | null;
    phone_number: string | null;
    plot_no: number;
    type: string;
    tenants: number;
    residents: number;
    units: number;
    vacant: number;
    upcoming: number;
    leasePercentage: number;
    under_construction: boolean;
}

interface Props {
    property: IPropertyDetails;
}

const PropertyDetails: React.FC<Props> = ({ property }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // Stores
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>(property);
    const [localPropertyEmail, setLocalPropertyEmail] = useState<string | null>(property.email);
    const [localPropertyPhoneNumber, setLocalPropertyPhoneNumber] = useState<string | null>(property.phone_number);

    const leasePercentage = useMemo(() => propertyDetails.leasePercentage.toFixed(0), [propertyDetails]);
    const dataHasBeenChanged = useMemo(
        () => propertyDetails.email !== localPropertyEmail || propertyDetails.phone_number !== localPropertyPhoneNumber,
        [propertyDetails, localPropertyEmail, localPropertyPhoneNumber]
    );

    const onClickUpdateProperty = useCallback(async () => {
        try {
            setIsUpdating(true);
            await updateProperty(propertyDetails.property_id, localPropertyEmail, localPropertyPhoneNumber);
            setPropertyDetails({ ...propertyDetails, email: localPropertyEmail, phone_number: localPropertyPhoneNumber });
            showAlert("success", t("pages.property_detail.property_saved_success_message"));
        } catch (error) {
            console.log('Error while updating property: ', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsUpdating(false);
        }
    }, [propertyDetails, localPropertyEmail, localPropertyPhoneNumber, t, showAlert]);

    return (
        <PropertyDetailsCard underConstruction={propertyDetails.under_construction}>
            <ImageContainer>
                <Image src={propertyDetails.image} alt={propertyDetails.name} draggable={false} />
                <UnderConstructionOverlay underConstruction={propertyDetails.under_construction}>
                    <IoConstructOutline className="construction-icon" />
                </UnderConstructionOverlay>
                <Badge>{propertyDetails.type}</Badge>
            </ImageContainer>
            <PropertyInfo>
                <PropertyName>{propertyDetails.name}</PropertyName>
                <PropertyPlotNo>Plot-No: {propertyDetails.plot_no}</PropertyPlotNo>
                <Divider />
                <Stats>
                    <StatItem>{propertyDetails.residents} <span>{t("pages.property_detail.residents_label")}</span></StatItem>
                    <StatItem>{propertyDetails.units} <span>{t("pages.property_detail.units_label")}</span></StatItem>
                    <StatItem>{propertyDetails.vacant} <span>{t("pages.property_detail.vacant_label")}</span></StatItem>
                    <StatItem>{propertyDetails.upcoming} <span>{t("pages.property_detail.upcoming_label")}</span></StatItem>
                </Stats>
                <CustomProgress>
                    <ProgressBar leasePercentage={parseFloat(leasePercentage)} />
                </CustomProgress>
                <PropertyLeasedProgress>
                    {t("pages.property_detail.leased_by", { percentage: leasePercentage })}
                </PropertyLeasedProgress>
                {employeeRoleId !== EmployeeRole.FINANCE_MANAGER && (
                    <ContactDetails>
                        <ExpandableSection
                            className="mt-4"
                            title="Contact details"
                            isDefaultExpanded
                        >
                            <CustomInputField
                                id="property-email"
                                type="email"
                                label={t("labels.email")}
                                placeholder="demo@project.ae"
                                value={localPropertyEmail ?? ""}
                                onChange={setLocalPropertyEmail}
                                onClear={() => setLocalPropertyEmail("")}
                            />
                            <CustomPhoneInput
                                id="property-phone-number"
                                defaultCountry="DE"
                                value={localPropertyPhoneNumber ?? undefined}
                                onChange={setLocalPropertyPhoneNumber}
                                onClear={() => setLocalPropertyPhoneNumber("")}
                                labelOnTop
                            />
                        </ExpandableSection>

                        {hasEditPermission && (
                            <CustomButton
                                className="mb-4 mt-2 w-100"
                                title={t("buttons.save")}
                                isLoading={isUpdating}
                                disabled={!dataHasBeenChanged}
                                onClick={onClickUpdateProperty}
                            />
                        )}
                    </ContactDetails>
                )}
            </PropertyInfo>
        </PropertyDetailsCard>
    );
};

export default PropertyDetails;
