import { API_URL } from "../utils/constants";

export interface IEmployee {
  employee_id: number;
  role_id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string | null;
  phone_number: string | null;
  created_at: string;
  updated_at: string;
}

export const checkSession = async (): Promise<void> => {
  const response = await fetch(`${API_URL}/auth/`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return Promise.resolve();
};

export const login = async (
  email: string,
  password: string
): Promise<IEmployee> => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();
  if (response.status === 201) {
    return data;
  } else {
    throw new Error(data.message);
  }
};

export const logout = async (): Promise<boolean> => {
  const response = await fetch(`${API_URL}/auth/logout`, {
    method: "POST",
    credentials: "include",
  });

  return response.status === 200;
};
