import { t } from "i18next";
import ExpandableSection from "../expandable-section/expandable-section";
import FileCard from "../file-card/file-card";
import FileUpload, { UploadedFile } from "../file-upload/file-upload";
import { IDocument } from "../../services/documentService";
import { useState } from "react";
import DeleteFileModal from "../../modals/delete-file-modal/delete-file-modal";
import DateSelect from "../date-select/date-select";

interface FileUploadSectionProps {
    className?: string | undefined;
    colStyle?: string;
    fileUploadId: string;
    title: string;
    subtitle?: string;
    documents: IDocument[];
    uploadedFiles: UploadedFile[];
    isDefaultExpanded?: boolean;
    onDeleteDocument?: ((document: IDocument) => void) | null;
    onUpdateUploadedFiles: (files: UploadedFile[]) => void;
    maxFilesToUpload?: number;
    isHeadlineLarge?: boolean;
    allowExpirationDate?: boolean;
    allowInternalDocument?: boolean;
    hasEditPermission?: boolean;
    removePadding?: boolean;
    allowedTypes?: string;
}

const FileUploadSection: React.FC<FileUploadSectionProps> = ({
    className,
    colStyle,
    fileUploadId,
    title,
    subtitle,
    documents = [],
    uploadedFiles = [],
    isDefaultExpanded,
    onDeleteDocument,
    onUpdateUploadedFiles,
    maxFilesToUpload,
    isHeadlineLarge = true,
    allowExpirationDate = false,
    allowInternalDocument = false,
    hasEditPermission = false,
    removePadding = false,
    allowedTypes = ".pdf,image/*"
}) => {
    // States
    const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState<IDocument | null>(null);

    // Functions
    const onFileSelected = (selectedFiles: UploadedFile[]) => {
        onUpdateUploadedFiles([...uploadedFiles, ...selectedFiles]);
    };

    const removeFile = (fileIndex: number) => {
        const newFiles = uploadedFiles.filter((_, index) => index !== fileIndex);
        onUpdateUploadedFiles(newFiles);
    };

    const handleExpirationDateChange = (fileIndex: number, date: Date | null) => {
        const updatedFiles = uploadedFiles.map((file, index) => {
            if (index === fileIndex) {
                return { ...file, valid_until: date };
            }
            return file;
        });
        onUpdateUploadedFiles(updatedFiles);
    };

    const handleInternalDocumentChange = (fileIndex: number, internal_document: boolean) => {
        const updatedFiles = uploadedFiles.map((file, index) => {
            if (index === fileIndex) {
                return { ...file, internal_document };
            }
            return file;
        });
        onUpdateUploadedFiles(updatedFiles);
    };

    return (
        <>
            <ExpandableSection
                className={className ?? "mt-4"}
                title={title}
                subtitle={subtitle}
                isHeadlineLarge={isHeadlineLarge}
                isDefaultExpanded={isDefaultExpanded}
            >
                <div className="white-card" style={removePadding ? { padding: 0 } : {}}>
                    {(!maxFilesToUpload || (documents.length + uploadedFiles.length < maxFilesToUpload)) && (
                        hasEditPermission ? (
                            <FileUpload
                                id={fileUploadId}
                                allowedTypes={allowedTypes}
                                onFileSelected={onFileSelected}
                                currentFileCount={uploadedFiles.length}
                                maxFilesToUpload={maxFilesToUpload}
                            />
                        ) : (
                            <p className="my-0">{t("components.file_upload_section.not_available")}</p>
                        )
                    )}
                    <div className="row">
                        {uploadedFiles.map((file, index) => (
                            <div key={index} className={`mt-2 ${colStyle ?? ""}`}>
                                <FileCard
                                    index={index}
                                    fileName={file.filename}
                                    fileSize={file.filesize}
                                    progress={100}
                                    uploaded
                                    onClickRemove={() => removeFile(index)}
                                />
                                {allowExpirationDate && (
                                    <DateSelect
                                        id={`valid-until-${index}`}
                                        label={t("labels.valid_until")}
                                        placeholder={t("placeholders.select_date")}
                                        date={file.valid_until ?? null}
                                        onChange={(date) => handleExpirationDateChange(index, date)}
                                        labelOnTop
                                    />
                                )}
                                {allowInternalDocument && (
                                    <div className="form-check form-switch ms-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={file.internal_document ?? false}
                                            onChange={(event) => handleInternalDocumentChange(index, event.target.checked)}
                                        />
                                        <label className="form-check-label">{t("labels.internal_document")}</label>
                                    </div>
                                )}
                            </div>
                        ))}

                        {documents.map(file => (
                            <div key={file.document_id} className={`mt-2 ${colStyle ?? ""}`}>
                                <FileCard
                                    index={file.document_id}
                                    documentId={file.document_id}
                                    fileName={file.file_name}
                                    filePath={file.file_path}
                                    progress={100}
                                    validUntil={file.valid_until}
                                    internalDocument={file.internal_document}
                                    uploaded
                                    onClickRemove={() => {
                                        setIsDeleteFileModalVisible(file);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </ExpandableSection>

            {/* DELETE FILE MODAL */}
            {isDeleteFileModalVisible && onDeleteDocument && (
                <DeleteFileModal
                    document_id={isDeleteFileModalVisible.document_id}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onDeleteDocument(isDeleteFileModalVisible);
                        }
                        setIsDeleteFileModalVisible(null);
                    }}
                />
            )}
        </>
    );
}

export default FileUploadSection;
