import React from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import TheIvyImage from "../../assets/images/the-ivy.jpeg";
import { IoLocationOutline } from 'react-icons/io5';
import {
    PropertyCardWrapper,
    PropertyImageWrapper,
    PropertyOverlay,
    PropertyContent,
    PropertyTitle,
    PropertyLocationWrapper,
    PropertyLocation,
    StyledLazyLoadImage,
    ConstructionIcon
} from './property-card.styles';
import useEmployeeStore from '../../stores/employeeStore';
import { EmployeeRole } from '../../services/employeeService';

interface PropertyCardProps {
    imageSrc?: string;
    title: string;
    location: string;
    locationUrl: string;
    under_construction: boolean;
    onClick: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
    imageSrc,
    title,
    location,
    locationUrl,
    under_construction,
    onClick,
}) => {
    const employeeRole = useEmployeeStore((state) => state.employee?.role_id);

    return (
        <PropertyCardWrapper deactivated={under_construction && employeeRole !== EmployeeRole.ADMINISTRATOR} onClick={onClick}>
            <PropertyImageWrapper>
                <StyledLazyLoadImage
                    placeholderSrc='https://placehold.co/600x400'
                    src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : TheIvyImage}
                    alt={title}
                    effect="opacity"
                    width="100%"
                    height="150px"
                    draggable={false}
                />
                {under_construction && (
                    <PropertyOverlay>
                        <ConstructionIcon />
                    </PropertyOverlay>
                )}
            </PropertyImageWrapper>
            <PropertyContent>
                <PropertyTitle>{title}</PropertyTitle>
                <PropertyLocationWrapper>
                    <IoLocationOutline size={20} />
                    <PropertyLocation
                        href={locationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}>
                        {location}
                    </PropertyLocation>
                </PropertyLocationWrapper>
            </PropertyContent>
        </PropertyCardWrapper>
    );
};

export default PropertyCard;
