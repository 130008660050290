export const getCurrentFormattedDate = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return new Date().toLocaleDateString("de-DE", options);
};

export const getCurrentFormattedDateTime = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };

  const date = new Date().toLocaleDateString("de-DE", options);

  const time = new Date().toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return `${date} - ${time}`;
};

export const getFormattedDate = (datetime: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return new Date(datetime).toLocaleDateString("de-DE", options);
};

export const formatDateTimeToDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("de-DE", { month: "long" });
  const year = date.getFullYear();

  return `${day}. ${month} ${year}`;
};

export const isYouTubeUrl = (url: string): boolean => {
  return (
    url.match(/(youtu.be\/|youtube.com\/(watch\?(.*&)?v=|(embed|v)\/))/) != null
  );
};

export const getYouTubeId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export const interpolateColor = (
  color1: string,
  color2: string,
  factor: number
): string => {
  // Extract RGB components from a hex color string
  const parseColor = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
  };

  // Linearly interpolate between two values
  const lerp = (start: number, end: number, t: number): number => {
    return Math.round(start + (end - start) * t);
  };

  // Parse both colors to get their RGB components
  const rgb1 = parseColor(color1);
  const rgb2 = parseColor(color2);

  // Interpolate each color component
  const r = lerp(rgb1.r, rgb2.r, factor);
  const g = lerp(rgb1.g, rgb2.g, factor);
  const b = lerp(rgb1.b, rgb2.b, factor);

  // Convert each component back to hex and pad with zeros if necessary
  const toHex = (x: number) => x.toString(16).padStart(2, "0");

  // Return the interpolated color in hex format
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const censorEmail = (email: string) => {
  const atIndex = email.indexOf("@");
  const firstPart = email.substring(0, atIndex);
  const lastPart = email.substring(atIndex);
  const visibleChars = Math.min(5, firstPart.length);
  const hiddenChars = Math.max(0, firstPart.length - visibleChars);
  const censoredFirstPart =
    hiddenChars > 0
      ? firstPart.slice(0, -visibleChars) + "*".repeat(visibleChars)
      : "*".repeat(firstPart.length);
  return censoredFirstPart + lastPart;
};

// Function to censor phone number
export const censorPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return "-";
  const visibleDigits = 5;
  const hiddenDigits = Math.max(0, phoneNumber.length - visibleDigits * 2);
  return `${phoneNumber.slice(0, visibleDigits)}${"*".repeat(
    hiddenDigits
  )}${phoneNumber.slice(-visibleDigits)}`;
};

export const calculateMonthsLeft = (
  startDateStr: string,
  endDateStr: string
): number => {
  // Parse the date strings into Date objects
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Check if the dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error("Invalid date format");
  }

  // Calculate the difference in milliseconds
  const durationMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysLeft = durationMs / millisecondsPerDay;

  // Return the number of days left
  return Math.floor(daysLeft);
};

export const calculateDurationInMonths = (
  startDateStr: string,
  endDateStr: string
): number => {
  // Parse the date strings into Date objects
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Check if the dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error("Invalid date format");
  }

  // Calculate the year and month difference
  const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
  const monthsDifference = endDate.getMonth() - startDate.getMonth();
  const daysDifference = endDate.getDate() - startDate.getDate();

  let totalMonths = yearsDifference * 12 + monthsDifference;

  if (daysDifference > 0) {
    const daysInMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate();
    totalMonths += daysDifference / daysInMonth;
  } else if (daysDifference < 0) {
    const daysInMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      0
    ).getDate();
    totalMonths += daysDifference / daysInMonth;
  }

  return totalMonths;
};

export const formatMoney = (amount: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(amount)
    .replace("$", "");
};
