import React, { useCallback, useState } from 'react';
import {
    PaymentList,
    PaymentItem,
    PaymentIcon,
    PaymentInfo,
    PaymentTitle,
    PaymentDetail,
    PaymentPercentage,
    Headline,
    HeadlineWrapper,
    PlusButton,
    PaymentAmount,
    EmptyText,
    DeleteIconWrapper
} from './payment-plan-items.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { deletePaymentPlanItem, IPaymentPlanItem } from '../../../../services/paymentPlanService';
import { formatMoney } from '../../../../utils/helpers';
import DeleteConfirmationModal from '../../../../modals/delete-confirmation-modal/delete-confirmation-modal';
import { useTranslation } from 'react-i18next';
import useEditPermission from '../../../../hooks/useEditPermission';
import { EmployeeRole } from '../../../../services/employeeService';
import useEmployeeStore from '../../../../stores/employeeStore';
import PaymentPlanModal from '../../../../modals/payment-plan-modal/payment-plan-modal';
import i18n from '../../../../i18n';
import { parseISO, isBefore, isSameMonth } from 'date-fns';

interface PaymentPlanProps {
    property_id: number;
    paymentPlanItems: IPaymentPlanItem[];
    onUpdatedPaymentPlanItems: (items: IPaymentPlanItem[]) => void;
}

const PaymentPlan: React.FC<PaymentPlanProps> = ({ property_id, paymentPlanItems, onUpdatedPaymentPlanItems }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPaymentPlanItem, setSelectedPaymentPlanItem] = useState<IPaymentPlanItem | null>(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<number | null>(null);

    // Functions
    const formatDate = (date: string | Date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString(i18n.language, {
            year: 'numeric',
            month: 'long',
        });
    };

    const onDeletePaymentPlanItem = useCallback(async () => {
        if (isDeleteModalVisible) {
            try {
                setIsLoading(true);
                const isDeleted = await deletePaymentPlanItem(isDeleteModalVisible);
                if (isDeleted) {
                    onUpdatedPaymentPlanItems(paymentPlanItems.filter(item => item.id !== isDeleteModalVisible));
                }
            } catch (error) {
                console.log(`Error while deleting payment plan item (${isDeleteModalVisible}):`, error);
            } finally {
                setIsLoading(false);
                setIsDeleteModalVisible(null);
            }
        }
    }, [isDeleteModalVisible, paymentPlanItems]);

    const isPaymentDue = (dueDate: string | Date) => {
        const currentDate = new Date();
        const paymentDueDate = typeof dueDate === 'string' ? parseISO(dueDate) : dueDate;

        return isBefore(paymentDueDate, currentDate) || isSameMonth(paymentDueDate, currentDate);
    };

    return (
        <>
            <div className="white-card" style={{ padding: 0 }}>
                <HeadlineWrapper>
                    <Headline>{t("pages.property_detail.payment_plan.headline")}</Headline>
                    <PlusButton onClick={() => setIsAddModalVisible(true)}>
                        <MaterialIcon icon="add_card" size={20} color={colorPalette.white} />
                    </PlusButton>
                </HeadlineWrapper>
                {paymentPlanItems.length > 0 ? (
                    <PaymentList>
                        {paymentPlanItems
                            .sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())
                            .map((item, index) => (
                                <PaymentItem key={index} onClick={() => {
                                    setIsAddModalVisible(true);
                                    setSelectedPaymentPlanItem(item);
                                }}>
                                    <div className="d-flex">
                                        <PaymentIcon paymentDue={isPaymentDue(item.due_date)}>
                                            <MaterialIcon icon="credit_card_clock" size={24} color={isPaymentDue(item.due_date) ? colorPalette.white : colorPalette.blue600} />
                                        </PaymentIcon>
                                        <PaymentInfo>
                                            <PaymentTitle>{formatDate(item.due_date)}</PaymentTitle>
                                            <PaymentDetail>{t("pages.property_detail.payment_plan.description", { text: item.description })}</PaymentDetail>
                                        </PaymentInfo>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {item.fixed_amount ? (
                                            <PaymentAmount>AED {formatMoney(item.fixed_amount)}</PaymentAmount>
                                        ) : (
                                            <PaymentPercentage>{Math.round(item.percentage!)}%</PaymentPercentage>
                                        )}
                                        {hasEditPermission && (
                                            <DeleteIconWrapper onClick={(event) => {
                                                setIsDeleteModalVisible(item.id);
                                                event.stopPropagation();
                                            }}>
                                                <MaterialIcon icon="delete" size={24} color={colorPalette.errorDark} />
                                            </DeleteIconWrapper>
                                        )}
                                    </div>
                                </PaymentItem>
                            ))}
                    </PaymentList>
                ) : (
                    <EmptyText>{t("pages.property_detail.payment_plan.empty_text")}</EmptyText>
                )}
            </div>

            {/* ADD PAYMENT PLAN ITEM MODAL */}
            {
                isAddModalVisible && hasEditPermission && (
                    <PaymentPlanModal
                        property_id={property_id}
                        payment_plan_item={selectedPaymentPlanItem}
                        onClose={() => {
                            setIsAddModalVisible(false);
                            setSelectedPaymentPlanItem(null);
                        }}
                        onCreated={(item) => {
                            onUpdatedPaymentPlanItems([...paymentPlanItems, item]);
                            setIsAddModalVisible(false);
                            setSelectedPaymentPlanItem(null);
                        }}
                        onUpdated={(updatedItem) => {
                            const updatedList = [...paymentPlanItems.map((item) =>
                                item.id === updatedItem.id ? updatedItem : item
                            )];
                            onUpdatedPaymentPlanItems(updatedList);
                            setIsAddModalVisible(false);
                            setSelectedPaymentPlanItem(null);
                        }}
                    />
                )
            }

            {/* DELETE PAYMENT PLAN ITEM MODAL */}
            {
                isDeleteModalVisible && hasEditPermission && (
                    <DeleteConfirmationModal
                        title={t("pages.property_detail.payment_plan.delete_modal.title")}
                        message={t("pages.property_detail.payment_plan.delete_modal.message")}
                        onClose={() => setIsDeleteModalVisible(null)}
                        onDelete={onDeletePaymentPlanItem}
                        isLoading={isLoading}
                    />
                )
            }
        </>
    );
};

export default PaymentPlan;
