import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import useUnitFilterStore from '../../../../stores/unitFilterStore';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import { formatSoldBy, UnitPurposeType, UnitSoldBy, UnitStatus } from '../../../../services/unitService';

interface UnitFiltersProps {
    onFilterUpdated: () => void;
}

const UnitFilters: React.FC<UnitFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    // Select Options
    const soldByOptions: SidebarSelectFieldOptions[] = Object.values(UnitSoldBy).map((soldBy) => ({
        label: formatSoldBy(soldBy),
        value: soldBy,
    }));

    const statusOptions: SidebarSelectFieldOptions[] = Object.values(UnitStatus).map((status) => ({
        label: status.replace(/-/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
        value: status,
    }));

    const purposeOptions: SidebarSelectFieldOptions[] = Object.values(UnitPurposeType).map((purpose) => ({
        label: purpose.replace(/-/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
        value: purpose,
    }));

    // Zustand store
    const { bedrooms, bathrooms, minimumAnnualRent, soldBy, status, purpose, pmContractSigned, isExpanded, setFilters, setIsExpanded } = useUnitFilterStore();

    // Local state for filters
    const [localBedrooms, setLocalBedrooms] = useState(bedrooms);
    const [localBathrooms, setLocalBathrooms] = useState(bathrooms);
    const [localMinimumAnnualRent, setLocalMinimumAnnualRent] = useState(minimumAnnualRent);
    const [localSoldBy, setLocalSoldBy] = useState(soldBy);
    const [localStatus, setLocalStatus] = useState(status);
    const [localPurpose, setLocalPurpose] = useState(purpose);
    const [localPMContractSigned, setLocalPMContractSigned] = useState(pmContractSigned);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localBedrooms !== bedrooms ||
            localBathrooms !== bathrooms ||
            localMinimumAnnualRent !== minimumAnnualRent ||
            localSoldBy !== soldBy ||
            localStatus !== status ||
            localPurpose !== purpose ||
            localPMContractSigned !== pmContractSigned;

        setFiltersChanged(hasChanges);
    }, [localBedrooms, localBathrooms, localMinimumAnnualRent, localSoldBy, localStatus, localPurpose, localPMContractSigned, bedrooms, bathrooms, minimumAnnualRent, soldBy, status, purpose, pmContractSigned]);

    const handleApplyFilters = () => {
        setFilters(localBedrooms, localBathrooms, localMinimumAnnualRent, localSoldBy, localStatus, localPurpose, localPMContractSigned);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Bedrooms Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="bedroomsInput"
                        label={t('labels.filter.bedrooms')}
                        placeholder="1"
                        value={localBedrooms}
                        onChange={setLocalBedrooms}
                        onClear={() => setLocalBedrooms("")}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Bathrooms Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="bathroomsInput"
                        label={t('labels.filter.bathrooms')}
                        placeholder="1-2"
                        value={localBathrooms}
                        onChange={setLocalBathrooms}
                        onClear={() => setLocalBathrooms("")}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Minimum Annual Rent Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="minimumAnnualRentInput"
                        label={t('labels.annual_rent')}
                        placeholder="50,000.00-100,000.00"
                        value={localMinimumAnnualRent}
                        onChange={setLocalMinimumAnnualRent}
                        onClear={() => setLocalMinimumAnnualRent("")}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Sold By Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="units-filter-sold-by-selection"
                        label={t("labels.sold_by")}
                        placeholder={t("placeholders.select")}
                        value={localSoldBy}
                        onChange={setLocalSoldBy}
                        options={soldByOptions}
                        showClearIcon
                        labelOnTop
                        isSearchable={false}
                    />
                </div>

                {/* Status Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="units-filter-status-selection"
                        label={t("labels.status")}
                        placeholder={t("placeholders.select")}
                        value={localStatus}
                        onChange={setLocalStatus}
                        options={statusOptions}
                        showClearIcon
                        labelOnTop
                        isSearchable={false}
                    />
                </div>

                {/* Purpose Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="units-filter-purpose-selection"
                        label={t("labels.purpose_type")}
                        placeholder={t("placeholders.select")}
                        value={localPurpose}
                        onChange={setLocalPurpose}
                        options={purposeOptions}
                        showClearIcon
                        labelOnTop
                        isSearchable={false}
                    />
                </div>

                {/* PM Contract Signed Filter */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center form-check form-switch">
                    <input
                        className="form-check-input"
                        style={{ marginLeft: 0 }}
                        type="checkbox"
                        checked={localPMContractSigned}
                        onChange={(e) => setLocalPMContractSigned(e.target.checked)}
                    />
                    <label className="form-check-label ms-2">{t("labels.pm_contract_signed")}</label>
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default UnitFilters;
