import useSWRInfinite from 'swr/infinite';
import { IProperty, constructPropertiesUrl, fetchProperties } from '../services/propertyService';

const PAGE_SIZE = 20;

export const useProperties = () => {
    const fetcher = (url: string) => fetchProperties(url);

    const getKey = (pageIndex: number, previousPageData: IProperty[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructPropertiesUrl(pageIndex + 1, PAGE_SIZE);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IProperty[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        properties: data ? ([] as IProperty[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
