import { create } from "zustand";
import { UnitSortBy } from "../services/unitService";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";

interface AvailableUnitFilterState {
  bedrooms: string;
  bathrooms: string;
  minimumAnnualRent: string;
  sortColumn: UnitSortBy | null;
  sortDirection: SortOrder | null;
  isExpanded: boolean;
  setFilters: (
    bedrooms: string,
    bathrooms: string,
    minimumAnnualRent: string
  ) => void;
  setSort: (column: UnitSortBy, direction: SortOrder) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useAvailableUnitFilterStore = create<AvailableUnitFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        bedrooms: "",
        bathrooms: "",
        minimumAnnualRent: "",
        sortColumn: null,
        sortDirection: null,
        isExpanded: false,
        setFilters: (bedrooms, bathrooms, minimumAnnualRent) =>
          set({
            bedrooms,
            bathrooms,
            minimumAnnualRent,
          }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "available-unit-filter-storage",
      }
    )
  )
);

export default useAvailableUnitFilterStore;
