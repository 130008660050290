import './sidebar-select-field.css';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BsXCircle, BsChevronDown } from 'react-icons/bs';

export interface SidebarSelectFieldOptions {
    label: string;
    value: string;
}

interface SidebarSelectFieldProps {
    className?: string;
    id: string;
    label: string;
    value?: string | undefined;
    onChange: (value: string) => void;
    options: SidebarSelectFieldOptions[];
    required?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    labelOnTop?: boolean;
    placeholder?: string;
    onSearch?: (searchQuery: string, page: number) => void | undefined;
    isSearchable?: boolean;
    isEndReached?: boolean;
    showClearIcon?: boolean;
    emptySearch?: string;
}

const SidebarSelectField: React.FC<SidebarSelectFieldProps> = ({
    className,
    id,
    label,
    value,
    onChange,
    options,
    required = false,
    disabled = false,
    errorMessage,
    labelOnTop = false,
    placeholder = 'Search...',
    onSearch,
    isSearchable = false,
    isEndReached = false,
    showClearIcon = false,
    emptySearch = 'No results found',
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [page, setPage] = useState(1);
    const inputRef = useRef<HTMLInputElement>(null);
    const ignoreSearchEffect = useRef(false);

    useEffect(() => {
        if (onSearch && !ignoreSearchEffect.current) {
            onSearch(searchQuery, page);
        } else {
            ignoreSearchEffect.current = false;
        }
    }, [searchQuery, page, onSearch]);

    const handleOptionClick = (value: string, label: string) => {
        onChange(value);
        ignoreSearchEffect.current = true;
        setSearchQuery(label);
        setPage(1);
        setIsDropdownVisible(false);
    };

    const handleInputFocus = () => {
        setIsDropdownVisible(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownVisible(false);
        }, 100);
    };

    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLUListElement>) => {
            const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
            if (bottom && !isEndReached) {
                setPage((prevPage) => prevPage + 1);
            }
        },
        [isEndReached]
    );

    const clearSelection = () => {
        onChange('');
        setSearchQuery('');
    };

    const labelClasses = labelOnTop ? 'sidebar-select-label-on-top' : 'col-sm-5 col-form-label';
    const selectWrapperClasses = labelOnTop ? 'sidebar-select-wrapper-on-top' : 'col-sm-7';

    return (
        <div className={`sidebar-select-field ${labelOnTop ? '' : 'row'} ${className}`}>
            <label htmlFor={id} className={labelClasses}>
                {label}
                {required && <span className="text-red ms-1">*</span>}
            </label>
            <div className={`${selectWrapperClasses} position-relative`}>
                <div className="d-flex flex-column" style={labelOnTop ? { height: 50 } : {}}>
                    {isSearchable ? (
                        <input
                            id={id}
                            type="text"
                            className="form-select"
                            ref={inputRef}
                            value={searchQuery}
                            onChange={(e) => {
                                setPage(1);
                                setSearchQuery(e.target.value);
                            }}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            placeholder={placeholder}
                            disabled={disabled}
                            required={required}
                        />
                    ) : (
                        <select
                            id={id}
                            className={`form-select ${!value ? 'placeholder' : ''}`} 
                            value={value ?? ''}
                            onChange={(e) => onChange(e.target.value)}
                            required={required}
                            disabled={disabled}
                        >
                            <option value="" disabled hidden>
                                {placeholder} {/* This is the placeholder */}
                            </option>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}
                    <div className="dropdown-arrow-container" style={{ right: showClearIcon && value ? '30px' : '10px' }}>
                        <BsChevronDown />
                    </div>
                    {showClearIcon && value && (
                        <div className="icon-container">
                            <BsXCircle onClick={clearSelection} />
                        </div>
                    )}
                    {isDropdownVisible && isSearchable && (
                        <ul className="dropdown-menu" onScroll={handleScroll}>
                            {options.length > 0 ? (
                                options.map((option) => (
                                    <li
                                        key={option.value}
                                        className="dropdown-item"
                                        onMouseDown={() => handleOptionClick(option.value, option.label)}
                                    >
                                        {option.label}
                                    </li>
                                ))
                            ) : (
                                <li className="dropdown-item empty-search">{emptySearch}</li>
                            )}
                        </ul>
                    )}
                </div>
                {errorMessage && <div className="ms-2 error-message">{errorMessage}</div>}
            </div>
        </div>
    );
};

export default SidebarSelectField;
