import useSWRInfinite from 'swr/infinite';
import { IUnit, UnitSortBy } from '../services/unitService';
import { SortOrder } from '../@types';
import { constructPropertyAvailableUnitsUrl, fetchPropertyAvailableUnits } from '../services/propertyService';

const PAGE_SIZE = 20;

export const useAvailableUnits = (
    propertyId: number,
    page_size: number | undefined = PAGE_SIZE,
    search: string,
    bedrooms?: string,
    bathrooms?: string,
    minimum_annual_rent?: string,
    sortBy?: UnitSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchPropertyAvailableUnits(url);

    const getKey = (pageIndex: number, previousPageData: IUnit[] | null) => {
        if (previousPageData && !previousPageData.length) return null; // reached the end
        return constructPropertyAvailableUnitsUrl(
            propertyId,
            search,
            pageIndex + 1,
            page_size,
            bedrooms,
            bathrooms,
            minimum_annual_rent,
            sortBy,
            sortOrder
        );
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IUnit[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        availableUnits: data ? ([] as IUnit[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
