import '../base-modal.css';
import './news-modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';
import { createNews, INews, updateNews } from '../../services/newsService';
import CustomRichTextField from '../../components/custom-rich-text-field/custom-rich-text-field';
import DateSelect from '../../components/date-select/date-select';

interface ModalProps {
    news?: INews;
    onClose: () => void;
    onNewsCreated: (news: INews) => void;
    onNewsUpdated: (news: INews) => void;
}

const NewsModal: React.FC<ModalProps> = ({ news, onClose, onNewsCreated, onNewsUpdated }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [publishedDate, setPublishedDate] = useState<Date | null>(null);

    // Error States
    const [titleError, setTitleError] = useState<string>("");
    const [contentError, setContentError] = useState<string>("");

    useEffect(() => {
        if (news) {
            setTitle(news.title);
            setContent(news.content);
            setPublishedDate(new Date(news.published_at));
        }
    }, [news]);

    const onHandleCreateNews = useCallback(async () => {
        let isValid = true;

        // Check if the title is not empty
        if (!title.trim()) {
            setTitleError(t("invalid_input.title_empty"));
            isValid = false;
        }

        // Check if the content is not empty
        if (!content.trim()) {
            setContentError(t("invalid_input.description_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);

                if (news) {
                    const updatedNews = await updateNews(news.news_id, title, content, publishedDate?.toLocaleString() ?? undefined);
                    onNewsUpdated(updatedNews);
                } else {
                    const createdNews = await createNews(title, content, publishedDate?.toLocaleString() ?? undefined);
                    onNewsCreated(createdNews);
                }
            } catch (error) {
                console.log(`Error while creating / updating news (${news?.news_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [title, content, publishedDate, news]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t(news ? "modals.news.update_title" : "modals.news.create_title")}</h3>
                <p className="mb-4">{t("modals.news.message")}</p>
                <DateSelect
                    id="news-published-at"
                    label={t("labels.publish_on")}
                    placeholder={t("placeholders.select_date_and_time")}
                    date={publishedDate ?? null}
                    onChange={setPublishedDate}
                    labelOnTop
                    showTimeSelect
                />
                <SidebarInputField
                    id="news-title"
                    type="text"
                    label={t("labels.title")}
                    placeholder="Headline of the News"
                    value={title}
                    errorMessage={titleError}
                    onClear={() => setTitle("")}
                    onChange={(value: string) => {
                        setTitle(value);
                        setTitleError("");
                    }}
                    labelOnTop
                    required
                />
                <CustomRichTextField
                    id="news-content"
                    label={t("labels.description")}
                    placeholder={t("pages.construction_update.description_placeholder")}
                    value={content}
                    errorMessage={contentError}
                    required
                    onChange={(text: string) => {
                        setContent(text);
                        setContentError("");
                    }}
                />
                <div className="text-end mt-4">
                    <button className={`btn btn-primary add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleCreateNews} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t(news ? "buttons.save" : "buttons.create")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose()}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewsModal;
