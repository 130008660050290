import './tenant-details.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeRole } from '../../../../services/employeeService';
import LeaseSidebarModal from '../../../../modals/sidebar/lease-sidemodal/lease-sidemodal';
import { fetchUnitLease, IUnitLeaseSearchType } from '../../../../services/unitService';
import { ILease } from '../../../../services/leaseService';
import TenantCard from './tenant-card';

interface TenantDetailsProps {
    employeeRoleId?: number | null;
    propertyId: number;
    unitId: number;
}

const TenantDetails: React.FC<TenantDetailsProps> = ({ employeeRoleId, propertyId, unitId }) => {
    const { t } = useTranslation();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [activeLease, setActiveLease] = useState<ILease | null>(null);
    const [upcomingLease, setUpcomingLease] = useState<ILease | null>(null);

    // Fetch lease details
    useEffect(() => {
        const fetchLeaseDetails = async () => {
            try {
                const [activeLease, upcomingLease] = await Promise.all([
                    fetchUnitLease(unitId, IUnitLeaseSearchType.ACTIVE),
                    fetchUnitLease(unitId, IUnitLeaseSearchType.UPCOMING)
                ]);
                setActiveLease(activeLease);
                setUpcomingLease(upcomingLease);
            } catch (error) {
                console.log('Error while fetching unit leases', error);
            }
        };
        fetchLeaseDetails();
    }, [unitId]);

    const onClickTenant = (tenant_id: number) => {
        const url = `${window.location.origin}/tenants/${tenant_id}`;
        window.open(url, '_blank');
    };

    const onLeaseCreated = (lease: ILease) => {
        const currentDate = new Date();
        const leaseStartDate = new Date(lease.start_date);

        if (leaseStartDate > currentDate) {
            setUpcomingLease(lease);
        } else {
            setActiveLease(lease);
        }
    };

    return (
        <>
            <div className="row gy-4">
                <TenantCard
                    lease={activeLease}
                    title={t("pages.unit_detail.tenant_details.current_tenant_title")}
                    noLeaseText={t("pages.unit_detail.tenant_details.current_tenant_empty")}
                    onClickTenant={onClickTenant}
                    setIsSidebarModalVisible={setIsSidebarModalVisible}
                />
                <TenantCard
                    lease={upcomingLease}
                    title={t("pages.unit_detail.tenant_details.next_tenant_title")}
                    noLeaseText={t("pages.unit_detail.tenant_details.next_tenant_empty")}
                    onClickTenant={onClickTenant}
                    setIsSidebarModalVisible={setIsSidebarModalVisible}
                />
            </div>

            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <LeaseSidebarModal
                    isVisible={isSidebarModalVisible}
                    propertyId={propertyId}
                    unitId={unitId}
                    onClose={() => setIsSidebarModalVisible(false)}
                    onLeaseCreated={onLeaseCreated}
                />
            )}
        </>
    );
};

export default TenantDetails;
