import '../base-modal.css';
import './add-occupant-modal.css';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILeaseOccupant, createLeaseOccupant } from '../../services/leaseService';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';

interface ModalProps {
    lease_id: number;
    onClose: () => void;
    onOccupantCreated: (occupant: ILeaseOccupant) => void;
}

const AddOccupantModal: React.FC<ModalProps> = ({ lease_id, onClose, onOccupantCreated }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    // Error States
    const [nameError, setNameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");

    const onHandleCreateOccupant = useCallback(async () => {
        let isValid = true;

        // Check if the name is not empty
        if (!name.trim()) {
            setNameError(t("invalid_input.name_empty"));
            isValid = false;
        }

        // Basic email validation
        if (!email.trim()) {
            setEmailError(t("invalid_input.email_empty"));
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                const createdOccupant = await createLeaseOccupant(lease_id, name, email);
                onOccupantCreated(createdOccupant);
            } catch (error) {
                console.log(`Error while creating a new occupant for lease (${lease_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [name, email, lease_id, t, onOccupantCreated]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.add_occupant.title")}</h3>
                <p className="mb-4">{t("modals.add_occupant.message")}</p>
                <SidebarInputField
                    id="add-occupant-name"
                    type="text"
                    label={t("labels.name")}
                    placeholder="Max Mustermann"
                    value={name}
                    errorMessage={nameError}
                    onClear={() => setName("")}
                    onChange={(value: string) => {
                        setName(value);
                        setNameError("");
                    }}
                    required
                />
                <SidebarInputField
                    id="add-occupant-email"
                    type="email"
                    label={t("labels.email")}
                    placeholder="max@mustermann.com"
                    value={email}
                    errorMessage={emailError}
                    onClear={() => setEmail("")}
                    onChange={(value: string) => {
                        setEmail(value);
                        setEmailError("");
                    }}
                    required
                />
                <div className="text-end mt-4">
                    <button className={`btn btn-primary add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleCreateOccupant} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.create")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose()}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddOccupantModal;
