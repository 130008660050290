import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import SidebarInputField from '../../components/sidebar-input-field/sidebar-input-field';
import LeasePaymentCardsSection, { LeasePaymentTableItem } from './lease-payment-table-section/lease-payment-table-section';
import useEditPermission from '../../../../hooks/useEditPermission';
import { EmployeeRole } from '../../../../services/employeeService';
import useEmployeeStore from '../../../../stores/employeeStore';

interface LeasePaymentInfoSectionProps {
    payments: LeasePaymentTableItem[];
    annualRent: string;
    totalRent: string;
    deposit: string;
    rentPayments: string;
    annualRentError: string;
    totalRentError: string;
    depositError: string;
    rentPaymentsError: string;
    onAnnualRentChange: (value: string) => void;
    onTotalRentChange: (value: string) => void;
    onDepositChange: (value: string) => void;
    onPetDepositChange: (value: string) => void;
    onRentPaymentsChange: (value: string) => void;
    startDate: Date | null;
    endDate: Date | null;
    furnished: boolean;
}

const LeasePaymentInfoSection: React.FC<LeasePaymentInfoSectionProps> = ({
    payments,
    annualRent,
    totalRent,
    deposit,
    rentPayments,
    annualRentError,
    totalRentError,
    depositError,
    rentPaymentsError,
    onAnnualRentChange,
    onTotalRentChange,
    onDepositChange,
    onPetDepositChange,
    onRentPaymentsChange,
    startDate,
    endDate,
    furnished
}) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasAdminPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [applyPetDeposit, setApplyPetDeposit] = useState(false);

    useEffect(() => {
        if (annualRent && startDate && endDate) {
            let adjustedEndDate = new Date(endDate);

            // Check if this is a full-year contract with an additional day
            const isFullYearPlusOneDay = (
                startDate.getFullYear() === endDate.getFullYear() - 1 &&
                startDate.getMonth() === endDate.getMonth() &&
                startDate.getDate() === endDate.getDate()
            );

            // Subtract one day only if the contract is exactly one year (i.e., end date is the same day in the next year)
            if (
                startDate.getFullYear() === endDate.getFullYear() + 1 &&
                startDate.getMonth() === endDate.getMonth() &&
                startDate.getDate() === endDate.getDate()
            ) {
                adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);
            }

            const startYear = startDate.getFullYear();
            const startMonth = startDate.getMonth();
            const startDay = startDate.getDate();

            const endYear = adjustedEndDate.getFullYear();
            const endMonth = adjustedEndDate.getMonth();
            const endDay = adjustedEndDate.getDate();

            // Calculate total months between start and end
            const totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

            // Calculate rent for the remaining days in the last month
            const rentPerMonth = Number(annualRent) / 12;
            let rentForPartialMonth = 0;

            if (endDay >= startDay) {
                rentForPartialMonth = (endDay - startDay + 1) / new Date(endYear, endMonth + 1, 0).getDate() * rentPerMonth;
            }

            // Total rent calculation
            const totalRent = rentPerMonth * totalMonths + rentForPartialMonth;

            // Handle contracts that are exactly one year + 1 day
            if (isFullYearPlusOneDay) {
                // Rent for one full year + 1 extra day
                const rentPerDay = rentPerMonth / new Date(startYear, startMonth + 1, 0).getDate();
                const adjustedTotalRent = Number(annualRent) + rentPerDay;
                onTotalRentChange(adjustedTotalRent.toFixed(2).toString());
            } else if (totalMonths === 12 && startDay === endDay) {
                // Full-year contract
                onTotalRentChange(Number(annualRent).toFixed(2));
            } else {
                // Other cases
                onTotalRentChange(totalRent.toFixed(2).toString());
            }
        }
    }, [annualRent, startDate, endDate]);

    // Calculate security deposit based on annual rent
    useEffect(() => {
        if (annualRent.length > 0) {
            const securityDepositPercentage = furnished ? 10 : 5;
            const calculation = (Number(annualRent) * securityDepositPercentage / 100).toFixed(2);
            onDepositChange(calculation);
            onPetDepositChange(applyPetDeposit ? (Number(annualRent) * 5 / 100).toFixed(2) : "");
        } else {
            onDepositChange("");
            onPetDepositChange("");
        }
    }, [annualRent, furnished, applyPetDeposit]);

    return (
        <ExpandableSection title={t("modals.create_lease.payment_information_title")} className="mt-4">
            <div className="row">
                <div className="col-12 col-xxl-6">
                    <SidebarInputField
                        id="annual-rent"
                        type="number"
                        label={t("labels.annual_rent")}
                        placeholder="60,000.00 AED"
                        value={annualRent}
                        errorMessage={annualRentError}
                        onClear={() => onAnnualRentChange("")}
                        onChange={(value: string) => {
                            onAnnualRentChange(value);
                        }}
                        required
                        labelOnTop
                        disabled={!hasAdminPermission}
                    />
                </div>
                <div className="col-12 col-xxl-6">
                    <SidebarInputField
                        id="total-rent"
                        type="number"
                        label={t("labels.total_rent")}
                        placeholder="60,000.00 AED"
                        value={totalRent}
                        errorMessage={totalRentError}
                        onClear={() => onTotalRentChange("")}
                        onChange={(value: string) => {
                            onTotalRentChange(value);
                        }}
                        required
                        labelOnTop
                        disabled
                    />
                </div>
                <div className="col-12 col-xxl-6">
                    <SidebarInputField
                        id="security-deposit"
                        type="number"
                        label={t("labels.security_deposit")}
                        placeholder="3,500.00 AED"
                        value={deposit}
                        errorMessage={depositError}
                        onClear={() => onDepositChange("")}
                        onChange={(value: string) => {
                            onDepositChange(value);
                        }}
                        required
                        labelOnTop
                        disabled
                    />
                </div>
                <div className="col-12 col-xxl-6">
                    <SidebarInputField
                        id="rent-payments"
                        type="number"
                        label={t("labels.no_of_rent_payments")}
                        placeholder="4"
                        value={rentPayments}
                        errorMessage={rentPaymentsError}
                        onClear={() => onRentPaymentsChange("")}
                        onChange={(value: string) => {
                            onRentPaymentsChange(value);
                        }}
                        required
                        labelOnTop
                    />
                </div>
                <div className="col-12 col-xxl-6">
                    <div className="form-check form-switch mb-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="apply-pet-deposit-switch"
                            checked={applyPetDeposit}
                            onChange={(event) => setApplyPetDeposit(event.currentTarget.checked)}
                        />
                        <label className="form-check-label" htmlFor="apply-pet-deposit-switch">
                            {t("labels.apply_pet_deposit")}
                        </label>
                    </div>
                </div>
                <LeasePaymentCardsSection payments={payments} />
            </div>
        </ExpandableSection>
    );
};

export default LeasePaymentInfoSection;
