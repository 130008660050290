import React, { useCallback, useMemo, useState } from "react";
import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import { IPicture, deleteUnitPicture } from "../../../../services/unitService";
import { IoCloseCircle } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FileUpload, { UploadedFile } from "../../../../components/file-upload/file-upload";
import DeletePictureModal from "../../../../modals/delete-picture-modal/delete-picture-modal";
import { SPACES_ENDPOINT } from "../../../../utils/constants";
import { EmployeeRole } from "../../../../services/employeeService";
import styles from "./unit-pictures-section.module.css";

interface UnitPicturesSectionProps {
    employeeRoleId?: number;
    unitId: number;
    pictures: IPicture[];
    newPictures: UploadedFile[];
    onPictureDeleted: (picture_id: number) => void;
    onUpdatePictures: (pictures: UploadedFile[]) => void;
}

const UnitPicturesSection: React.FC<UnitPicturesSectionProps> = ({ employeeRoleId, unitId, pictures, newPictures, onPictureDeleted, onUpdatePictures }) => {
    // States
    const [isDeletePictureModalVisible, setIsDeletePictureModalVisible] = useState<number | null>(null);

    const onDeletePicture = useCallback(async (picture_id: number) => {
        const deleted = await deleteUnitPicture(unitId, picture_id);
        if (deleted) {
            onPictureDeleted(picture_id);
        }
    }, [unitId, onPictureDeleted]);

    const onClickRemoveImage = useCallback((imageIndex: number) => {
        const url = newPictures[imageIndex].url;
        if (url) {
            URL.revokeObjectURL(url);
        }
        const updatedPictures = newPictures.filter((_, index) => index !== imageIndex);
        onUpdatePictures(updatedPictures);
    }, [newPictures, onUpdatePictures]);

    const onPictureSelected = useCallback((newFiles: UploadedFile[]) => {
        // Filter out duplicate files
        const uniqueNewFiles = newFiles.filter(newFile =>
            !newPictures.some(image => image.file.name === newFile.file.name)
        );

        // Map new files to include URLs
        const newImages = uniqueNewFiles.map(newFile => ({
            ...newFile,
            url: URL.createObjectURL(newFile.file),
        }));

        // Update state with new images
        onUpdatePictures([...newPictures, ...newImages]);
    }, [newPictures, onUpdatePictures]);

    const hasEditPermission = useMemo(() => {
        return employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER;
    }, [employeeRoleId]);

    return (
        <>
            <ExpandableSection
                className="mt-4"
                title={t("pages.unit_detail.available_pictures_title", { number: pictures.length })}
                isHeadlineLarge
                isDefaultExpanded
            >
                <div className="white-card">
                    <div className="row gx-2 gy-2">
                        {pictures.map((image, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <div className={styles.imageSquare}>
                                    <LazyLoadImage src={`${SPACES_ENDPOINT}${image.picture_path}`} alt={`image-${index}`} className="img-fluid" effect="opacity" />
                                    <IoCloseCircle className={styles.removeIcon} onClick={() => setIsDeletePictureModalVisible(image.picture_id)} />
                                </div>
                            </div>
                        ))}
                        {newPictures.map((image, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <div className={styles.imageSquare}>
                                    <LazyLoadImage src={URL.createObjectURL(image.file)} alt={`image-${index}`} className="img-fluid" effect="opacity" />
                                    <IoCloseCircle className={styles.removeIcon} onClick={() => onClickRemoveImage(index)} />
                                </div>
                            </div>
                        ))}

                        {/* Handle File Uploader Visibility */}
                        {hasEditPermission && (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-2 file-upload-wrapper">
                                <FileUpload
                                    id="construction-update-pictures"
                                    style={{ height: "100%" }}
                                    allowedTypes="image/*"
                                    onFileSelected={onPictureSelected}
                                    currentFileCount={newPictures.length}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ExpandableSection>

            {isDeletePictureModalVisible && (
                <DeletePictureModal
                    onClose={() => {
                        setIsDeletePictureModalVisible(null);
                    }}
                    onDeletePicture={() => {
                        onDeletePicture(isDeletePictureModalVisible);
                        setIsDeletePictureModalVisible(null);
                    }}
                />
            )}
        </>
    );
};

export default UnitPicturesSection;
