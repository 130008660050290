import { API_URL } from "../utils/constants";

export interface IPaymentPlanItem {
  id: number;
  due_date: string;
  description: string;
  percentage: number | null;
  fixed_amount: number | null;
  created_at: string;
}

export const fetchPaymentPlanItems = async (
  url: string
): Promise<IPaymentPlanItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.payment_plan_items;
};

export const constructPaymentPlanItemsUrl = (property_id: number): string => {
  let queryParams = new URLSearchParams();

  if (property_id !== undefined) {
    queryParams.append("property_id", property_id.toString());
  }

  return `${API_URL}/payment-plan?${queryParams.toString()}`;
};

export const createPaymentPlanItem = async (
  property_id: number,
  due_date: string,
  description: string,
  percentage?: number,
  fixed_amount?: number
): Promise<IPaymentPlanItem> => {
  const response = await fetch(`${API_URL}/payment-plan`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      property_id,
      due_date,
      description,
      percentage: percentage ?? null,
      fixed_amount: fixed_amount ?? null,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.payment_plan_item);
};

export const updatePaymentPlanItem = async (
  item_id: number,
  due_date: string,
  description: string,
  percentage?: number,
  fixed_amount?: number
): Promise<IPaymentPlanItem> => {
  const response = await fetch(`${API_URL}/payment-plan/${item_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      due_date,
      description,
      percentage: percentage ?? null,
      fixed_amount: fixed_amount ?? null,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.payment_plan_item);
};

export const deletePaymentPlanItem = async (
  item_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/payment-plan/${item_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};
