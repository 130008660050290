import useSWRInfinite from 'swr/infinite';
import { IEmployee, constructEmployeesUrl, fetchEmployees } from '../services/employeeService';

const PAGE_SIZE = 20;

export const useEmployees = (roleId: number | null, search: string) => {
    const fetcher = (url: string) => fetchEmployees(url);

    const getKey = (pageIndex: number, previousPageData: IEmployee[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructEmployeesUrl(roleId, search, pageIndex + 1, PAGE_SIZE);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IEmployee[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        employees: data ? ([] as IEmployee[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
