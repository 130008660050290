import { API_URL } from "../utils/constants";

export interface IAddress {
  address_id: number;
  street_address1?: string;
  street_address2?: string;
  city?: string;
  state_province_region?: string;
  postal_code?: string;
  country?: string;
}

export const updateAddress = async (address: IAddress): Promise<IAddress> => {
  const response = await fetch(`${API_URL}/addresses/${address.address_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(address),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};
