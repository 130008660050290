import './available-units.css';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../../../utils/helpers';
import { useAvailableUnits } from '../../../../hooks/useAvailableUnits';

interface AvailableUnitsProps {
    propertyId: number
}

const AvailableUnits: React.FC<AvailableUnitsProps> = ({ propertyId }) => {
    const { t } = useTranslation();
    const {
        availableUnits,
        isLoading
    } = useAvailableUnits(Number(propertyId), 5, "");

    const onClickUnit = (unitId: number) => {
        window.open(`/units/${unitId}`, '_blank');
    }

    const onClickViewAll = useCallback(() => {
        window.open(`/properties/${propertyId}/available-units`, '_blank');
    }, [propertyId]);

    return (
        <div className="available-units">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="title">{t("pages.property_detail.available_units.title")}</h5>
                <button className="btn btn-view-all" onClick={onClickViewAll}>{t("buttons.view_all")}</button>
            </div>
            {isLoading ? (
                <div className="available-units-table-responsive">
                    <table className="available-units-table">
                        <thead>
                            <tr>
                                <th>{t("unit_table.name_header")}</th>
                                <th>{t("unit_table.bedroom_header")}</th>
                                <th>{t("unit_table.bathroom_header")}</th>
                                <th>{t("unit_table.minimum_annual_rent_header")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(5)].map((_, idx) => (
                                <tr key={idx}>
                                    <td><span className="skeleton unit-id"></span></td>
                                    <td><span className="skeleton bedroom"></span></td>
                                    <td><span className="skeleton bathroom"></span></td>
                                    <td><span className="skeleton rent"></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                availableUnits.length > 0 ? (
                    <div className="available-units-table-responsive">
                        <table className="available-units-table">
                            <thead>
                                <tr>
                                    <th>{t("unit_table.name_header")}</th>
                                    <th>{t("unit_table.bedroom_header")}</th>
                                    <th>{t("unit_table.bathroom_header")}</th>
                                    <th>{t("unit_table.minimum_annual_rent_header")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {availableUnits.map(unit => (
                                    <tr key={unit.unit_id} onClick={() => onClickUnit(unit.unit_id)}>
                                        <td>{unit.unit_id}</td>
                                        <td>{unit.bedroom}</td>
                                        <td>{unit.bathroom}</td>
                                        <td>
                                            <span className="total-rent-amount-badge">
                                                {unit.minimum_annual_rent ? formatMoney(unit.minimum_annual_rent) : "-"}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="available-units-card">
                        <p className="no-units-text">{t("pages.property_detail.available_units.no_units_text")}</p>
                    </div>
                )
            )}
        </div>
    );
};

export default AvailableUnits;
