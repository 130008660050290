// EmployeesPage.tsx

import { useEffect, useState } from "react";
import CustomButton from "../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../utils/helpers";
import useSearchStore from "../stores/searchStore";
import { useTranslation } from "react-i18next";
import { IEmployee } from "../services/employeeService";
import EmployeeTable from "../components/employee-table/employee-table";
import EmployeeSidebarModal from "../modals/sidebar/employee-sidemodal/employee-sidemodal";
import Switch, { SwitchOptions } from "../components/switch/switch";
import { fetchRoles } from "../services/roleService";
import DeleteEmployeeModal from "../modals/delete-employee-modal/delete-employee-modal";
import { useEmployees } from "../hooks/useEmployees";
import useAlertStore from "../stores/alertStore";
import useDebounce from "../hooks/useDebounce";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading.-anim.json";
import usePageTitle from "../hooks/usePageTitle";

const EmployeesPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.employees.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [selectedRole, setSelectedRole] = useState<number | null>(null);
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(null);
    const [isDeleteEmployeeModalVisible, setIsDeleteEmployeeModalVisible] = useState<IEmployee | null>(null);
    const [roles, setRoles] = useState<SwitchOptions[]>([]);
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);

    const debouncedKeyword = useDebounce(search, 500);
    const {
        employees,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useEmployees(selectedRole, debouncedKeyword);

    // Fetch and set filter
    useEffect(() => {
        const loadEmployeeRoles = async () => {
            try {
                const fetchedRoles = await fetchRoles();
                setRoles([{ label: t("pages.investor.filter.all"), value: "0" }, ...fetchedRoles.map(agent => {
                    return { label: `${agent.role_name}`, value: agent.role_id.toString() }
                })]);
            } catch (error) {
                // @ts-ignore
                showAlert("error", error.message);
            }
        }
        loadEmployeeRoles();
    }, [t, showAlert]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedRole(index === 0 ? null : index);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.employees.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.employees.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                <div className="d-flex d-none d-sm-inline">
                    <CustomButton
                        title={t("pages.employees.create_employee_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            </div>

            {/* MOBILE BUTTONS */}
            <div className="d-flex d-sm-none mt-3">
                <CustomButton
                    title={t("pages.employees.create_employee_button")}
                    icon={BsPlus}
                    isLoading={false}
                    onClick={() => setIsSidebarModalVisible(true)}
                />
            </div>

            {/* FILTER BUTTONS  */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={roles} value={selectedRole ? selectedRole.toString() : "0"} onChange={handleFilterClick} />
                {/*<SortingButton
                    options={[
                        { label: 'Nach Name', value: 'name', order: sort.order },
                        { label: 'Nach letzter Aktivität', value: 'lastActivity', order: sort.order }
                    ]}
                    defaultSort={sort.field}
                    onSortChange={handleSortChange}
                    disabled={false} // toggle based on your needs
                />*/}
            </div>

            <Switch className="d-sm-none mt-4" options={roles} value={selectedRole ? selectedRole.toString() : "0"} onChange={handleFilterClick} />

            {/*<SortingButton
                className="mt-3 d-sm-none d-flex"
                options={[
                    { label: 'Nach Name', value: 'name', order: sort.order },
                    { label: 'Nach letzter Aktivität', value: 'lastActivity', order: sort.order }
                ]}
                defaultSort={sort.field}
                onSortChange={handleSortChange}
                disabled={false} // toggle based on your needs
            />*/}

            {/* EMPLOYEES OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <div className="mt-4 white-card">
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={employees.length}
                        next={loadMore}
                        hasMore={!isReachingEnd}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <EmployeeTable
                            employees={employees}
                            onClickEmployee={(employee_id: number) => {
                                setSelectedEmployee(employees.find(employee => employee.employee_id === employee_id) ?? null);
                                setIsSidebarModalVisible(true);
                            }}
                        />
                    </InfiniteScroll>
                </div>
            )}

            <EmployeeSidebarModal
                isVisible={isSidebarModalVisible}
                selectedEmployee={selectedEmployee ?? undefined}
                onClose={() => {
                    setIsSidebarModalVisible(false);
                    setSelectedEmployee(null);
                }}
                onClickDeleteEmployee={(employee_id: number) => {
                    setIsSidebarModalVisible(false);
                    setSelectedEmployee(null);
                    setIsDeleteEmployeeModalVisible(employees.find(employee => employee.employee_id === employee_id) ?? null);
                }}
                mutate={mutate}
            />

            {isDeleteEmployeeModalVisible && (
                <DeleteEmployeeModal
                    employee={isDeleteEmployeeModalVisible}
                    onClose={() => setIsDeleteEmployeeModalVisible(null)}
                    mutate={mutate}
                />
            )}
        </div>
    );
};

export default EmployeesPage;
