import './tenant-card.css';

import React, { useCallback } from 'react';
import { IoAdd, IoCallOutline, IoMailOutline } from 'react-icons/io5';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import { getFormattedDate } from '../../../../utils/helpers';
import { ILease } from '../../../../services/leaseService';
import { useTranslation } from 'react-i18next';

interface TenantCardProps {
    lease: ILease | null;
    title: string;
    noLeaseText: string;
    onClickTenant: (tenant_id: number) => void;
    setIsSidebarModalVisible: (visible: boolean) => void;
}

const TenantCard: React.FC<TenantCardProps> = ({ lease, title, noLeaseText, onClickTenant, setIsSidebarModalVisible }) => {
    const { t } = useTranslation();

    const handleAddClick = useCallback(() => {
        setIsSidebarModalVisible(true);
    }, [setIsSidebarModalVisible]);

    return (
        <div className="col-12">
            <div className="unit-tenant-details-card">
                {lease && lease.tenant ? (
                    <>
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <h5 className="headline">{title}</h5>
                        </div>
                        <div className="divider" />
                        <div className="tenants ms-4">
                            <div
                                key={lease.tenant.tenant_id}
                                className="d-flex tenant-details align-items-center mb-3"
                                onClick={() => onClickTenant(lease.tenant.tenant_id)}
                            >
                                <img className="avatar" src={DefaultAvatar} alt="Tenant Avatar" />
                                <div className="ms-3">
                                    <div className="d-flex align-items-center mb-1">
                                        <h4 className="name">
                                            {lease.tenant.first_name} {lease.tenant.last_name}
                                        </h4>
                                        <OverlayTrigger overlay={<Tooltip>{lease.tenant.phone_number}</Tooltip>}>
                                            <a href={`tel:${lease.tenant.phone_number}`} className="contact-link">
                                                <IoCallOutline className="contact-icon ms-2" />
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip>{lease.tenant.email}</Tooltip>}>
                                            <a href={`mailto:${lease.tenant.email}`} className="contact-link">
                                                <IoMailOutline className="contact-icon ms-2" />
                                            </a>
                                        </OverlayTrigger>
                                    </div>
                                    <h4 className="info">
                                        {getFormattedDate(lease.start_date)} - {getFormattedDate(lease.end_date)}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="d-flex justify-content-center align-items-center position-relative">
                        <h5 className="headline-empty">{noLeaseText}</h5>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-add-owner">{t("pages.unit_detail.tenant_details.add_tooltip")}</Tooltip>}
                        >
                            <div className="add-icon-container position-absolute end-0 me-3" onClick={handleAddClick}>
                                <IoAdd className="add-icon" />
                            </div>
                        </OverlayTrigger>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TenantCard;
