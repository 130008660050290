import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ILease } from "../services/leaseService";

interface LeaseDetailState {
  lease: ILease | null;
  isLoading: boolean;
  isDeleting: boolean;
  setLease: (lease: ILease) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsDeleting: (isDeleting: boolean) => void;
  reset: () => void;
}

const useLeaseDetailStore = create<LeaseDetailState>()(
  devtools(
    persist(
      (set, get) => ({
        lease: null,
        isLoading: true,
        isDeleting: false,
        setLease: (lease: ILease) => set({ lease }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsDeleting: (isDeleting: boolean) => set({ isDeleting }),
        reset: () => {
          set({ lease: null, isLoading: true });
        },
      }),
      {
        name: "lease-detail-storage",
      }
    )
  )
);

export default useLeaseDetailStore;
