import '../base-modal.css';
import './delete-picture-modal.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    onClose: () => void;
    onDeletePicture: () => void;
}

const DeletePictureModal: React.FC<ModalProps> = ({ onClose, onDeletePicture }) => {
    const { t } = useTranslation();

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.delete_picture.title")}</h3>
                <p>{t("modals.delete_picture.message")}</p>
                <div className="text-end mt-4">
                    <button className="btn btn-danger add-btn me-2" onClick={onDeletePicture}>
                        {t("buttons.delete")}
                    </button>
                    <button className="btn btn-dark close-btn" onClick={onClose}>{t("buttons.close")}</button>
                </div>
            </div>
        </div>
    );
};

export default DeletePictureModal;
