import { useRef, useState, useEffect } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import Switch, { SwitchOptions } from "../../components/switch/switch";
import InvestorTable from "../../components/investor-table/investor-table";
import InvestorSidebarModal from "../../modals/sidebar/investor-sidemodal/investor-sidemodal";
import { useInvestors } from '../../hooks/useInvestors';
import useSearchStore from "../../stores/searchStore";
import ImportInvestorsModal from "../../modals/investor-csv-import-modal/investor-csv-import-modal";
import { useTranslation } from "react-i18next";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";
import useAlertStore from "../../stores/alertStore";
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useDebounce from "../../hooks/useDebounce";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import InvestorFilters from "./components/investor-filters/investor-filters";
import useInvestorFilterStore from "../../stores/investorFilterStore";
import { InvestorSortBy } from "../../services/investorService";
import { SortOrder } from "../../@types";

const InvestorsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.investor.page_title"));

    // References
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        salesAgent,
        ownedUnits,
        totalPurchasedValue,
        selectedState,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedState,
    } = useInvestorFilterStore();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [csvFile, setCSVFile] = useState<File | null>(null);

    const switchOptions: SwitchOptions[] = [
        { label: t("pages.investor.filter.all"), value: "0" },
        { label: t("pages.investor.filter.active"), value: "1" },
        { label: t("pages.investor.filter.inactive"), value: "2" },
        { label: t("pages.investor.filter.drafts"), value: "3" },
        //{ label: t("pages.investor.filter.withoutDocuments"), value: "4" }
    ];

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const {
        investors,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useInvestors(selectedState, debouncedKeyword, salesAgent, ownedUnits, totalPurchasedValue, sortColumn || undefined, sortDirection || undefined);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedState(index === 0 ? null : index);
        setSize(1);
    };

    const handleSortChange = (column: InvestorSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    const handleFileSelectClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCSVFile(file);
        }
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.investor.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.investor.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                {employeeRoleId === EmployeeRole.ADMINISTRATOR && (
                    <div className="d-flex d-none d-sm-inline">
                        <CustomButton
                            className="me-3"
                            title={t("buttons.csv_import")}
                            color="green"
                            isLoading={false}
                            onClick={handleFileSelectClick}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <CustomButton
                            title={t("pages.investor.create_investor_button")}
                            icon={BsPlus}
                            isLoading={false}
                            onClick={() => setIsSidebarModalVisible(true)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE BUTTONS */}
            {employeeRoleId === EmployeeRole.ADMINISTRATOR && (
                <div className="d-flex d-sm-none mt-3">
                    <CustomButton
                        className="me-3"
                        title={t("buttons.csv_import")}
                        color="green"
                        isLoading={false}
                        onClick={handleFileSelectClick}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".csv"
                        onChange={handleFileChange}
                    />
                    <CustomButton
                        title={t("pages.investor.create_investor_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            )}

            {/* FILTER BUTTONS */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={switchOptions} value={selectedState ? selectedState.toString() : "0"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" value={selectedState ? selectedState.toString() : "0"} options={switchOptions} onChange={handleFilterClick} />

            {/* INVESTORS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <InvestorFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={investors.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <InvestorTable
                                investors={investors}
                                onSortChange={handleSortChange}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}

            {employeeRoleId === EmployeeRole.ADMINISTRATOR && (
                <>
                    <InvestorSidebarModal
                        isVisible={isSidebarModalVisible}
                        onClose={() => setIsSidebarModalVisible(false)}
                        mutate={mutate}
                    />

                    {csvFile && (
                        <ImportInvestorsModal
                            csv={csvFile}
                            onClose={(refresh: boolean) => {
                                setCSVFile(null)
                                if (refresh) {
                                    window.location.reload();
                                }
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default InvestorsPage;
