import React from 'react';
import countries from 'i18n-iso-countries';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import { useTranslation } from 'react-i18next';

interface NationalitySelectProps {
    className?: string;
    nationality: string;
    onChange: (nationality: string) => void;
    required?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    labelOnTop?: boolean;
}

const NationalitySelect: React.FC<NationalitySelectProps> = ({
    className,
    nationality,
    onChange,
    required = false,
    disabled = false,
    errorMessage,
    labelOnTop = false
}) => {
    const { t, i18n } = useTranslation();

    countries.registerLocale(require("i18n-iso-countries/langs/" + i18n.language + ".json"));

    const getNationalities = (): SidebarSelectFieldOptions[] => {
        return Object.entries(countries.getNames(i18n.language)).map(([code, name]) => ({
            label: name,
            value: code,
        }));
    };

    return (
        <SidebarSelectField
            className={className}
            id="nationality"
            label={t("labels.nationality")}
            value={nationality}
            onChange={(onChange)}
            options={getNationalities()}
            required={required}
            disabled={disabled}
            errorMessage={errorMessage}
            placeholder={t("placeholders.select")}
            labelOnTop={labelOnTop}
        />
    );
};

export default NationalitySelect;
