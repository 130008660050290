import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 12px;
`;

export const Headline = styled.h4`
  color: ${colorPalette.textMain};
  ${typeScale.headingXS}
`;

export const PlusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.primaryDark};
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: ${colorPalette.primaryMain};
  }
`;

export const ProgressList = styled.div`
  padding: 16px 16px 28px;
`;

export const ProgressLine = styled.div<{
  isTopItem: boolean;
  isBottomItem: boolean;
}>`
  width: 2px;
  position: absolute;
  top: -42px;
  bottom: -42px;
  background: ${({ isTopItem, isBottomItem }) =>
    isTopItem
      ? `linear-gradient(to top, ${colorPalette.successMedium} 50%, rgba(142, 203, 189, 0) 100%)`
      : isBottomItem
      ? `linear-gradient(to bottom, ${colorPalette.successMedium} 50%, rgba(142, 203, 189, 0) 100%)`
      : `${colorPalette.successMedium}`};
  z-index: 0;
`;

export const ProgressItem = styled.div<{ isLast: boolean }>`
  display: grid;
  grid-template-columns: 40px 100px 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0;
  position: relative;
  cursor: pointer;
`;

export const ProgressItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ProgressCircle = styled.div<{ isFirst: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  /* Outer Glow (innermost part of the gradient) */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(142, 203, 189, 0.1);
    z-index: 0;
    filter: blur(4px);
  }

  /* Middle Circle */
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(142, 203, 189, 0.3);
    z-index: 1;
  }

  /* Inner Circle */
  background-color: ${colorPalette.successMedium};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  z-index: 2;

  /* Breathing Animation */
  ${({ isFirst }) =>
    isFirst &&
    `
    animation: breathing 3s ease-in-out infinite;
  `}

  @keyframes breathing {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
  }
`;

export const StatusText = styled.p<{ isFirst: boolean }>`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXXSMain}
  display: ${({ isFirst }) => (isFirst ? "block" : "none")};
`;

export const ProgressPercentage = styled.p<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? colorPalette.successMedium : colorPalette.textDark};
  margin: 0;
  ${({ isActive }) =>
    isActive ? typeScale.headingL : typeScale.headingSMMain};
`;

export const ProgressDate = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXSMain}
  margin: 0;
`;

export const ProgressStatus = styled.p<{ isFirst: boolean }>`
  color: ${({ isFirst }) =>
    isFirst ? colorPalette.textMain : colorPalette.textDark};
  margin: 0;
  ${({ isFirst }) => (isFirst ? typeScale.bodyMD : typeScale.bodyXS)};
`;

export const ProgressIcon = styled.a`
  color: ${colorPalette.blue500};
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const EmptyText = styled.p`
  color: ${colorPalette.textDark};
  padding-left: 12px;
  padding-right: 12px;
  ${typeScale.bodySMMain}
`;
