// IndexPage.tsx
import useEmployeeStore from "../stores/employeeStore";
import { useEffect } from "react";
import { EmployeeRole } from "../services/employeeService";
import { useNavigate } from "react-router-dom";

const IndexPage = () => {
    const navigate = useNavigate();

    const { employee } = useEmployeeStore();

    useEffect(() => {
        if (!employee) {
            navigate("/login");
        }
        else if (employee.role_id === EmployeeRole.ADMINISTRATOR) {
            navigate("/dashboard");
        }
        else {
            navigate("/properties");
        }
    }, [employee, navigate]);

    return (<></>);
};

export default IndexPage;
