import './birthdate-select.css';
import { useTranslation } from 'react-i18next';
import React, { CSSProperties, useEffect, useState } from 'react';

interface BirthdateSelectProps {
    className?: string;
    id: string;
    label: string;
    date: Date | null;
    onChange: (date: Date) => void;
    required?: boolean;
    disabled?: boolean;
    inputStyle?: CSSProperties;
    errorMessage?: string;
    labelOnTop?: boolean;
}

const BirthdateSelect: React.FC<BirthdateSelectProps> = ({
    className,
    id,
    label,
    date,
    onChange,
    required = false,
    disabled = false,
    errorMessage,
    labelOnTop = false
}) => {
    const { t } = useTranslation();

    // States
    const [day, setDay] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');

    const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('en', { month: 'long' }));

    useEffect(() => {
        if (date) {
            const newDay = date.getDate().toString();
            const newMonth = months[date.getMonth()];
            const newYear = date.getFullYear().toString();
            setDay(newDay);
            setMonth(newMonth);
            setYear(newYear);
        }
    }, [date, months]);

    useEffect(() => {
        if (day && month && year) {
            const newDate = new Date(parseInt(year), months.indexOf(month), parseInt(day));
            if (!isNaN(newDate.getTime())) {
                onChange(newDate);
            }
        }
    }, [day, month, year, onChange, months]);

    const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDay(e.target.value);
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMonth(e.target.value);
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setYear(e.target.value);
    };

    const labelClasses = labelOnTop ? 'sidebar-select-label-on-top' : 'col-sm-5 col-form-label';
    const selectWrapperClasses = labelOnTop ? 'sidebar-select-wrapper-on-top' : 'col-sm-7';

    return (
        <div className={`sidebar-select-field ${labelOnTop ? '' : 'row'} ${className}`}>
            <label htmlFor={id} className={labelClasses}>
                {label}
                {required && <span className="text-red ms-1">*</span>}
            </label>
            <div className={selectWrapperClasses}>
                <div className="d-flex align-items-center" style={labelOnTop ? { height: 50 } : {}}>
                    <div className="date-of-birth-select">
                        <select id={`${id}-day`} value={day} onChange={handleDayChange} disabled={disabled} required={required}>
                            <option value="">{t("placeholders.day")}</option>
                            {Array.from({ length: 31 }, (_, i) => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
                        </select>
                        <select id={`${id}-month`} value={month} onChange={handleMonthChange} disabled={disabled} required={required}>
                            <option value="">{t("placeholders.month")}</option>
                            {months.map((m, index) => <option key={index} value={m}>{m}</option>)}
                        </select>
                        <select id={`${id}-year`} value={year} onChange={handleYearChange} disabled={disabled} required={required}>
                            <option value="">{t("placeholders.year")}</option>
                            {Array.from({ length: 100 }, (_, i) => <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>)}
                        </select>
                    </div>
                </div>
                {errorMessage && <div className="ms-2 error-message">{errorMessage}</div>}
            </div>
        </div>
    );
};

export default BirthdateSelect;
