import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";
import { LeaseSortBy } from "../services/leaseService";

interface LeaseFilterState {
  total_rent_amount: string;
  security_deposit: string;
  payment_frequency: string;
  duration: string;
  sortColumn: LeaseSortBy | null;
  sortDirection: SortOrder | null;
  selectedProperty: number | null;
  isExpanded: boolean;
  setFilters: (
    total_rent_amount: string,
    security_deposit: string,
    payment_frequency: string,
    duration: string
  ) => void;
  setSort: (column: LeaseSortBy, direction: SortOrder) => void;
  setSelectedProperty: (propertyId: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useLeaseFilterStore = create<LeaseFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        total_rent_amount: "",
        security_deposit: "",
        payment_frequency: "",
        duration: "",
        sortColumn: null,
        sortDirection: null,
        selectedProperty: null,
        isExpanded: false,
        setFilters: (
          total_rent_amount,
          security_deposit,
          payment_frequency,
          duration
        ) =>
          set({
            total_rent_amount,
            security_deposit,
            payment_frequency,
            duration,
          }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "lease-filter-storage",
      }
    )
  )
);

export default useLeaseFilterStore;
