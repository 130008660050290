import "./bar-chart.css";

import React from 'react';
import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip, Chart as ChartJS, } from "chart.js";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface BarChartProps {
    dataNumbers: number[];
}

const BarChart: React.FC<BarChartProps> = ({ dataNumbers }) => {
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Investoren Zuwachs pro Monat',
                data: dataNumbers,
                backgroundColor: '#A0C3FF',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                barThickness: 15,
            },
        ],
    };

    const options = {
        scales: {
            y: { // 'yAxes' is now just 'y' in Chart.js v3+
                beginAtZero: true,
                ticks: {
                    color: '#4F4F4F',
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.8)',
                },
            },
            x: { // 'xAxes' is now just 'x'
                ticks: {
                    color: '#4F4F4F',
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.8)',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
