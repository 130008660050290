import "./unit-general-section.css";

import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import { EmployeeRole } from "../../../../services/employeeService";
import { useMemo, useState } from "react";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import { formatSoldBy, IUnit, UnitPurposeType, UnitSoldBy } from "../../../../services/unitService";
import SidebarSelectField, { SidebarSelectFieldOptions } from "../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field";

interface UnitGeneralSectionProps {
    employeeRoleId: number | undefined;
    unit: IUnit;
    onUpdateUnit: (unit: IUnit) => void;
}

const UnitGeneralSection: React.FC<UnitGeneralSectionProps> = ({ employeeRoleId, unit, onUpdateUnit }) => {

    const purposeTypeOptions: SidebarSelectFieldOptions[] = Object.values(UnitPurposeType).map((purpose) => ({
        label: purpose.replace(/-/g, ' ').replace(/\b\w/g, (p) => p.toUpperCase()),
        value: purpose,
    }));

    const soldByOptions: SidebarSelectFieldOptions[] = Object.values(UnitSoldBy).map((soldBy) => ({
        label: formatSoldBy(soldBy),
        value: soldBy,
    }));

    // Error States
    const [annualRentError, setAnnualRentError] = useState<string>("");
    const [unitNumberError, setUnitNumberError] = useState<string>("");
    const [floorError, setFloorError] = useState<string>("");
    const [squareFeetError, setSquareFeetError] = useState<string>("");
    const [bedroomError, setBedroomError] = useState<string>("");
    const [bathroomError, setBathroomError] = useState<string>("");

    const hasEditPermission = useMemo(() => {
        return employeeRoleId === EmployeeRole.ADMINISTRATOR
    }, [employeeRoleId]);

    return (
        <ExpandableSection
            className="unit-general-section"
            title={t("pages.unit_detail.general_information_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <SidebarSelectField
                            id="unit-creation-purpose-type-selection"
                            label={t("labels.purpose_type")}
                            value={unit.purpose_type}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, purpose_type: UnitPurposeType[text.toUpperCase().replace("-", "_") as keyof typeof UnitPurposeType] });
                            }}
                            options={purposeTypeOptions}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <SidebarSelectField
                            id="unit-creation-sold-by-selection"
                            label={t("labels.sold_by")}
                            value={unit.sold_by}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, sold_by: UnitSoldBy[text.toUpperCase().replace("-", "_") as keyof typeof UnitSoldBy] });
                            }}
                            options={soldByOptions}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-md-6 col-md-6">
                        <CustomInputField
                            id="unit-number"
                            label={t("labels.unit_number")}
                            value={unit.unit_number}
                            errorMessage={unitNumberError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, unit_number: text });
                                setUnitNumberError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, unit_number: "" });
                                setUnitNumberError("");
                            }}
                            placeholder="G05"
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-6 col-xl-3">
                        <CustomInputField
                            id="floor"
                            type="text"
                            label={t("labels.floor")}
                            placeholder="10"
                            value={unit.floor.toString()}
                            errorMessage={floorError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, floor: text });
                                setFloorError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, floor: "" });
                                setFloorError("");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-6 col-xl-3">
                        <CustomInputField
                            id="square-feet"
                            type="number"
                            label={t("labels.square_feet")}
                            placeholder="870"
                            value={unit.square_feet.toString()}
                            errorMessage={squareFeetError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, square_feet: Number(text) });
                                setSquareFeetError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, square_feet: 0 });
                                setSquareFeetError("");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-6 col-xl-4">
                        <CustomInputField
                            id="unit-annual-rent-number"
                            type="number"
                            label={t("labels.annual_rent")}
                            value={unit.minimum_annual_rent?.toString() ?? ""}
                            errorMessage={annualRentError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, minimum_annual_rent: Number(text) });
                                setAnnualRentError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, minimum_annual_rent: null });
                                setAnnualRentError("");
                            }}
                            placeholder="70,000.00 AED"
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-6 col-xl-4">
                        <CustomInputField
                            id="bedroom"
                            type="number"
                            label={t("labels.bedroom")}
                            placeholder="2"
                            value={unit.bedroom.toString()}
                            errorMessage={bedroomError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, bedroom: Number(text) });
                                setBedroomError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, bedroom: 0 });
                                setBedroomError("");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-6 col-xl-4">
                        <CustomInputField
                            id="bathroom"
                            type="number"
                            label={t("labels.bathroom")}
                            placeholder="1"
                            value={unit.bathroom.toString()}
                            errorMessage={bathroomError}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, bathroom: Number(text) });
                                setBathroomError("");
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, bathroom: 0 });
                                setBathroomError("");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                </div>
            </div>
        </ExpandableSection>
    );
}

export default UnitGeneralSection;
