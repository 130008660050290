import "./investor-general-section.css";

import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import { useEffect, useState } from "react";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import useEditPermission from "../../../../hooks/useEditPermission";
import CustomPhoneInput from "../../../../components/custom-phone-input/custom-phone-input";
import SidebarSelectField from "../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field";
import { EmployeeRole } from "../../../../services/employeeService";
import { INVESTOR_STATUS_OPTIONS } from "../../../../utils/constants";
import { IInvestor } from "../../../../services/investorService";
import { ISalesAgent, fetchSalesAgents } from "../../../../services/salesAgentService";

interface InvestorGeneralSectionProps {
    employeeRoleId: number | undefined;
    investor: IInvestor;
    firstnameError: string;
    lastnameError: string;
    emailError: string;
    phoneNumberError: string;
    onUpdateInvestor: (investor: IInvestor) => void;
    onClearError: (field: String) => void;
}

const InvestorGeneralSection: React.FC<InvestorGeneralSectionProps> = ({
    employeeRoleId,
    investor,
    firstnameError,
    lastnameError,
    emailError,
    phoneNumberError,
    onUpdateInvestor,
    onClearError,
}) => {
    const hasEditPermission = useEditPermission(employeeRoleId);

    // Error States
    const [salesAgents, setSalesAgents] = useState<ISalesAgent[]>([]);

    // Fetch Sales Agents
    useEffect(() => {
        const loadSalesAgents = async () => {
            try {
                const agents = await fetchSalesAgents();
                setSalesAgents(agents);
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            }
        }
        loadSalesAgents();
    }, []);

    return (
        <ExpandableSection
            className="investor-general-section"
            title={t("pages.investor_detail.general_information_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <CustomInputField
                            id="investor-firstname"
                            label={t("labels.firstname")}
                            value={investor.first_name}
                            errorMessage={firstnameError}
                            onChange={(text: string) => {
                                onUpdateInvestor({ ...investor, first_name: text });
                                onClearError("firstname");
                            }}
                            onClear={() => {
                                onUpdateInvestor({ ...investor, first_name: "" });
                                onClearError("firstname");
                            }}
                            placeholder="Max"
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <CustomInputField
                            id="investor-lastname"
                            label={t("labels.lastname")}
                            placeholder="Mustermann"
                            value={investor.last_name}
                            errorMessage={lastnameError}
                            onChange={(text: string) => {
                                onUpdateInvestor({ ...investor, last_name: text });
                                onClearError("lastname");
                            }}
                            onClear={() => {
                                onUpdateInvestor({ ...investor, last_name: "" });
                                onClearError("lastname");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <CustomInputField
                            id="investor-email"
                            type="email"
                            label={t("labels.email")}
                            placeholder="max@mustermann.de"
                            value={investor.email}
                            errorMessage={emailError}
                            onChange={(text: string) => {
                                onUpdateInvestor({ ...investor, email: text });
                                onClearError("email");
                            }}
                            onClear={() => {
                                onUpdateInvestor({ ...investor, email: "" });
                                onClearError("email");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <CustomPhoneInput
                            id="investor-phone-number"
                            defaultCountry="DE"
                            value={investor.phone_number ?? undefined}
                            errorMessage={phoneNumberError}
                            onChange={(text: string) => {
                                onUpdateInvestor({ ...investor, phone_number: text });
                                onClearError("phone_number");
                            }}
                            onClear={() => {
                                onUpdateInvestor({ ...investor, phone_number: null });
                                onClearError("phone_number");
                            }}
                            disabled={!hasEditPermission}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <SidebarSelectField
                            id="investor-sales-agent"
                            label={t("labels.sales_agent")}
                            value={investor.sales_agent?.employee_id ? investor.sales_agent.employee_id.toString() : undefined}
                            onChange={(value: string) => {
                                const salesAgent = salesAgents.find(agent => agent.employee_id === Number(value));
                                onUpdateInvestor({ ...investor, sales_agent: salesAgent ?? null });
                            }}
                            options={salesAgents.map(agent => {
                                return { label: `${agent.first_name} ${agent.last_name}`, value: agent.employee_id.toString() }
                            })}
                            disabled={employeeRoleId !== EmployeeRole.ADMINISTRATOR}
                            labelOnTop
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <SidebarSelectField
                            id="investor-status"
                            label={t("labels.status")}
                            value={investor.status.status_id.toString()}
                            onChange={(value: string) => {
                                const statusName = INVESTOR_STATUS_OPTIONS.find(status => status.value === value);
                                onUpdateInvestor({ ...investor, status: { status_id: Number(value), status_name: statusName?.label ?? "" } });
                            }}
                            options={INVESTOR_STATUS_OPTIONS}
                            disabled={employeeRoleId !== EmployeeRole.ADMINISTRATOR}
                            required
                            labelOnTop
                        />
                    </div>
                </div>
            </div>
        </ExpandableSection>
    );
}

export default InvestorGeneralSection;
