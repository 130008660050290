import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCallOutline, IoMailOutline } from 'react-icons/io5';
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import styles from './investor-details.module.css';
import { IUnitOwner } from '../../../../services/unitOwnerService';

interface InvestorDetailsProps {
    owners: IUnitOwner[];
}

const InvestorDetails: React.FC<InvestorDetailsProps> = ({ owners }) => {
    const { t } = useTranslation();

    const onClickInvestor = (investor_id: number) => {
        const url = `${window.location.origin}/investors/${investor_id}`;
        window.open(url, '_blank');
    };

    return (
        <div className={styles.investorDetails}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className={styles.title}>{t("pages.lease_detail.investor_details.title")}</h5>
            </div>

            {/* Iterate through owners */}
            {owners.length > 0 ? (
                owners.map((owner) => (
                    <div className={styles.investorDetailsCard} key={owner.investor.investor_id} onClick={() => onClickInvestor(owner.investor.investor_id)}>
                        <div className={styles.investor}>
                            <img className={styles.avatar} src={DefaultAvatar} alt="Investor Avatar" />
                            <div className={styles.investorInfo}>
                                <div className={styles.investorHeader}>
                                    <h4 className={styles.name}>
                                        {owner.investor.first_name} {owner.investor.last_name}
                                    </h4>
                                    {owner.investor.phone_number && (
                                        <OverlayTrigger overlay={<Tooltip>{owner.investor.phone_number}</Tooltip>}>
                                            <a href={`tel:${owner.investor.phone_number}`} className={styles.contactLink}>
                                                <IoCallOutline className={styles.contactIcon} />
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                    <OverlayTrigger overlay={<Tooltip>{owner.investor.email}</Tooltip>}>
                                        <a href={`mailto:${owner.investor.email}`} className={styles.contactLink}>
                                            <IoMailOutline className={styles.contactIcon} />
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <h4 className={styles.info}>
                                    {t("labels.shares")}: {owner.shares}%
                                </h4>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className={styles.investorDetailsCard}>
                    <p className={styles.emptyText}>{t("pages.lease_detail.investor_details.no_owners")}</p>
                </div>
            )}
        </div>
    );
};

export default InvestorDetails;
