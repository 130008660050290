import { useMemo } from 'react';
import { EmployeeRole } from '../services/employeeService';

const useEditPermission = (employeeRoleId?: number | null, allowedRoles: EmployeeRole[] = [EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]) => {
    const hasEditPermission = useMemo(() => {
        return employeeRoleId != null && allowedRoles.includes(employeeRoleId);
    }, [employeeRoleId, allowedRoles]);

    return hasEditPermission;
};

export default useEditPermission;
