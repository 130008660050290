import "./unit-condition-section.css";

import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import { IUnit } from "../../../../services/unitService";
import { useMemo } from "react";
import { EmployeeRole } from "../../../../services/employeeService";

interface UnitConditionSectionProps {
    employeeRoleId: number | undefined;
    unit: IUnit;
    onUpdateUnit: (unit: IUnit) => void;
}

const UnitConditionSection: React.FC<UnitConditionSectionProps> = ({ employeeRoleId, unit, onUpdateUnit }) => {

    const hasEditPermission = useMemo(() => {
        return employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER
    }, [employeeRoleId]);

    return (
        <ExpandableSection
            className="unit-condition-section"
            title={t("pages.unit_detail.unit_condition_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                <div className="row gy-3">
                    <div className="col-12 col-md-6 col-xl-4 form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={unit.under_renovation}
                            onChange={(e) => onUpdateUnit({ ...unit, under_renovation: e.target.checked })}
                            disabled={!hasEditPermission}
                        />
                        <label className="form-check-label">{t("labels.under_renovation")}</label>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={unit.under_dispute}
                            onChange={(e) => onUpdateUnit({ ...unit, under_dispute: e.target.checked })}
                            disabled={!hasEditPermission}
                        />
                        <label className="form-check-label">{t("labels.under_dispute")}</label>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={unit.pm_contract_signed}
                            onChange={(e) => onUpdateUnit({ ...unit, pm_contract_signed: e.target.checked })}
                            disabled={!hasEditPermission}
                        />
                        <label className="form-check-label">{t("labels.pm_contract_signed")}</label>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={unit.furnished}
                            onChange={(e) => onUpdateUnit({ ...unit, furnished: e.target.checked })}
                            disabled={!hasEditPermission}
                        />
                        <label className="form-check-label">{t("labels.furnished")}</label>
                    </div>
                </div>
            </div>
        </ExpandableSection>
    );
}

export default UnitConditionSection;
