// LeasesPage.tsx

import { useCallback, useEffect, useMemo, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useSearchStore from "../../stores/searchStore";
import { useTranslation } from "react-i18next";
import Switch from "../../components/switch/switch";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";
import { useNavigate } from "react-router-dom";
import LeaseTable from "../../components/lease-table/lease-table";
import LeaseSidebarModal from "../../modals/sidebar/lease-sidemodal/lease-sidemodal";
import useDebounce from "../../hooks/useDebounce";
import { useProperties } from "../../hooks/useProperties";
import { useLeases } from "../../hooks/useLeases";
import useAlertStore from "../../stores/alertStore";
import { ILease, LeaseSortBy } from "../../services/leaseService";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { SortOrder } from "../../@types";
import useLeaseFilterStore from "../../stores/leaseFilterStore";
import LeaseFilters from "./components/lease-filters/lease-filters";

const LeasesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.leases.page_title"));

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        total_rent_amount,
        security_deposit,
        payment_frequency,
        duration,
        selectedProperty,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedProperty,
    } = useLeaseFilterStore();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const { properties } = useProperties();
    const {
        leases,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useLeases(
        selectedProperty,
        debouncedKeyword,
        total_rent_amount,
        security_deposit,
        payment_frequency,
        duration,
        sortColumn || undefined,
        sortDirection || undefined
    );

    const propertyOptions = useMemo(() => {
        return [
            ...properties.map(property => ({
                label: `${property.property_name}`,
                value: property.property_id.toString(),
            }))
        ];
    }, [properties]);

    useEffect(() => {
        if (selectedProperty === null && propertyOptions.length > 0) {
            setSelectedProperty(Number(propertyOptions[0].value));
        }
    }, [propertyOptions, selectedProperty, setSelectedProperty]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedProperty(index === 0 ? null : index);
        setSize(1);
    }

    const handleSortChange = (column: LeaseSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    const onClickLease = (lease_id: number) => {
        navigate(`/leases/${lease_id}`);
    };

    const onLeaseCreated = useCallback((lease: ILease) => {
        mutate(
            (data: ILease[][] | undefined) => {
                if (!data) return [];
                return [
                    [lease, ...data[0]],
                    ...data.slice(1)
                ];
            },
            false
        );
    }, [mutate]);

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.leases.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.leases.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                    <div className="d-flex d-none d-sm-inline">
                        <CustomButton
                            title={t("pages.leases.create_button")}
                            icon={BsPlus}
                            isLoading={false}
                            onClick={() => setIsSidebarModalVisible(true)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE BUTTONS */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <div className="d-flex d-sm-none mt-3">
                    <CustomButton
                        title={t("pages.leases.create_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            )}

            {/* FILTER BUTTONS  */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />

            {/* LEASES OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <LeaseFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={leases.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <LeaseTable
                                leases={leases}
                                onSortChange={handleSortChange}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                                onClickLease={onClickLease}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}

            {/* CREATE LEASE SIDEBAR MODAL */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <LeaseSidebarModal
                    isVisible={isSidebarModalVisible}
                    onClose={() => setIsSidebarModalVisible(false)}
                    onLeaseCreated={onLeaseCreated}
                />
            )}
        </div>
    );
};

export default LeasesPage;
