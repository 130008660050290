import useSWRInfinite from 'swr/infinite';
import { ICourseModuleItem, constructCourseModuleItemsUrl, fetchCourseModuleItems } from '../services/courseService';

const PAGE_SIZE = 20;

export const useCourseModuleItems = (module_id: number) => {
    const fetcher = (url: string) => fetchCourseModuleItems(url);

    const getKey = (pageIndex: number, previousPageData: ICourseModuleItem[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructCourseModuleItemsUrl(module_id, pageIndex + 1, PAGE_SIZE);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<ICourseModuleItem[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        courseModuleItems: data ? ([] as ICourseModuleItem[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
