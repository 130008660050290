import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DateSelect from '../../../../components/date-select/date-select';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import SidebarInputField from '../../components/sidebar-input-field/sidebar-input-field';

interface LeaseGeneralInfoSectionProps {
    startDate: Date | null;
    endDate: Date | null;
    remark: string;
    startDateError: string;
    endDateError: string;
    onStartDateChange: (date: Date) => void;
    onEndDateChange: (date: Date) => void;
    onRemarkChange: (value: string) => void;
}

const LeaseGeneralInfoSection: React.FC<LeaseGeneralInfoSectionProps> = ({
    startDate,
    endDate,
    remark,
    startDateError,
    endDateError,
    onStartDateChange,
    onEndDateChange,
    onRemarkChange,
}) => {
    const { t } = useTranslation();

    const handleEndDateChange = useCallback((selectedDate: Date) => {
        if (startDate) {
            const oneYearMinusOneDay = new Date(startDate);
            oneYearMinusOneDay.setFullYear(oneYearMinusOneDay.getFullYear() + 1);
            oneYearMinusOneDay.setDate(oneYearMinusOneDay.getDate() - 1);

            if (selectedDate < oneYearMinusOneDay) {
                onEndDateChange(oneYearMinusOneDay);
            } else {
                onEndDateChange(selectedDate);
            }
        }
    }, [startDate, onEndDateChange]);

    return (
        <ExpandableSection title={t("modals.create_lease.general_title")} className="mt-4">
            <div className="row">
                <div className="col-12 col-md-6">
                    <DateSelect
                        id="start-date"
                        label={t("labels.start_date")}
                        placeholder={t("placeholders.select_date")}
                        errorMessage={startDateError}
                        required
                        labelOnTop
                        date={startDate ?? null}
                        onChange={(value: Date) => onStartDateChange(value)}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <DateSelect
                        id="end-date"
                        label={t("labels.end_date")}
                        placeholder={t("placeholders.select_date")}
                        errorMessage={endDateError}
                        required
                        labelOnTop
                        date={endDate ?? null}
                        onChange={handleEndDateChange}
                    />
                </div>
                <p className="hint-message">{t("modals.create_lease.date_message")}</p>
                <div className="col-12">
                    <SidebarInputField
                        id="remark"
                        type="text"
                        label={t("labels.remark")}
                        placeholder={t("placeholders.remark")}
                        value={remark}
                        onClear={() => onRemarkChange("")}
                        onChange={onRemarkChange}
                        labelOnTop
                        isTextArea
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default LeaseGeneralInfoSection;
