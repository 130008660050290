import "./course-detail-page.css";

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import CustomInputField from "../../components/custom-input-field/custom-input-field";
import FileUpload, { UploadedFile } from "../../components/file-upload/file-upload";
import { IoAdd, IoTrashBin } from "react-icons/io5";
import CustomRichTextField from "../../components/custom-rich-text-field/custom-rich-text-field";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAlertStore from "../../stores/alertStore";
import { ICourseModuleItem, createCourseModuleItem, updateCourseModuleItem, uploadCourseModuleItemThumbnail, uploadCourseModuleItemVideo } from "../../services/courseService";
import useCourseStore from "../../stores/courseStore";
import DeleteCourseModuleItemModal from "../../modals/delete-course-module-item-modal/delete-course-module-item-modal";
import { API_URL } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useCourseModuleItems } from "../../hooks/useCourseModuleItems";
import usePageTitle from "../../hooks/usePageTitle";

const CourseDetailPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    let { itemId } = useParams();

    // Params
    const searchParams = new URLSearchParams(location.search);
    const moduleName = decodeURIComponent(searchParams.get('moduleName') || '');
    const moduleId = searchParams.get('moduleId');

    // Set page title
    usePageTitle(t("pages.course.title", { name: moduleName }));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { selectedModuleItem } = useCourseStore();

    // States
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [moduleItem, setModuleItem] = useState<ICourseModuleItem>({
        item_id: 0,
        module_id: Number(moduleId),
        title: "",
        description: "",
        video_path: "",
        thumbnail_path: "",
        published: false,
        created_at: new Date().toString(),
        updated_at: new Date().toString(),
    });
    const [moduleItemOriginal, setModuleItemOriginal] = useState<ICourseModuleItem>({
        item_id: 0,
        module_id: Number(moduleId),
        title: "",
        description: "",
        video_path: "",
        thumbnail_path: "",
        published: false,
        created_at: new Date().toString(),
        updated_at: new Date().toString(),
    });
    const [titleError, setTitleError] = useState('');
    const [textError, setTextError] = useState('');
    const [video, setVideo] = useState<UploadedFile | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [thumbnail, setThumbnail] = useState<File | null>(null);
    const [error, setError] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    // Hooks
    //const { courseModules } = useCourseModules();
    const { mutate } = useCourseModuleItems(Number(moduleId));

    useEffect(() => {
        if (error) {
            showAlert("error", error);
        }
    }, [error, showAlert]);

    useEffect(() => {
        if (itemId && selectedModuleItem && moduleId) {
            setModuleItem(selectedModuleItem);
            setModuleItemOriginal(selectedModuleItem);

            setVideoUrl(`${API_URL}/course-modules/${moduleId}/items/${selectedModuleItem.item_id}/video`);
        }
    }, [itemId, selectedModuleItem, moduleId]);

    const onFileSelected = (newFile: UploadedFile[]) => {
        const url = URL.createObjectURL(newFile[0].file);
        setVideo(newFile[0]);
        setVideoUrl(url);
        generateThumbnail(url);
    };

    const generateThumbnail = (videoUrl: string) => {
        const videoElement = document.createElement('video');
        videoElement.src = videoUrl;

        const onLoadedData = () => {
            videoElement.currentTime = 1;
        };

        const onSeeked = () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const thumbnailFile = new File([blob], "thumbnail.jpg", { type: "image/jpeg" });
                        setThumbnail(thumbnailFile);
                    }
                }, 'image/jpeg', 0.3);
            }
            videoElement.removeEventListener('seeked', onSeeked);
            videoElement.removeEventListener('loadeddata', onLoadedData);
        };

        videoElement.addEventListener('loadeddata', onLoadedData);
        videoElement.addEventListener('seeked', onSeeked);
    };

    const onClickSave = async () => {
        let isValid = true;

        // Check if the title is not empty
        if (!moduleItem.title.trim()) {
            setTitleError(t("invalid_input.title_empty"));
            isValid = false;
        }

        // Check if the description is not empty
        if (!moduleItem.description.trim()) {
            setTextError(t("invalid_input.description_empty"));
            isValid = false;
        }

        // Check if the text is not empty
        if (!videoUrl) {
            showAlert("error", t("pages.course.video_not_uploaded_message"))
            isValid = false;
        }

        if (isValid && moduleId) {
            try {
                setError("");
                setIsUpdating(true);

                if (itemId) {
                    const updatedCourseModuleItem = await updateCourseModuleItem(Number(moduleId), Number(itemId), moduleItem.title, moduleItem.description, moduleItem.published);

                    mutate(
                        (data: ICourseModuleItem[][] | undefined) => {
                            if (!data) return [];
                            return data.map((page) =>
                                page.map((item) =>
                                    item.item_id === updatedCourseModuleItem.item_id ? updatedCourseModuleItem : item
                                )
                            );
                        },
                        false
                    );

                    showAlert("success", t("pages.course.updated_success_message"));
                } else {
                    if (video && thumbnail) {
                        const createdModuleItem = await createCourseModuleItem(Number(moduleId), moduleItem.title, moduleItem.description, moduleItem.published);
                        await uploadCourseModuleItemVideo(Number(moduleId), createdModuleItem.item_id, video.file, setUploadProgress);
                        const updatedModuleItem = await uploadCourseModuleItemThumbnail(Number(moduleId), createdModuleItem.item_id, thumbnail);

                        mutate(
                            (data: ICourseModuleItem[][] | undefined) => {
                                if (!data) return [];
                                return [
                                    [updatedModuleItem, ...data[0]],
                                    ...data.slice(1)
                                ];
                            },
                            false
                        );

                        showAlert("success", t("pages.course.created_success_message"));
                        navigate("/courses");
                    }
                }
            } catch (error) {
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }

    const onDeleteCourseModuleItem = useCallback(() => {
        if (moduleItem) {
            mutate(
                (data: ICourseModuleItem[][] | undefined) => {
                    if (!data) return [];
                    return data.map(page => page.filter(item => item.item_id !== Number(moduleItem.item_id)));
                },
                false
            );

            navigate("/courses");
            showAlert("success", t("pages.course.deleted_success_message"));
        }
    }, [moduleItem, mutate, navigate, showAlert, t])

    const onChangeStatusHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setModuleItem({ ...moduleItem, published: e.target.value === "true" ? true : false });
    }

    const dataHasBeenChanged = useMemo(() => {
        if (!itemId) return true;
        return !_.isEqual(moduleItem, moduleItemOriginal);
    }, [moduleItem, moduleItemOriginal, itemId]);

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-start align-items-lg-center">
                <h4 className="mb-3 mb-lg-0">{moduleName}</h4>
                <div className="d-flex">
                    {itemId && (
                        <CustomButton
                            className="me-3"
                            title={t("buttons.delete")}
                            color="red"
                            icon={IoTrashBin}
                            isLoading={false}
                            disabled={isUpdating}
                            onClick={() => setIsDeleteModalVisible(true)}
                        />
                    )}
                    <CustomButton
                        title={itemId ? t("buttons.save") : t("buttons.create")}
                        icon={IoAdd}
                        isLoading={isUpdating}
                        disabled={!dataHasBeenChanged}
                        onClick={onClickSave}
                    />
                </div>
            </div>

            <h4 className="mt-5">{t("pages.course.general_information_title")}</h4>
            <div className="row mt-1 gy-3">
                <div className="col-12 col-md-6">
                    <div className="white-card h-100">
                        <p>Status *</p>
                        <div className="d-flex mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="true" id="flexRadioDefault1" checked={moduleItem.published} onChange={onChangeStatusHandler} />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    {t("pages.course.state_active")}
                                </label>
                            </div>
                            <div className="form-check ms-3">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="false" id="flexRadioDefault2" checked={!moduleItem.published} onChange={onChangeStatusHandler} />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    {t("pages.course.state_inactive")}
                                </label>
                            </div>
                        </div>
                        <CustomInputField
                            id="title"
                            label={t("labels.title")}
                            maxLength={60}
                            value={moduleItem.title}
                            errorMessage={titleError}
                            onChange={(text: string) => {
                                setModuleItem({ ...moduleItem, title: text });
                                setTitleError("");
                            }}
                            onClear={() => {
                                setModuleItem({ ...moduleItem, title: "" });
                                setTitleError("");
                            }}
                            placeholder={t("pages.course.title_placeholder")}
                            required
                        />
                        {(!videoUrl || videoUrl.length <= 0) && moduleItem.video_path.length <= 0 && (
                            <FileUpload
                                id="course-video"
                                style={{ height: "100%" }}
                                allowedTypes="video/*"
                                onFileSelected={onFileSelected}
                                currentFileCount={video ? 1 : 0}
                            />
                        )}
                        {(videoUrl && videoUrl.length > 0) && (
                            <>
                                <p className="my-0 mb-2">{t("pages.course.selected_video")}</p>
                                <div className="video-responsive">
                                    <video
                                        width="560"
                                        height="315"
                                        controls
                                        controlsList="nodownload">
                                        <source src={videoUrl ?? moduleItem.video_path} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                {!itemId && (
                                    <div className="progress mt-3">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} aria-valuenow={uploadProgress} aria-valuemin={0} aria-valuemax={100}>
                                            {uploadProgress}%
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="white-card">
                        <CustomRichTextField
                            id="text"
                            label={t("labels.description")}
                            placeholder={t("pages.course.description_placeholder")}
                            value={moduleItem.description}
                            errorMessage={textError}
                            required
                            onChange={(text: string) => {
                                setModuleItem({ ...moduleItem, description: text });
                                setTextError("");
                            }}
                        />
                    </div>
                </div>
            </div>
            {isDeleteModalVisible && (
                <DeleteCourseModuleItemModal
                    module_id={Number(moduleId)}
                    item_id={Number(itemId)}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onDeleteCourseModuleItem();
                        }
                        setIsDeleteModalVisible(false);
                    }}
                />
            )}
        </div>
    );
};

export default CourseDetailPage;
