import './payment-details.css';

import React from 'react';
import useNumberAnimation from '../../../../hooks/useNumberAnimation';
import { useTranslation } from 'react-i18next';

interface PaymentInfo {
    rent: number;
    additionalServices: number;
    deposit: number;
}

interface Props {
    payments: PaymentInfo;
}

const PaymentDetails: React.FC<Props> = ({ payments }) => {
    const { t } = useTranslation();

    const animatedRent = useNumberAnimation(payments.rent);
    const animatedAdditionalServices = useNumberAnimation(payments.additionalServices);
    const animatedDebt = useNumberAnimation(payments.deposit);

    return (
        <div className="payment-details">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="title">{t("pages.property_detail.payment_details.title")}</h5>
                <p className="month-badge">{t("pages.property_detail.payment_details.badge")}</p>
            </div>
            <div className="payment-details-card">
                <div className="row g-2 justify-content-center">
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedRent.toLocaleString()} AED <span>{t("pages.property_detail.payment_details.rent_label")}</span></div></div>
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedAdditionalServices.toLocaleString()} AED <span>{t("pages.property_detail.payment_details.additional_services_label")}</span></div></div>
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedDebt.toLocaleString()} AED <span>{t("pages.property_detail.payment_details.deposit_label")}</span></div></div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;
