import './sidebar-input-field.css';

import React, { CSSProperties, useState } from 'react';
import { BsEye, BsEyeSlash, BsXCircle } from 'react-icons/bs';

interface SidebarInputFieldProps {
    className?: string;
    id: string;
    label: string;
    placeholder?: string;
    maxLength?: number;
    value: string;
    onClear?: () => void;
    onChange: (value: string) => void;
    type?: 'text' | 'email' | 'password' | 'number';
    required?: boolean;
    disabled?: boolean;
    inputStyle?: CSSProperties | undefined;
    errorMessage?: string;
    labelOnTop?: boolean;
    isTextArea?: boolean;
    isCurrency?: boolean;
}

const SidebarInputField: React.FC<SidebarInputFieldProps> = ({
    className,
    id,
    label,
    placeholder,
    maxLength,
    value,
    onChange,
    onClear,
    type = 'text',
    required = false,
    disabled = false,
    inputStyle,
    errorMessage,
    labelOnTop = false,
    isTextArea = false,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const labelClasses = labelOnTop ? 'sidebar-select-label-on-top' : 'col-sm-5 col-form-label';
    const inputWrapperClasses = labelOnTop ? 'sidebar-select-wrapper-on-top' : 'col-sm-7';

    return (
        <div className={`sidebar-input-field ${labelOnTop ? '' : 'row'} ${className} align-items-center`}>
            <label htmlFor={id} className={labelClasses}>
                {label}
                {required && (<span className="text-red ms-1">*</span>)}
            </label>
            <div className={inputWrapperClasses}>
                <div className="sidebar-input-wrapper" style={labelOnTop ? { height: 'auto' } : {}}>
                    {isTextArea ? (
                        <textarea
                            style={inputStyle}
                            id={id}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            required={required}
                            disabled={disabled}
                        />
                    ) : (
                        <input
                            style={inputStyle}
                            id={id}
                            type={type}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            required={required}
                            disabled={disabled}
                        />
                    )}

                    <div className="icon-container">
                        {type === 'password' && (
                            <div className="icon" onClick={togglePasswordVisibility}>
                                {showPassword ? <BsEye /> : <BsEyeSlash />}
                            </div>
                        )}
                        {value && onClear && !disabled && (
                            <BsXCircle className="icon" onClick={onClear} />
                        )}
                    </div>
                </div>
                {errorMessage && <div className="ms-2 error-message">{errorMessage}</div>}
            </div>
        </div>
    );
};

export default SidebarInputField;
