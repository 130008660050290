import '../base-modal.css';
import './delete-employee-modal.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEmployee, deleteEmployee } from '../../services/employeeService';
import useAlertStore from '../../stores/alertStore';

interface ModalProps {
    employee: IEmployee;
    onClose: () => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const DeleteEmployeeModal: React.FC<ModalProps> = ({ employee, onClose, mutate }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onHandleDelete = async () => {
        try {
            setIsLoading(true);
            const deleted = await deleteEmployee(employee.employee_id);
            if (deleted) {
                mutate(
                    (data: IEmployee[][]) => {
                        if (!data) return [];
                        return data.map((page) =>
                            page.filter((emp) => emp.employee_id !== employee.employee_id)
                        );
                    },
                    false
                );
                showAlert("success", t("modals.delete_employee.success_message"));
            }
            onClose();
        } catch (error) {
            console.log("Error while deleting employee: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.delete_employee.title")}</h3>
                <p>{t("modals.delete_employee.message", { name: `${employee.first_name} ${employee.last_name}` })}</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-danger add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleDelete} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.delete")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={onClose}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteEmployeeModal;
