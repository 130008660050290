import useSWRInfinite from 'swr/infinite';
import { SortOrder } from '../@types';
import { fetchInvestors, constructInvestorsUrl, IInvestor, InvestorSortBy } from '../services/investorService';

const PAGE_SIZE = 20;

export const useInvestors = (
    statusId: number | null,
    search: string,
    sales_agent?: string,
    owned_units?: string,
    total_purchased_value?: string,
    sortBy?: InvestorSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchInvestors(url);

    const getKey = (pageIndex: number, previousPageData: IInvestor[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructInvestorsUrl(statusId, search, pageIndex + 1, PAGE_SIZE, sales_agent, owned_units, total_purchased_value, sortBy, sortOrder);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IInvestor[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        investors: data ? ([] as IInvestor[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
