// AuditLogsPage.tsx

import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import Switch, { SwitchOptions } from "../../components/switch/switch";
import useAlertStore from "../../stores/alertStore";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { useAuditLogs } from "../../hooks/useAuditLogs";
import AuditLogsTable from "./components/audit-logs-table/audit-logs-table";
import { AuditLogAction, AuditLogSortBy } from "../../services/auditLogService";
import AuditLogFilters from "./components/audit-log-filters/audit-log-filters";
import useAuditLogFilterStore from "../../stores/auditLogFilterStore";
import { SortOrder } from "../../@types";

const AuditLogsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.audit_logs.page_title"));

    const actionOptions: SwitchOptions[] = [
        { label: t("pages.investor.filter.all"), value: "all" },
        ...Object.values(AuditLogAction).map((action) => ({
            label: action.replace(/-/g, ' ')
                .toLowerCase()
                .replace(/^\w/, (c) => c.toUpperCase()),
            value: action,
        }))
    ];

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        start_date,
        end_date,
        time,
        entity_id,
        entity_name,
        employee_id,
        selectedAction,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedAction,
    } = useAuditLogFilterStore();
    const {
        audit_logs,
        isLoading,
        size,
        setSize,
        isReachingEnd,
        error,
    } = useAuditLogs(
        selectedAction ?? undefined,
        start_date,
        end_date,
        time,
        entity_name,
        entity_id,
        employee_id,
        sortColumn ?? undefined,
        sortDirection ?? undefined
    );

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleSortChange = (column: AuditLogSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    const handleFilterClick = (value: string) => {
        setSelectedAction(value.includes("all") ? null : value);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.audit_logs.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.audit_logs.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
            </div>

            {/* FILTER BUTTONS  */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={actionOptions} value={selectedAction ? selectedAction.toString() : "all"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" options={actionOptions} value={selectedAction ? selectedAction.toString() : ""} onChange={handleFilterClick} />

            {/* EMPLOYEES OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <AuditLogFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={audit_logs.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <AuditLogsTable
                                auditLogs={audit_logs}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                                onSortChange={handleSortChange}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}
        </div>
    );
};

export default AuditLogsPage;
