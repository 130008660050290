import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITenant } from '../../../../services/tenantService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCallOutline, IoMailOutline } from 'react-icons/io5';
import { getFormattedDate } from '../../../../utils/helpers';
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import styles from './tenant-details.module.css';

interface TenantDetailsProps {
    tenant: ITenant;
    start_date: string;
    end_date: string;
}

const TenantDetails: React.FC<TenantDetailsProps> = ({ tenant, start_date, end_date }) => {
    const { t } = useTranslation();

    const onClickTenant = (tenant_id: number) => {
        const url = `${window.location.origin}/tenants/${tenant_id}`;
        window.open(url, '_blank');
    };

    return (
        <div className={styles.leaseTenantDetails}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className={styles.title}>{t("pages.lease_detail.tenant_details.title")}</h5>
            </div>

            <div className={styles.leaseTenantDetailsCard} onClick={() => onClickTenant(tenant.tenant_id)}>
                <div className={styles.tenant}>
                    <img className={styles.avatar} src={DefaultAvatar} alt="Tenant Avatar" />
                    <div className={styles.tenantInfo}>
                        <div className={styles.tenantHeader}>
                            <h4 className={styles.name}>
                                {tenant.first_name} {tenant.last_name}
                            </h4>
                            {tenant.phone_number && (
                                <OverlayTrigger overlay={<Tooltip>{tenant.phone_number}</Tooltip>}>
                                    <a href={`tel:${tenant.phone_number}`} className={styles.contactLink}>
                                        <IoCallOutline className={styles.contactIcon} />
                                    </a>
                                </OverlayTrigger>
                            )}
                            <OverlayTrigger overlay={<Tooltip>{tenant.email}</Tooltip>}>
                                <a href={`mailto:${tenant.email}`} className={styles.contactLink}>
                                    <IoMailOutline className={styles.contactIcon} />
                                </a>
                            </OverlayTrigger>
                        </div>
                        <h4 className={styles.info}>
                            {getFormattedDate(start_date)} - {getFormattedDate(end_date)}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TenantDetails;
