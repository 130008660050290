import React from 'react';
import { IoNewspaperOutline, IoTrashOutline } from 'react-icons/io5';
import styles from './news-list.module.css';
import { INews } from '../../../services/newsService';
import { useTranslation } from 'react-i18next';

interface NewsListProps {
    news: INews[];
    onClickNews: (news: INews) => void;
    onDeleteNews: (news_id: number) => void;
}

const NewsList: React.FC<NewsListProps> = ({ news, onClickNews, onDeleteNews }) => {
    const { t } = useTranslation();

    if (!news.length) return <div className="white-card"><p className="my-0 text-center">{t("pages.news.no_news_available")}</p></div>;

    const latestNews = news.reduce((latest, current) => {
        return new Date(current.published_at) > new Date(latest.published_at) ? current : latest;
    }, news[0]);

    return (
        <div className={styles.newsList}>
            {news.map((newsItem, index) => {
                const isLatest = newsItem === latestNews;
                return (
                    <div
                        key={index}
                        className={`${styles.newsItem} white-card ${isLatest ? styles.highlighted : ''}`}
                        onClick={() => onClickNews(newsItem)}
                    >
                        <div className={styles.newsHeader}>
                            <IoNewspaperOutline size={24} />
                            <h2 className="my-0">{newsItem.title}</h2>
                            <IoTrashOutline
                                className={styles.deleteIcon}
                                size={20}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteNews(newsItem.news_id);
                                }}
                            />
                        </div>
                        <div className={styles.newsContent} dangerouslySetInnerHTML={{ __html: newsItem.content }} />
                        <div className={styles.newsFooter}>
                            <p
                                className="mt-2 mb-0"
                                dangerouslySetInnerHTML={{
                                    __html: t("pages.news.published_on", {
                                        date: new Date(newsItem.published_at).toLocaleDateString(),
                                        time: new Date(newsItem.published_at).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })
                                    })
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default NewsList;
