import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useSearchStore from "../../stores/searchStore";
import useAlertStore from "../../stores/alertStore";
import useDebounce from "../../hooks/useDebounce";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { UnitSortBy } from "../../services/unitService";
import UnitFilters from "./components/available-unit-filters/available-unit-filters";
import { SortOrder } from "../../@types";
import AvailableUnitTable from "./components/available-unit-table/available-unit-table";
import { useAvailableUnits } from "../../hooks/useAvailableUnits";
import useAvailableUnitFilterStore from "../../stores/availableUnitFilterStore";

const AvailableUnitsPage = () => {
    let { propertyId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.property_detail.available_units.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        bedrooms,
        bathrooms,
        minimumAnnualRent,
        sortColumn,
        sortDirection,
        setSort,
    } = useAvailableUnitFilterStore();

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const {
        availableUnits,
        isLoading,
        size,
        setSize,
        isReachingEnd,
        error,
    } = useAvailableUnits(
        Number(propertyId),
        undefined,
        debouncedKeyword,
        bedrooms,
        bathrooms,
        minimumAnnualRent,
        sortColumn || undefined,
        sortDirection || undefined
    );

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const onClickUnit = (unit_id: number) => {
        navigate(`/units/${unit_id}`);
    };

    const handleSortChange = (column: UnitSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.property_detail.available_units.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.property_detail.available_units.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
            </div>

            {/* UNITS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <UnitFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={availableUnits.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <AvailableUnitTable
                                units={availableUnits}
                                onClickUnit={onClickUnit}
                                onSortChange={handleSortChange}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}
        </div>
    );
};

export default AvailableUnitsPage;
