// ConstructionUpdatesPage.tsx

import { useEffect } from "react";
import CustomButton from "../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import Switch, { SwitchOptions } from "../components/switch/switch";
import ConstructionUpdateCard from "../components/construction-update-card/construction-update-card";
import { useNavigate } from "react-router-dom";
import useConstructionUpdateStore from "../stores/constructionUpdateStore";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import useAlertStore from "../stores/alertStore";
import { useConstructionUpdates } from "../hooks/useConstructionUpdates";
import usePageTitle from "../hooks/usePageTitle";

const switchOptions: SwitchOptions[] = [
    { label: "The Vybe", value: "3" },
    { label: "Haven Gardens", value: "4" },
];

const ConstructionUpdatesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.construction_updates.page_title"));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        selectedPropertyId,
        setSelectedPropertyId
    } = useConstructionUpdateStore();

    // Hooks
    const {
        constructionUpdates,
        isLoading,
        size,
        setSize,
        isReachingEnd,
        error,
    } = useConstructionUpdates(selectedPropertyId);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        setSelectedPropertyId(Number(value));
        setSize(1);
    }

    const onClickNewUpdate = () => {
        const switchOption = switchOptions.find(option => Number(option.value) === selectedPropertyId);
        if (switchOption) {
            navigate(`/construction-update?propertyName=${encodeURIComponent(switchOption.label)}&propertyId=${selectedPropertyId}`);
        }
    }

    const onClickUpdate = (updateId: number) => {
        const switchOption = switchOptions.find(option => Number(option.value) === selectedPropertyId);
        if (switchOption) {
            navigate(`/construction-update/${updateId}?propertyName=${encodeURIComponent(switchOption.label)}&propertyId=${selectedPropertyId}`);
        }
    }

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-center justify-content-sm-between align-items-center">
                <Switch
                    options={switchOptions}
                    value={selectedPropertyId.toString()}
                    onChange={handleFilterClick}
                />
                <CustomButton
                    className="d-none d-sm-inline"
                    title={t("pages.construction_updates.new_update_button")}
                    icon={BsPlus}
                    isLoading={false}
                    onClick={onClickNewUpdate}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="my-0">{t("pages.construction_updates.title", { number: constructionUpdates.length })}</h4>
                <p className="d-inline d-sm-none my-0" onClick={() => navigate("/construction-update")}>{t("pages.construction_updates.new_update_button")}</p>
            </div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <div className="spinner-border text-primary" role="status" />
                </div>
            ) : (
                constructionUpdates.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <p>{t("pages.construction_updates.empty_message")}</p>
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={constructionUpdates.length}
                        next={loadMore}
                        hasMore={!isReachingEnd}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <div className="row mt-1 mb-2 gy-4">
                            {constructionUpdates.map((update, index) => (
                                <div className="col-12 col-sm-6 col-xl-4" key={index}>
                                    <ConstructionUpdateCard
                                        imageSrc={update.picture_path}
                                        title={update.title}
                                        description={update.short_description}
                                        published={update.published}
                                        created_at={update.created_at}
                                        onClick={() => onClickUpdate(update.update_id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                )
            )}
        </div>
    );
};

export default ConstructionUpdatesPage;
