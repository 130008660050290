import './available-unit-table.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUnit, UnitSortBy } from '../../../../services/unitService';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { SortOrder } from '../../../../@types';
import { formatMoney } from '../../../../utils/helpers';

interface AvailableUnitTableProps {
    units: IUnit[];
    onClickUnit: (unit_id: number) => void;
    onSortChange: (sortColumn: UnitSortBy, sortDirection: SortOrder) => void;
    selectedSortColumn: UnitSortBy | null;
    selectedSortDirection: SortOrder | null;
}

const AvailableUnitTable: React.FC<AvailableUnitTableProps> = ({
    units,
    onClickUnit,
    onSortChange,
    selectedSortColumn,
    selectedSortDirection
}) => {
    const { t } = useTranslation();

    const handleSort = (column: UnitSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    return (
        <div className="available-unit-table-responsive">
            <table className="available-unit-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort(UnitSortBy.UNIT_NUMBER)} style={{ cursor: 'pointer' }}>
                            {t("unit_table.name_header")}
                            {selectedSortColumn === UnitSortBy.UNIT_NUMBER ? (
                                selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                            ) : <FaSort />}
                        </th>
                        <th onClick={() => handleSort(UnitSortBy.BEDROOM)} style={{ cursor: 'pointer' }}>
                            {t("unit_table.bedroom_header")}
                            {selectedSortColumn === UnitSortBy.BEDROOM ? (
                                selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                            ) : <FaSort />}
                        </th>
                        <th onClick={() => handleSort(UnitSortBy.BATHROOM)} style={{ cursor: 'pointer' }}>
                            {t("unit_table.bathroom_header")}
                            {selectedSortColumn === UnitSortBy.BATHROOM ? (
                                selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                            ) : <FaSort />}
                        </th>
                        <th onClick={() => handleSort(UnitSortBy.SQUARE_FEET)} style={{ cursor: 'pointer' }}>
                            {t("unit_table.size_header")} (sq ft)
                            {selectedSortColumn === UnitSortBy.SQUARE_FEET ? (
                                selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                            ) : <FaSort />}
                        </th>
                        <th onClick={() => handleSort(UnitSortBy.MINIMUM_ANNUAL_RENT)} style={{ cursor: 'pointer' }}>
                            {t("unit_table.minimum_annual_rent_header")}
                            {selectedSortColumn === UnitSortBy.MINIMUM_ANNUAL_RENT ? (
                                selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                            ) : <FaSort />}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {units.map(unit => (
                        <tr key={unit.unit_id} className="aktiv" onClick={() => onClickUnit(unit.unit_id)}>
                            <td>{unit.unit_id}</td>
                            <td>{unit.bedroom}</td>
                            <td>{unit.bathroom}</td>
                            <td>
                                <span className="square-feet-badge">
                                    {unit.square_feet}
                                </span>
                            </td>
                            <td>
                                <span className="total-rent-amount-badge">
                                    {unit.minimum_annual_rent ? formatMoney(unit.minimum_annual_rent) : "-"}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AvailableUnitTable;
