// ProfilePage.tsx
import "./profile-page.css";

import useEmployeeStore from "../../stores/employeeStore";
//import useAlertStore from "../../stores/alertStore";
import { useTranslation } from "react-i18next";
import CustomInputField from "../../components/custom-input-field/custom-input-field";
import CustomPhoneInput from "../../components/custom-phone-input/custom-phone-input";
import DefaultAvatar from "../../assets/images/default-avatar.webp";
import { SPACES_ENDPOINT } from "../../utils/constants";
import { IoPencil } from 'react-icons/io5';
import usePageTitle from "../../hooks/usePageTitle";

const ProfilePage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.profile.page_title"));

    // Stores
    //const showAlert = useAlertStore(state => state.showAlert);
    const { employee } = useEmployeeStore();

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            // const file = event.target.files[0];
            // Handle file upload logic here
            // For example, you can call an API to upload the file
            // and then update the employee's avatar URL

            // Assuming the upload was successful, update the employee store
            // setEmployee({ ...employee, avatar: newAvatarUrl });
        }
    };

    return (
        <div className="no-select">
            {employee && (
                <>
                    {/* ADD TABS */}
                    <div className="row gy-2">
                        <div className="col-12 col-md-8">
                            <div className="white-card">
                                <div className="row gy-2">
                                    <div className="col-12 col-md-3 text-center">
                                        <div className="round-image-container">
                                            <img
                                                src={employee.avatar ? `${SPACES_ENDPOINT}${employee.avatar}` : DefaultAvatar}
                                                className="round-image"
                                                alt="Avatar"
                                            />
                                            <label htmlFor="avatar-upload" className="pencil-icon">
                                                <IoPencil />
                                            </label>
                                            <input
                                                type="file"
                                                id="avatar-upload"
                                                style={{ display: "none" }}
                                                accept="image/*"
                                                onChange={handleAvatarChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="row gy-2">
                                            <div className="col-12 col-md-6">
                                                <CustomInputField
                                                    id="firstname"
                                                    label={t("labels.firstname")}
                                                    maxLength={30}
                                                    value={employee.first_name}
                                                    errorMessage={""}
                                                    onChange={(text: string) => {
                                                        //setInvestor({ ...investor, first_name: text });
                                                        //setFirstnameError("");
                                                    }}
                                                    onClear={() => {
                                                        //setInvestor({ ...investor, first_name: "" });
                                                        //setFirstnameError("");
                                                    }}
                                                    placeholder="Max"
                                                    required
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <CustomInputField
                                                    id="lastname"
                                                    label={t("labels.lastname")}
                                                    maxLength={30}
                                                    value={employee.last_name}
                                                    errorMessage={""}
                                                    onChange={(text: string) => {
                                                        //setInvestor({ ...investor, last_name: text });
                                                        //setLastnameError("");
                                                    }}
                                                    onClear={() => {
                                                        //setInvestor({ ...investor, last_name: "" });
                                                        //setLastnameError("");
                                                    }}
                                                    placeholder="Mustermann"
                                                    required
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <CustomInputField
                                                    id="email"
                                                    type="email"
                                                    label={t("labels.email")}
                                                    maxLength={140}
                                                    value={employee.email}
                                                    errorMessage={""}
                                                    onChange={(text: string) => {
                                                        //setInvestor({ ...investor, email: text });
                                                        //setEmailError("");
                                                    }}
                                                    onClear={() => {
                                                        //setInvestor({ ...investor, email: "" });
                                                        //setEmailError("");
                                                    }}
                                                    placeholder="name@danielgarofoli.com"
                                                    required
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <CustomPhoneInput
                                                    id="phone-number"
                                                    defaultCountry="DE"
                                                    value={employee.phone_number ?? undefined}
                                                    onChange={(number: string) => {
                                                        //setInvestor({ ...investor, phone_number: number });
                                                    }}
                                                    onClear={() => {
                                                        //setInvestor({ ...investor, phone_number: "" });
                                                    }}
                                                    labelOnTop
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProfilePage;
