import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ICourseModuleItem } from "../services/courseService";

const STORAGE_NAME = "course-storage";

interface CourseState {
  selectedModuleItem: ICourseModuleItem | null;
  selectedModuleId: number;
  setSelectedModuleId: (moduleId: number) => void;
  setSelectedModuleItem: (moduleItem: ICourseModuleItem | null) => void;
  reset: () => void;
}

const useCourseStore = create<CourseState>()(
  devtools(
    persist(
      (set) => ({
        selectedModuleItem: null,
        selectedModuleId: 1,
        setSelectedModuleItem: (moduleItem: ICourseModuleItem | null) =>
          set({ selectedModuleItem: moduleItem }),
        setSelectedModuleId: (moduleId: number) =>
          set({ selectedModuleId: moduleId }),
        reset: () => {
          set({
            selectedModuleItem: null,
          });
        },
      }),
      {
        name: STORAGE_NAME,
      }
    )
  )
);

export default useCourseStore;
