import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IUnit } from "../services/unitService";
import { IUnitOwner } from "../services/unitOwnerService";
import { IParkingSpace } from "../services/parkingSpaceService";

interface UnitDetailState {
  unit: IUnit | null;
  isLoading: boolean;
  isDeleting: boolean;
  setUnit: (unit: IUnit) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsDeleting: (isDeleting: boolean) => void;
  addUnitOwner: (unitOwner: IUnitOwner) => void;
  addParkingSpace: (parkingSpace: IParkingSpace) => void;
  removeParkingSpace: (parking_id: number) => void;
  reset: () => void;
}

const useUnitDetailStore = create<UnitDetailState>()(
  devtools(
    persist(
      (set, get) => ({
        unit: null,
        isLoading: true,
        isDeleting: false,
        setUnit: (unit: IUnit) => set({ unit }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsDeleting: (isDeleting: boolean) => set({ isDeleting }),
        addUnitOwner: (unitOwner: IUnitOwner) => {
          const { unit } = get();
          if (unit) {
            set({
              unit: {
                ...unit,
                owners: [...unit.owners, unitOwner],
              },
            });
          }
        },
        addParkingSpace: (parkingSpace: IParkingSpace) => {
          const { unit } = get();
          if (unit) {
            set({
              unit: {
                ...unit,
                parking_spaces: [...unit.parking_spaces, parkingSpace],
              },
            });
          }
        },
        removeParkingSpace: (parking_id: number) => {
          const { unit } = get();
          if (unit) {
            set({
              unit: {
                ...unit,
                parking_spaces: unit.parking_spaces.filter(
                  (space) => space.parking_id !== parking_id
                ),
              },
            });
          }
        },
        reset: () => {
          set({ unit: null, isLoading: true });
        },
      }),
      {
        name: "unit-detail-storage",
      }
    )
  )
);

export default useUnitDetailStore;
