import { DocumentType } from "../services/documentService";

export interface IUploadFile {
  file: File;
  type: DocumentType | null;
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum ErrorCodesEnum {
  PROPERTY_NOT_FOUND = "PROPERTY_NOT_FOUND",
  UNIT_NOT_FOUND = "UNIT_NOT_FOUND",
  PARKING_CODE_EXISTS = "PARKING_CODE_EXISTS",
  SERVER_ERROR = "SERVER_ERROR",
}
