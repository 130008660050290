import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose, IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import SidebarSelectField from '../components/sidebar-select-field/sidebar-select-field';
import { Value } from 'react-phone-number-input';
import CustomPhoneInput from '../../../components/custom-phone-input/custom-phone-input';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import { fetchRoles } from '../../../services/roleService';
import { IEmployee, createEmployee, updateEmployee } from '../../../services/employeeService';

interface EmployeeSidebarModalProps {
    isVisible: boolean;
    selectedEmployee?: IEmployee;
    onClose: () => void;
    onClickDeleteEmployee: (employee_id: number) => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const EmployeeSidebarModal: React.FC<EmployeeSidebarModalProps> = ({ isVisible, selectedEmployee, onClose, onClickDeleteEmployee, mutate }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<Value | undefined>(undefined);
    const [role, setRole] = useState<string>("");
    const [roles, setRoles] = useState<{ label: string; value: string; }[]>([]);

    // Error States
    const [firstnameError, setFirstnameError] = useState<string>("");
    const [lastnameError, setLastnamError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedEmployee && roles) {
            setFirstname(selectedEmployee.first_name);
            setLastname(selectedEmployee.last_name);
            setEmail(selectedEmployee.email);
            setPhoneNumber(selectedEmployee.phone_number ?? undefined);
            setRole(selectedEmployee.role.role_id.toString());
        }
    }, [selectedEmployee, roles]);

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setFirstname("");
            setLastname("");
            setEmail("");
            setRole("");
            setPhoneNumber("");
            setFirstnameError("");
            setLastnamError("");
            setEmailError("");
            setPhoneNumberError("");
            setError("");
        } else {
            const loadEmployeeRoles = async () => {
                try {
                    const fetchedRoles = await fetchRoles();
                    setRoles(fetchedRoles.map(agent => {
                        return { label: `${agent.role_name}`, value: agent.role_id.toString() }
                    }));
                    setRole(roles.length > 0 ? roles[0].value : "");
                } catch (error) {
                    // @ts-ignore
                    setError(error.message);
                }
            }
            loadEmployeeRoles();
        }
    }, [isVisible]);

    // Create Employee
    const onHandleCreateEmployee = useCallback(async () => {
        let isValid = true;

        // Check if the firstname is not empty
        if (!firstname.trim()) {
            setFirstnameError(t("invalid_input.firstname_empty"));
            isValid = false;
        }

        // Check if the lastname is not empty
        if (!lastname.trim()) {
            setLastnamError(t("invalid_input.lastname_empty"));
            isValid = false;
        }

        // Basic email validation
        if (!email.trim()) {
            setEmailError(t("invalid_input.email_empty"));
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
        }

        // Check if the phone number is not empty
        if (!phoneNumber?.trim()) {
            setPhoneNumberError(t("invalid_input.phone_number_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setError("");
                setIsLoading(true);
                const createdEmployee = await createEmployee(firstname, lastname, email, phoneNumber ?? "", Number(role));

                mutate(
                    (data: IEmployee[][]) => {
                        if (!data) return [];
                        return [
                            [createdEmployee, ...data[0]],
                            ...data.slice(1)
                        ];
                    },
                    false
                );

                onClose();
                showAlert("success", t("modals.create_employee.create_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [firstname, lastname, email, phoneNumber, role, mutate, onClose, showAlert, t]);

    // Update Employee
    const onHandleUpdateEmployee = useCallback(async () => {
        let isValid = true;

        // Check if the firstname is not empty
        if (!firstname.trim()) {
            setFirstnameError(t("invalid_input.firstname_empty"));
            isValid = false;
        }

        // Check if the lastname is not empty
        if (!lastname.trim()) {
            setLastnamError(t("invalid_input.lastname_empty"));
            isValid = false;
        }

        // Basic email validation
        if (!email.trim()) {
            setEmailError(t("invalid_input.email_empty"));
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
        }

        // Check if the phone number is not empty
        if (!phoneNumber?.trim()) {
            setPhoneNumberError(t("invalid_input.phone_number_empty"));
            isValid = false;
        }

        if (isValid && selectedEmployee) {
            try {
                setError("");
                setIsLoading(true);
                const updatedEmployee = await updateEmployee(selectedEmployee.employee_id, firstname, lastname, email, phoneNumber ?? "", Number(role));

                mutate(
                    (data: IEmployee[][]) => {
                        if (!data) return [];
                        return data.map((page) =>
                            page.map((employee) =>
                                employee.employee_id === updatedEmployee.employee_id ? updatedEmployee : employee
                            )
                        );
                    },
                    false
                );

                onClose();
                showAlert("success", t("modals.update_employee.update_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [selectedEmployee, firstname, lastname, email, phoneNumber, role, mutate, onClose, showAlert, t]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.create_employee.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <SidebarInputField
                            id="employee-firstname"
                            type="text"
                            label={t("labels.firstname")}
                            placeholder="Max"
                            value={firstname}
                            errorMessage={firstnameError}
                            onClear={() => setFirstname("")}
                            onChange={(value: string) => {
                                setFirstname(value);
                                setFirstnameError("");
                            }}
                            required
                        />
                        <SidebarInputField
                            id="employee-lastname"
                            type="text"
                            label={t("labels.lastname")}
                            placeholder="Mustermann"
                            value={lastname}
                            errorMessage={lastnameError}
                            onClear={() => setLastname("")}
                            onChange={(value: string) => {
                                setLastname(value);
                                setLastnamError("");
                            }}
                            required
                        />
                        <SidebarInputField
                            id="employee-email"
                            type="email"
                            label={t("labels.email")}
                            placeholder="social@danielgarofoli.com"
                            value={email}
                            errorMessage={emailError}
                            onClear={() => setEmail("")}
                            onChange={(value: string) => {
                                setEmail(value);
                                setEmailError("");
                            }}
                            required
                        />
                        <CustomPhoneInput
                            id="employee-phone-number"
                            defaultCountry='AE'
                            value={phoneNumber}
                            errorMessage={phoneNumberError}
                            onChange={setPhoneNumber}
                            onClear={() => {
                                setPhoneNumber(undefined);
                                setPhoneNumberError("");
                            }}
                            required
                        />
                        <div className="sidebar-modal-divider mb-4" />
                        <SidebarSelectField
                            id="employee-role"
                            label={t("labels.role")}
                            value={role}
                            onChange={setRole}
                            options={roles}
                            required
                        />
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        {selectedEmployee ? (
                            <div className="d-flex">
                                <CustomButton
                                    className="w-100"
                                    title={t("modals.update_employee.update_button")}
                                    icon={IoPencilSharp}
                                    onClick={onHandleUpdateEmployee}
                                    isLoading={isLoading}
                                />
                                <CustomButton
                                    className="w-100 ms-4"
                                    title={t("modals.update_employee.delete_button")}
                                    color="red"
                                    icon={IoTrashBinSharp}
                                    onClick={() => onClickDeleteEmployee(selectedEmployee.employee_id)}
                                    isLoading={isLoading}
                                />
                            </div>
                        ) : (
                            <CustomButton
                                className="w-100"
                                title={t("modals.create_employee.create_button")}
                                isLoading={isLoading}
                                onClick={onHandleCreateEmployee}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeSidebarModal;
