import { create } from "zustand";
import { UnitSortBy } from "../services/unitService";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";

interface UnitFilterState {
  bedrooms: string;
  bathrooms: string;
  minimumAnnualRent: string;
  soldBy: string;
  status: string;
  purpose: string;
  pmContractSigned: boolean;
  sortColumn: UnitSortBy | null;
  sortDirection: SortOrder | null;
  selectedProperty: number | null;
  isExpanded: boolean;
  setFilters: (
    bedrooms: string,
    bathrooms: string,
    minimumAnnualRent: string,
    soldBy: string,
    status: string,
    purpose: string,
    pmContractSigned: boolean
  ) => void;
  setSort: (column: UnitSortBy, direction: SortOrder) => void;
  setSelectedProperty: (propertyId: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useUnitFilterStore = create<UnitFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        bedrooms: "",
        bathrooms: "",
        minimumAnnualRent: "",
        soldBy: "",
        status: "",
        purpose: "",
        pmContractSigned: true,
        sortColumn: null,
        sortDirection: null,
        selectedProperty: null,
        isExpanded: false,
        setFilters: (
          bedrooms,
          bathrooms,
          minimumAnnualRent,
          soldBy,
          status,
          purpose,
          pmContractSigned
        ) =>
          set({
            bedrooms,
            bathrooms,
            minimumAnnualRent,
            soldBy,
            status,
            purpose,
            pmContractSigned,
          }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "unit-filter-storage",
      }
    )
  )
);

export default useUnitFilterStore;
