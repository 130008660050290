import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMailOutline, IoTrashOutline } from 'react-icons/io5'; // Import the trash icon
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import { ILeaseOccupant, deleteLeaseOccupant } from '../../../../services/leaseService';
import AddOccupantModal from '../../../../modals/add-occupant-modal/add-occupant-modal';
import DeleteConfirmationModal from '../../../../modals/delete-confirmation-modal/delete-confirmation-modal';
import useEmployeeStore from '../../../../stores/employeeStore';
import useEditPermission from '../../../../hooks/useEditPermission';
import styles from './occupants-details.module.css';
import { EmployeeRole } from '../../../../services/employeeService';

interface OccupantDetailsProps {
    lease_id: number;
    occupants: ILeaseOccupant[];
    onOccupantCreated: (occupant: ILeaseOccupant) => void;
    onOccupantDeleted: (occupant_id: number) => void;
}

const OccupantDetails: React.FC<OccupantDetailsProps> = ({ lease_id, occupants, onOccupantCreated, onOccupantDeleted }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddOccupantModalVisible, setIsAddOccupantModalVisible] = useState<boolean>(false);
    const [isDeleteOccupantModalVisible, setIsDeleteOccupantModalVisible] = useState<number | null>(null);

    const onDeleteOccupant = useCallback(async () => {
        if (isDeleteOccupantModalVisible) {
            try {
                setIsLoading(true);
                const isDeleted = await deleteLeaseOccupant(lease_id, isDeleteOccupantModalVisible);
                if (isDeleted) {
                    onOccupantDeleted(isDeleteOccupantModalVisible);
                }
            } catch (error) {
                console.log(`Error while deleting occupant (${isDeleteOccupantModalVisible}):`, error);
            } finally {
                setIsLoading(false);
                setIsDeleteOccupantModalVisible(null);
            }
        }
    }, [isDeleteOccupantModalVisible, lease_id]);

    return (
        <>
            <div className={styles.occupantDetails}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className={styles.title}>({occupants.length}) {t("pages.lease_detail.occupant_details.title")}</h5>
                    {hasEditPermission && (
                        <button className={styles.btnCreateOccupant} onClick={() => setIsAddOccupantModalVisible(true)}>
                            {t("buttons.add")}
                        </button>
                    )}
                </div>

                {/* Iterate through occupants */}
                {occupants.length > 0 ? (
                    occupants.map((occupant) => (
                        <div className={styles.occupantDetailsCard} key={occupant.occupant_id}>
                            <div className={styles.occupant}>
                                <img className={styles.avatar} src={DefaultAvatar} alt="Occupant Avatar" />
                                <div className={styles.occupantInfo}>
                                    <div className={styles.occupantHeader}>
                                        <h4 className={styles.name}>
                                            {occupant.name}
                                        </h4>
                                        <OverlayTrigger overlay={<Tooltip>{occupant.email}</Tooltip>}>
                                            <a href={`mailto:${occupant.email}`} className={styles.contactLink}>
                                                <IoMailOutline className={styles.contactIcon} />
                                            </a>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                {/* Add delete button aligned to the right */}
                                {hasEditPermission && (
                                    <button
                                        className={styles.deleteButton}
                                        onClick={() => setIsDeleteOccupantModalVisible(occupant.occupant_id)}
                                    >
                                        <IoTrashOutline className={styles.deleteIcon} />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.occupantDetailsCard}>
                        <p className={styles.emptyText}>{t("pages.lease_detail.occupant_details.no_occupants")}</p>
                    </div>
                )}
            </div>

            {/* ADD OCCUPANTS MODAL */}
            {
                isAddOccupantModalVisible && hasEditPermission && (
                    <AddOccupantModal
                        lease_id={lease_id}
                        onClose={() => {
                            setIsAddOccupantModalVisible(false);
                        }}
                        onOccupantCreated={(occupant) => {
                            onOccupantCreated(occupant);
                            setIsAddOccupantModalVisible(false);
                        }}
                    />
                )
            }

            {/* DELETE OCCUPANT MODAL */}
            {
                isDeleteOccupantModalVisible && hasEditPermission && (
                    <DeleteConfirmationModal
                        title={t("pages.lease_detail.occupant_details.delete_modal.title")}
                        message={t("pages.lease_detail.occupant_details.delete_modal.message")}
                        isLoading={isLoading}
                        onClose={() => setIsDeleteOccupantModalVisible(null)}
                        onDelete={onDeleteOccupant}
                    />
                )
            }
        </>
    );
};

export default OccupantDetails;
