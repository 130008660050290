import React, { useCallback, useState } from "react";
import { IParkingSpace, createParkingSpace, deleteParkingSpace } from "../../../../services/parkingSpaceService";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import { FiPlus } from "react-icons/fi";
import useEditPermission from "../../../../hooks/useEditPermission";
import useEmployeeStore from "../../../../stores/employeeStore";
import DeleteConfirmationModal from "../../../../modals/delete-confirmation-modal/delete-confirmation-modal";
import { useTranslation } from "react-i18next";
import useAlertStore from "../../../../stores/alertStore";
import { FaSpinner } from "react-icons/fa";

import "./parking-space-section.css";
import { ErrorCodesEnum } from "../../../../@types";

interface ParkingSpaceSectionProps {
    property_id: number;
    unit_id: number;
    parkingSpaces: IParkingSpace[];
    onAddParkingSpace: (parkingSpace: IParkingSpace) => void;
    onDeleteParkingSpace: (parkingId: number) => void;
}

const ParkingSpaceSection: React.FC<ParkingSpaceSectionProps> = ({
    property_id,
    unit_id,
    parkingSpaces,
    onAddParkingSpace,
    onDeleteParkingSpace,
}) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId);

    // States
    const [newParkingCode, setNewParkingCode] = useState<string>("");
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<IParkingSpace | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCreateParkingSpace = useCallback(async () => {
        if (newParkingCode.trim()) {
            try {
                setIsLoading(true);
                const createdParkingSpace = await createParkingSpace(property_id, unit_id, newParkingCode.trim());
                onAddParkingSpace(createdParkingSpace);
                setNewParkingCode("");
            } catch (error: any) {
                console.log(`Error while creating parking space with code ${newParkingCode.trim()}:`, error);

                if (error.message === ErrorCodesEnum.PROPERTY_NOT_FOUND) {
                    showAlert("error", t('error_messages.property_not_found'));
                } else if (error.message === ErrorCodesEnum.UNIT_NOT_FOUND) {
                    showAlert("error", t('error_messages.unit_not_found'));
                } else if (error.message === ErrorCodesEnum.PARKING_CODE_EXISTS) {
                    showAlert("error", t('error_messages.parking_code_exists'));
                } else {
                    showAlert("error", t('error_messages.generic_error'));
                }
            } finally {
                setIsLoading(false);
            }
        }
    }, [newParkingCode, property_id, unit_id, t, showAlert, setIsLoading, onAddParkingSpace, setNewParkingCode]);

    const onHandleDeleteParkingSpace = useCallback(async () => {
        const parkingSpace = isDeleteModalVisible;
        if (parkingSpace) {
            try {
                await deleteParkingSpace(parkingSpace.parking_id);
                onDeleteParkingSpace(parkingSpace.parking_id);
                setIsDeleteModalVisible(null);
            } catch (error) {
                console.log(`Error while deleting parking space ${parkingSpace.parking_code}:`, error);
                // @ts-ignore
                showAlert("error", error.message);
            }
        }
    }, [isDeleteModalVisible, setIsDeleteModalVisible, showAlert, onDeleteParkingSpace]);

    return (
        <>
            <ExpandableSection
                className="parking-space-section"
                title="Parking Spaces"
                isHeadlineLarge
                isDefaultExpanded
            >
                <div className="parking-space-chips">
                    {parkingSpaces.map((space: IParkingSpace) => (
                        <div key={space.parking_id} className="parking-chip">
                            {space.parking_code}
                            <span
                                className="delete-icon"
                                onClick={() => setIsDeleteModalVisible(space)}
                            >
                                &times;
                            </span>
                        </div>
                    ))}
                    <div className={`add-chip ${isLoading ? 'disabled' : ''}`}>
                        {isLoading ? (
                            <FaSpinner className="loading-spinner" size={16} />
                        ) : (
                            <FiPlus size={16} />
                        )}
                        <input
                            type="text"
                            placeholder="Add Parking Space"
                            value={newParkingCode}
                            onChange={(e) => setNewParkingCode(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && !isLoading && handleCreateParkingSpace()}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </ExpandableSection>

            {/* DELETE CONFIRMATION MODAL */}
            {isDeleteModalVisible && hasEditPermission && (
                <DeleteConfirmationModal
                    title={t("pages.unit_detail.delete_parking_space_modal.title", { parking_code: isDeleteModalVisible.parking_code })}
                    message={t("pages.unit_detail.delete_parking_space_modal.message", { parking_code: isDeleteModalVisible.parking_code })}
                    onClose={() => setIsDeleteModalVisible(null)}
                    onDelete={onHandleDeleteParkingSpace}
                />
            )}
        </>
    );
};

export default ParkingSpaceSection;
