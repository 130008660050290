import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IInvestor } from "../services/investorService";

interface InvestorDetailState {
  investor: IInvestor | null;
  isLoading: boolean;
  isDeleting: boolean;
  setInvestor: (investor: IInvestor) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsDeleting: (isDeleting: boolean) => void;
  reset: () => void;
}

const useInvestorDetailStore = create<InvestorDetailState>()(
  devtools(
    persist(
      (set) => ({
        investor: null,
        isLoading: true,
        isDeleting: false,
        setInvestor: (investor: IInvestor) => set({ investor }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsDeleting: (isDeleting: boolean) => set({ isDeleting }),
        reset: () => {
          set({ investor: null, isLoading: true });
        },
      }),
      {
        name: "investor-detail-storage",
      }
    )
  )
);

export default useInvestorDetailStore;
