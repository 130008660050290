import useSWRInfinite from 'swr/infinite';
import { ILeasePayment, LeasePaymentFilter, constructLeasePaymentsUrl, fetchLeasePayments } from '../services/leasePaymentService';

const PAGE_SIZE = 20;

export const useLeasePayments = (
    propertyId?: number,
    page_size: number | undefined = PAGE_SIZE,
    filter?: LeasePaymentFilter
) => {
    const fetcher = (url: string) => fetchLeasePayments(url);

    const getKey = (pageIndex: number, previousPageData: { lease_payments: ILeasePayment[] } | null) => {
        if (previousPageData && !previousPageData.lease_payments.length) return null; // reached the end
        return constructLeasePaymentsUrl(
            propertyId,
            pageIndex + 1,
            page_size,
            filter
        );
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    // Combine lease payments across all pages
    const lease_payments = data ? ([] as ILeasePayment[]).concat(...data.map(d => d.lease_payments)) : [];

    // Get the total count and current page count from the last page of data
    const totalCount = data?.[0]?.totalCount ?? 0;
    const currentPageCount = data?.[size - 1]?.currentPageCount ?? 0;

    return {
        lease_payments,
        totalCount,
        currentPageCount,
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.lease_payments.length < PAGE_SIZE,
        error,
    };
};
