import useSWRInfinite from 'swr/infinite';
import { ILease, LeaseSortBy, constructLeasesUrl, fetchLeases } from '../services/leaseService';
import { SortOrder } from '../@types';

const PAGE_SIZE = 20;

export const useLeases = (
    propertyId: number | null,
    search: string,
    total_rent_amount?: string,
    security_deposit?: string,
    payment_frequency?: string,
    duration?: string,
    sortBy?: LeaseSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchLeases(url);

    const getKey = (pageIndex: number, previousPageData: ILease[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructLeasesUrl(propertyId, search, pageIndex + 1, PAGE_SIZE, total_rent_amount, security_deposit, payment_frequency, duration, sortBy, sortOrder);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<ILease[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        leases: data ? ([] as ILease[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
