import { useEffect, useRef, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useSearchStore from "../../stores/searchStore";
import { useTranslation } from "react-i18next";
import Switch from "../../components/switch/switch";
import UnitTable from "./components/unit-table/unit-table";
import UnitSidebarModal from "../../modals/sidebar/unit-sidemodal/unit-sidemodal";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";
import { useNavigate } from "react-router-dom";
import { useUnits } from "../../hooks/useUnits";
import useAlertStore from "../../stores/alertStore";
import useDebounce from "../../hooks/useDebounce";
import { useProperties } from "../../hooks/useProperties";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import ImportUnitsModal from "../../modals/unit-csv-import-modal/unit-csv-import-modal";
import SidebarSelectField from "../../modals/sidebar/components/sidebar-select-field/sidebar-select-field";
import usePageTitle from "../../hooks/usePageTitle";
import { UnitSortBy } from "../../services/unitService";
import UnitFilters from "./components/unit-filters/unit-filters";
import useUnitFilterStore from "../../stores/unitFilterStore";
import { SortOrder } from "../../@types";

const UnitsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.units.page_title"));

    // References
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Stores
    const search = useSearchStore(state => state.search);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        bedrooms,
        bathrooms,
        minimumAnnualRent,
        soldBy,
        status,
        purpose,
        pmContractSigned,
        selectedProperty,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedProperty,
    } = useUnitFilterStore();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [csvFile, setCSVFile] = useState<File | null>(null);

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const { properties } = useProperties();
    const {
        units,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useUnits(
        selectedProperty,
        null,
        debouncedKeyword,
        bedrooms,
        bathrooms,
        minimumAnnualRent,
        soldBy,
        status,
        purpose,
        pmContractSigned,
        sortColumn || undefined,
        sortDirection || undefined
    );

    const propertyOptions = properties.map(property => ({
        label: `${property.property_name}`,
        value: property.property_id.toString(),
    }));

    useEffect(() => {
        if (selectedProperty === null && propertyOptions.length > 0) {
            setSelectedProperty(Number(propertyOptions[0].value));
        }
    }, [propertyOptions, selectedProperty, setSelectedProperty]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedProperty(index === 0 ? null : index);
        setSize(1);
    };

    const onClickUnit = (unit_id: number) => {
        navigate(`/units/${unit_id}`);
    };

    const handleSortChange = (column: UnitSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    const handleFileSelectClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCSVFile(file);
        }
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.units.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.units.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                    <div className="d-flex d-none d-sm-inline">
                        <CustomButton
                            className="me-3"
                            title={t("buttons.csv_import")}
                            color="green"
                            isLoading={false}
                            onClick={handleFileSelectClick}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <CustomButton
                            title={t("pages.units.create_unit_button")}
                            icon={BsPlus}
                            isLoading={false}
                            onClick={() => setIsSidebarModalVisible(true)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE BUTTONS */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <div className="d-flex d-sm-none mt-3">
                    <CustomButton
                        className="me-3"
                        title={t("buttons.csv_import")}
                        color="green"
                        isLoading={false}
                        onClick={handleFileSelectClick}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".csv"
                        onChange={handleFileChange}
                    />
                    <CustomButton
                        title={t("pages.units.create_unit_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            )}

            {/* DESKTOP FILTER BUTTONS */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />
            </div>

            {/* MOBILE FILTER BUTTONS */}
            <SidebarSelectField
                className="d-sm-none mt-4"
                id="unit-property-selection"
                label={t("labels.filter.property")}
                value={selectedProperty ? selectedProperty.toString() : "0"}
                onChange={handleFilterClick}
                options={propertyOptions}
            />

            {/* UNITS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <UnitFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={units.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <UnitTable
                                units={units}
                                onClickUnit={onClickUnit}
                                onSortChange={handleSortChange}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}

            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <>
                    <UnitSidebarModal
                        isVisible={isSidebarModalVisible}
                        onClose={() => setIsSidebarModalVisible(false)}
                        mutate={mutate}
                    />

                    {csvFile && (
                        <ImportUnitsModal
                            csv={csvFile}
                            onClose={(refresh: boolean) => {
                                setCSVFile(null)
                                if (refresh) {
                                    window.location.reload();
                                }
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default UnitsPage;
