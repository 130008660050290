import './owner-details.css';

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnitOwner } from '../../../../services/unitOwnerService';
import DefaultAvatar from '../../../../assets/images/default-avatar.webp';
import { getFormattedDate } from '../../../../utils/helpers';
import { IoAdd, IoCallOutline, IoMailOutline } from 'react-icons/io5';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddUnitOwnerSidebarModal from '../../../../modals/sidebar/add-unit-owner-sidemodal/add-unit-owner-sidemodal';
import { EmployeeRole } from '../../../../services/employeeService';

interface OwnerDetailsProps {
    employeeRoleId?: number | null;
    propertyId: number;
    unitId: number;
    owners: IUnitOwner[];
    onUnitOwnerAdded: (owner: IUnitOwner) => void;
}

const OwnerDetails: React.FC<OwnerDetailsProps> = ({ employeeRoleId, propertyId, unitId, owners, onUnitOwnerAdded }) => {
    const { t } = useTranslation();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);

    const onClickInvestor = (investor_id: number) => {
        const url = `${window.location.origin}/investors/${investor_id}`;
        window.open(url, '_blank');
    }

    const totalShares = useMemo(() => {
        return owners.reduce((total, owner) => total + owner.shares, 0)
    }, [owners]);

    return (
        <>
            <div className="unit-owner-details-card">
                {owners.length > 0 ? (
                    <>
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <h5 className="headline">{t("pages.unit_detail.owner_section.title", { numbers: owners.length })}</h5>
                            {totalShares < 100 && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-add-owner">{t("pages.unit_detail.owner_section.add_tooltip")}</Tooltip>}
                                >
                                    <div className="add-icon-container position-absolute end-0 me-3" onClick={() => setIsSidebarModalVisible(true)}>
                                        <IoAdd className="add-icon" />
                                    </div>
                                </OverlayTrigger>
                            )}
                        </div>
                        <div className="divider" />
                        <div className="owners ms-4">
                            {owners.map((owner) => (
                                <div
                                    key={owner.unit_owner_id}
                                    className="d-flex owner-details align-items-center mb-3"
                                    onClick={() => onClickInvestor(owner.investor.investor_id)}
                                >
                                    <img className="avatar" src={DefaultAvatar} alt="Owner Avatar" />
                                    <div className="ms-3">
                                        <div className="d-flex align-items-center mb-1">
                                            <h4 className="name">
                                                {owner.investor.first_name} {owner.investor.last_name}
                                            </h4>
                                            <OverlayTrigger overlay={<Tooltip>{owner.investor.phone_number}</Tooltip>}>
                                                <a href={`tel:${owner.investor.phone_number}`} className="contact-link">
                                                    <IoCallOutline className="contact-icon ms-2" />
                                                </a>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>{owner.investor.email}</Tooltip>}>
                                                <a href={`mailto:${owner.investor.email}`} className="contact-link">
                                                    <IoMailOutline className="contact-icon ms-2" />
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                        <h4 className="info">
                                            {t("pages.unit_detail.owner_section.info", { date: getFormattedDate(owner.created_at), shares: owner.shares })}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="d-flex justify-content-center align-items-center position-relative">
                        <h5 className="headline">{t("pages.unit_detail.owner_section.empty_title")}</h5>
                        {totalShares < 100 && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-add-owner">{t("pages.unit_detail.owner_section.add_tooltip")}</Tooltip>}
                            >
                                <div className="add-icon-container position-absolute end-0 me-3" onClick={() => setIsSidebarModalVisible(true)}>
                                    <IoAdd className="add-icon" />
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                )}
            </div>

            {/* ADD UNIT OWNER SIDEBAR MODAL */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <AddUnitOwnerSidebarModal
                    isVisible={isSidebarModalVisible}
                    propertyId={propertyId}
                    unitId={unitId}
                    onClose={() => setIsSidebarModalVisible(false)}
                    onAddUnitOwner={onUnitOwnerAdded}
                />
            )}
        </>
    );
};

export default OwnerDetails;
