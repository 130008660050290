// UnitDetails.tsx
import './unit-details.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnit } from '../../../../services/unitService';
import { SPACES_ENDPOINT } from '../../../../utils/constants';
import PictureModal from '../../../../modals/show-picture-modal/show-picture-modal';

interface UnitDetailsProps {
    unit: IUnit;
    leasesAmount: number;
}

const UnitDetails: React.FC<UnitDetailsProps> = ({ unit, leasesAmount }) => {
    const { t } = useTranslation();

    // States
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    return (
        <>
            <div className="unit-details-card">
                {/*<div className="image-container">
                    {unit.pictures.length > 1 ? (
                        <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {unit.pictures.map((picture, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-current={index === 0 ? "true" : "false"}
                                        aria-label={`Slide ${index + 1}`}
                                    ></button>
                                ))}
                            </div>
                            <div className="carousel-inner">
                                {unit.pictures.map((picture, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        data-bs-interval="5000"
                                    >
                                        <img
                                            src={`${SPACES_ENDPOINT}${picture.picture_path}`}
                                            className="d-block w-100"
                                            role='button'
                                            alt={`Unit ${index + 1}`}
                                            onClick={() => setSelectedImage(`${SPACES_ENDPOINT}${picture.picture_path}`)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    ) : (
                        <img
                            src={unit.pictures.length > 0 ? `${SPACES_ENDPOINT}${unit.pictures[0].picture_path}` : "https://placehold.co/600x400"}
                            className="d-block w-100"
                            alt="Unit 1"
                        />
                    )}
                </div>*/}
                <div className="d-flex justify-content-center align-items-center position-relative">
                    <h5 className="headline">Statistics</h5>
                </div>
                <div className="divider" />
                <div className="unit-info">
                    {/*<h5 className="unit-name">{unit.unit_number}</h5>
                    <p className="property-name">{unit.property.property_name}</p>
                    <div className="divider" />*/}
                    <div className="stats">
                        <div className="stat-item">{leasesAmount}<span>{t("pages.unit_detail.details_section.leases_stats")}</span></div> {/* LEASES */}
                        <div className="stat-item">0 AED<span>{t("pages.unit_detail.details_section.total_rent_stats")}</span></div> {/* TOTAL RENT */}
                    </div>
                </div>
            </div>

            {selectedImage && (
                <PictureModal
                    image={selectedImage}
                    onClose={() => setSelectedImage(null)}
                    onNext={() => {
                        if (unit.pictures) {
                            const totalImages = unit.pictures.length;
                            const currentIndex = unit.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                            const nextIndex = (currentIndex + 1) % totalImages;
                            setSelectedImage(`${SPACES_ENDPOINT}${unit.pictures[nextIndex].picture_path}`);
                        }
                    }}
                    onPrevious={() => {
                        if (unit.pictures) {
                            const totalImages = unit.pictures.length;
                            const currentIndex = unit.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                            const prevIndex = (currentIndex - 1 + totalImages) % totalImages;
                            setSelectedImage(`${SPACES_ENDPOINT}${unit.pictures[prevIndex].picture_path}`);
                        }
                    }}
                />
            )}
        </>
    );
};

export default UnitDetails;
