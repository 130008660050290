import styled, { keyframes } from "styled-components";

// Fade In animation
const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const PropertyDetailsCard = styled.div<{ underConstruction: boolean }>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  border: 1px solid #dfe3ea;
  opacity: 0;
  animation: ${fadeInLeft} 1s forwards 0.5s;
  position: relative;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
`;

export const Badge = styled.span`
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-color: #fcedcb;
  color: black;
  padding: 8px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: normal;
  z-index: 3;
`;

export const PropertyInfo = styled.div`
  text-align: center;
`;

export const PropertyName = styled.h5`
  font-weight: bold;
  margin-top: 20px;
`;

export const PropertyPlotNo = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f7fe;
  margin-bottom: 20px;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
`;

export const StatItem = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: black;

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #6c757d;
  }
`;

export const CustomProgress = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 16px;
  background-color: #f8f9fa;
  border-radius: 18px;
`;

export const ProgressBar = styled.div<{ leasePercentage: number }>`
  background-color: #fecc00;
  font-size: 12px;
  width: ${({ leasePercentage }) => `${leasePercentage}%`};
  height: 100%;
  border-radius: 18px;
`;

export const PropertyLeasedProgress = styled.p`
  text-align: start;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const ContactDetails = styled.div`
  margin-top: 20px;
  padding: 0 20px;
  color: #333;
  text-align: start;
`;

export const ContactDetailText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const ContactDetailStrong = styled.strong`
  color: #000;
`;

// Under Construction overlay
export const UnderConstructionOverlay = styled.div<{ underConstruction: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${({ underConstruction }) => (underConstruction ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;

  .construction-icon {
    color: #fff;
    font-size: 40px;
  }
`;
