import './quote.css';

import React, { CSSProperties } from 'react';

interface QuoteProps {
    className?: string | undefined;
    style?: CSSProperties | undefined;
    text: string;
    author: string;
    boldWords?: string[];
}

const Quote: React.FC<QuoteProps> = ({ className, style, text, author, boldWords = [] }) => {
    const getProcessedText = () => {
        let processedText = text;
        boldWords.forEach(word => {
            const regex = new RegExp(`(${word})`, 'gi');
            processedText = processedText.replace(regex, '<span class="bold">$1</span>');
        });
        return processedText;
    };

    return (
        <div className={`${className ?? ""}`} style={style}>
            <p className="quote-text" dangerouslySetInnerHTML={{ __html: getProcessedText() }} />
            <p className="quote-author">- {author}</p>
        </div>
    );
};

export default Quote;
