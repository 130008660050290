import React from 'react';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import { useTranslation } from 'react-i18next';

interface GenderSelectProps {
    className?: string;
    gender: string;
    onChange: (gender: string) => void;
    required?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    labelOnTop?: boolean;
}

const GenderSelect: React.FC<GenderSelectProps> = ({
    className,
    gender,
    onChange,
    required = false,
    disabled = false,
    errorMessage,
    labelOnTop = false
}) => {
    const { t } = useTranslation();

    const GENDER_OPTIONS: SidebarSelectFieldOptions[] = [
        { label: t("gender.male"), value: "male" },
        { label: t("gender.female"), value: "female" },
    ]

    return (
        <SidebarSelectField
            className={className}
            id="gender"
            label={t("labels.gender")}
            value={gender}
            onChange={onChange}
            options={GENDER_OPTIONS}
            required={required}
            labelOnTop={labelOnTop}
            disabled={disabled}
            errorMessage={errorMessage}
            placeholder={t("placeholders.select")}
        />
    );
};

export default GenderSelect;
