import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import SidebarInputField from '../../components/sidebar-input-field/sidebar-input-field';
import { ILeaseOccupant } from '../../../../services/leaseService';

interface LeaseOccupantsSectionProps {
    occupants: ILeaseOccupant[];
    onUpdateOccupants: (occupants: ILeaseOccupant[]) => void;
}

const LeaseOccupantsSection: React.FC<LeaseOccupantsSectionProps> = ({
    occupants,
    onUpdateOccupants
}) => {
    const { t } = useTranslation();

    // States
    const [occupantAmount, setOccupantAmount] = useState<number>(0);

    useEffect(() => {
        const newOccupants = Array(occupantAmount)
            .fill(null)
            .map((_, index) => occupants[index] || { name: '', email: '' });

        onUpdateOccupants(newOccupants);
    }, [occupantAmount]);

    // Functions
    const handleOccupantChange = (index: number, field: keyof ILeaseOccupant, value: string) => {
        const updatedDetails = occupants.map((detail, i) =>
            i === index ? { ...detail, [field]: value } : detail
        );
        onUpdateOccupants(updatedDetails);
    };

    const handleOccupantAmountChange = (value: string) => {
        const numberOfOccupants = Math.max(0, parseInt(value, 10) || 0); // Ensure non-negative number
        setOccupantAmount(numberOfOccupants);
    };

    return (
        <ExpandableSection title={t("modals.create_lease.occupants_information_title")} className="mt-4">
            <div className="row">
                <div className="col-12 col-xl-6">
                    <SidebarInputField
                        id="occupants"
                        type="number"
                        label={t("labels.no_of_occupants")}
                        placeholder="1"
                        value={occupantAmount.toString()}
                        onClear={() => setOccupantAmount(0)}
                        onChange={handleOccupantAmountChange}
                        labelOnTop
                    />
                </div>
                <p className="hint-message">{t("modals.create_lease.occupation_message")}</p>

                {occupants.map((occupant, index) => (
                    <div key={index} className="row">
                        <p>Occupant {index + 1}</p>
                        <div className="col-12 col-xl-6">
                            <SidebarInputField
                                id={`occupant-name-${index}`}
                                type="text"
                                label={t("labels.name")}
                                placeholder="Max Mustermann"
                                value={occupant.name}
                                onChange={(value: string) => handleOccupantChange(index, 'name', value)}
                                required
                                labelOnTop
                                onClear={() => handleOccupantChange(index, 'name', '')}
                            />
                        </div>
                        <div className="col-12 col-xl-6">
                            <SidebarInputField
                                id={`occupant-email-${index}`}
                                type="email"
                                label={t("labels.email")}
                                placeholder="max@mustermann.de"
                                value={occupant.email}
                                onChange={(value: string) => handleOccupantChange(index, 'email', value)}
                                required
                                labelOnTop
                                onClear={() => handleOccupantChange(index, 'email', '')}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </ExpandableSection>
    );
};

export default LeaseOccupantsSection;
