import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { IInvestor } from "./investorService";
import { IUnit } from "./unitService";

export interface IUnitOwner {
  unit_owner_id: number;
  unit: IUnit | null;
  investor: IInvestor;
  shares: number;
  purchase_price: number;
  created_at: string;
}

export const fetchUnitOwners = async (
  unitId: number
): Promise<IUnitOwner[]> => {
  const response = await fetch(`${API_URL}/units/${unitId}/owners`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.units;
};

export const createUnitOwner = async (
  unit_id: number,
  investor_id: number,
  shares: number,
  purchase_price: number
): Promise<IUnitOwner> => {
  const response = await fetch(`${API_URL}/units/${unit_id}/owners`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      investor_id,
      shares,
      purchase_price,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const uploadUnitOwnerDocuments = async (
  unit_id: number,
  unit_owner_id: number,
  files: File[]
): Promise<IDocument[]> => {
  const formData = new FormData();

  // Append the file file to the form data if provided
  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
  });

  const response = await fetch(
    `${API_URL}/units/${unit_id}/owners/${unit_owner_id}/power-of-attorney`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};
