import '../base-modal.css';
import './delete-construction-update-modal.css';

import React, { useState } from 'react';
import { deleteConstructionUpdate } from '../../services/constructionUpdateService';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    update_id: number;
    onClose: (deleted: boolean) => void;
}

const DeleteConstructionUpdateModal: React.FC<ModalProps> = ({ update_id, onClose }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onHandleDelete = async () => {
        try {
            setIsLoading(true);
            const deleted = await deleteConstructionUpdate(update_id);
            onClose(deleted);
        } catch (error) {
            console.log("Error while deleting construction update: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.delete_construction_update.title")}</h3>
                <p>{t("modals.delete_construction_update.message")}</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-danger add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleDelete} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.delete")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose(false)}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteConstructionUpdateModal;
