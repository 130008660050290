import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 12px;
`;

export const Headline = styled.h4`
  color: ${colorPalette.textMain};
  ${typeScale.headingXS}
`;

export const PlusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.primaryDark};
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: ${colorPalette.primaryMain};
  }
`;

export const PaymentList = styled.div`
  overflow-y: auto;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    max-height: 400px;
  }
`;

export const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};
  cursor: pointer;

  &:hover {
    background-color: ${colorPalette.blue100};
  }
`;

export const PaymentIcon = styled.div<{ paymentDue: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ paymentDue }) =>
    paymentDue ? colorPalette.blue800 : colorPalette.blue200};
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentTitle = styled.p`
  color: ${colorPalette.primaryDark};
  margin: 0;
  ${typeScale.headingXS}
`;

export const PaymentDetail = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXSMain}
`;

export const PaymentAmount = styled.p`
  color: ${colorPalette.primaryDark};
  margin: 0;
  ${typeScale.headingXS}
  text-align: right;
`;

export const PaymentPercentage = styled.p`
  color: ${colorPalette.textMain};
  margin: 0;
  text-align: right;
  ${typeScale.headingXS}
`;

export const EmptyText = styled.p`
  color: ${colorPalette.textDark};
  padding-left: 12px;
  padding-right: 12px;
  ${typeScale.bodySMMain}
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
  color: red;

  &:hover {
    color: ${colorPalette.primaryMain};
  }
`;
