import useSWRInfinite from 'swr/infinite';
import { constructNewsUrl, fetchNews, INews } from '../services/newsService';

const PAGE_SIZE = 20;

export const useNews = (search: string) => {
    const fetcher = (url: string) => fetchNews(url);

    const getKey = (pageIndex: number, previousPageData: INews[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructNewsUrl(search, pageIndex + 1, PAGE_SIZE);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<INews[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        news: data ? ([] as INews[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
