import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnitOwner } from '../../../../services/unitOwnerService';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import AddUnitOwnerSidebarModal from '../../../../modals/sidebar/add-unit-owner-sidemodal/add-unit-owner-sidemodal';
import styles from './owned-units-table.module.css';
import { EmployeeRole } from '../../../../services/employeeService';
import { IoAddCircleOutline } from 'react-icons/io5';
import useEmployeeStore from '../../../../stores/employeeStore';
import { formatMoney } from '../../../../utils/helpers';

interface OwnedUnitsTableProps {
    investorId: number;
    unitOwners: IUnitOwner[];
    onUnitOwnerAdded: (unitOwner: IUnitOwner) => void;
}

const OwnedUnitsTable: React.FC<OwnedUnitsTableProps> = ({ investorId, unitOwners, onUnitOwnerAdded }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onClickUnit = (unitId: number) => {
        const url = `${window.location.origin}/units/${unitId}`;
        window.open(url, '_blank');
    };

    const handleAddUnitOwner = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleUnitOwnerAdded = (unitOwner: IUnitOwner) => {
        onUnitOwnerAdded(unitOwner);
        setIsModalVisible(false);
    };

    return (
        <>
            <ExpandableSection
                className={styles.unitsOwnedContainer}
                title={t("pages.investor_detail.owned_units_table.title", { units: unitOwners.length })}
                isHeadlineLarge
                isDefaultExpanded
            >
                <div className="white-card">
                    {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                        <div className={styles.addUnitButton} onClick={handleAddUnitOwner}>
                            <IoAddCircleOutline className={styles.addUnitButtonIcon} />
                            <span>{t("pages.investor_detail.owned_units_table.add_button")}</span>
                        </div>
                    )}
                    <div className={styles.ownedUnitsTableResponsive}>
                        {unitOwners.length > 0 ? (
                            <table className={styles.ownedUnitsTable}>
                                <thead>
                                    <tr>
                                        <th>{t("pages.investor_detail.owned_units_table.property_name")}</th>
                                        <th>{t("pages.investor_detail.owned_units_table.unit_number")}</th>
                                        <th>{t("pages.investor_detail.owned_units_table.purchase_price")}</th>
                                        <th>{t("pages.investor_detail.owned_units_table.shares")}</th>
                                        <th>Purpose Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unitOwners.map((unitOwner) => (
                                        <tr key={unitOwner.unit_owner_id} onClick={() => onClickUnit(unitOwner.unit?.unit_id!)}>
                                            <td>{unitOwner.unit?.property.property_name}</td>
                                            <td>{unitOwner.unit?.unit_number}</td>
                                            <td>{formatMoney(unitOwner.purchase_price)}
                                            </td>
                                            <td>{Number(unitOwner.shares).toFixed(0)}%</td>
                                            <td>
                                                <span className={styles.squareFeetBadge}>
                                                    {unitOwner.unit?.purpose_type}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className={styles.emptyMessage}>{t("pages.investor_detail.owned_units_table.empty_message")}</p>
                        )}
                    </div>
                </div>
            </ExpandableSection>

            {isModalVisible && (
                <AddUnitOwnerSidebarModal
                    isVisible={isModalVisible}
                    onClose={handleCloseModal}
                    onAddUnitOwner={handleUnitOwnerAdded}
                    investorId={investorId}
                />
            )}
        </>
    );
};

export default OwnedUnitsTable;
