import React from 'react';
import CustomInputField from '../custom-input-field/custom-input-field';
import { IAddress } from '../../services/addressService';
import { useTranslation } from 'react-i18next';

interface AddressFormProps {
    address: IAddress;
    disabled?: boolean;
    onAddressChanged: (address: IAddress) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ address, disabled = false, onAddressChanged }) => {
    const { t } = useTranslation();

    const handleChange = (field: string) => (value: string) => {
        onAddressChanged({ ...address, [field]: value });
    };

    const handleClear = (field: string) => () => {
        onAddressChanged({ ...address, [field]: '' });
    };

    return (
        <>
            <div className="row">
                {/* Street Address */}
                <div className="col-md-6">
                    <CustomInputField
                        id="street-address"
                        label={t("labels.street_address1")}
                        placeholder={t("placeholders.street_address1")}
                        value={address.street_address1 ?? ""}
                        onChange={handleChange('street_address1')}
                        onClear={handleClear('street_address1')}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-6">
                    <CustomInputField
                        id="office"
                        label={t("labels.street_address2")}
                        value={address.street_address2 ?? ""}
                        onChange={handleChange('street_address2')}
                        onClear={handleClear('street_address2')}
                        placeholder={t("placeholders.street_address2")}
                        disabled={disabled}
                    />
                </div>
                {/* City */}
                <div className="col-md-6">
                    <CustomInputField
                        id="city"
                        label={t("labels.city")}
                        placeholder={t("placeholders.city")}
                        value={address.city ?? ""}
                        onChange={handleChange('city')}
                        onClear={handleClear('city')}
                        disabled={disabled}
                    />
                </div>
                {/* State */}
                <div className="col-md-6">
                    <CustomInputField
                        id="state"
                        label={t("labels.state_province_region")}
                        placeholder={t("placeholders.state_province_region")}
                        value={address.state_province_region ?? ""}
                        onChange={handleChange('state_province_region')}
                        onClear={handleClear('state_province_region')}
                        disabled={disabled}
                    />
                </div>
                {/* ZIP Code */}
                <div className="col-md-6">
                    <CustomInputField
                        id="zip-code"
                        label={t("labels.postal_code")}
                        placeholder="76228"
                        value={address.postal_code ?? ""}
                        onChange={handleChange('postal_code')}
                        onClear={handleClear('postal_code')}
                        disabled={disabled}
                    />
                </div>
                {/* Country */}
                <div className="col-md-6">
                    <CustomInputField
                        id="country"
                        label={t("labels.country")}
                        placeholder={t("placeholders.country")}
                        value={address.country ?? ""}
                        onChange={handleChange('country')}
                        onClear={handleClear('country')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default AddressForm;
