import './custom-button.css';

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';

interface CustomButtonProps {
    className?: string;
    title: string;
    isLoading: boolean;
    disabled?: boolean;
    color?: "blue" | "red" | "green";
    size?: "small" | "regular";
    icon?: IconType;
    onClick: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
    className,
    title,
    isLoading,
    disabled = false,
    color = "blue",
    size = "regular",
    icon: IconComponent,
    onClick
}) => {
    let buttonClassName = "btn-primary";
    if (color === "blue") {
        buttonClassName = "btn-primary";
    } else if (color === "red") {
        buttonClassName = "btn-danger";
    } else if (color === "green") {
        buttonClassName = "btn-success";
    }

    const sizeClassName = size === "small" ? "btn-small" : "btn-regular";

    return (
        <button
            className={`btn ${buttonClassName} ${sizeClassName} custom-btn ${className}`}
            onClick={onClick}
            disabled={isLoading || disabled}
        >
            {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
                <>
                    {IconComponent && <IconComponent className="me-2 custom-btn-icon" />}
                    {title}
                </>
            )}
        </button>
    );
};

export default CustomButton;
