import useSWRInfinite from 'swr/infinite';
import { ICourseModule, constructCourseModulesUrl, fetchCourseModules } from '../services/courseService';

export const useCourseModules = () => {
    const fetcher = (url: string) => fetchCourseModules(url);

    const getKey = (pageIndex: number, previousPageData: ICourseModule[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructCourseModulesUrl();
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<ICourseModule[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;

    return {
        courseModules: data ? ([] as ICourseModule[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        size,
        setSize,
        mutate,
        error,
    };
};
