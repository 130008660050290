import { API_URL } from "../utils/constants";

export interface ICourseModule {
  module_id: number;
  title: string;
  description: string;
  created_at: string;
}

export interface ICourseModuleItem {
  item_id: number;
  module_id: number;
  title: string;
  description: string;
  video_path: string;
  thumbnail_path: string;
  published: boolean;
  created_at: string;
  updated_at: string;
}

export const createCourseModuleItem = async (
  module_id: number,
  title: string,
  description: string,
  published: boolean
): Promise<ICourseModuleItem> => {
  const response = await fetch(`${API_URL}/course-modules/${module_id}/items`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      description,
      published,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateCourseModuleItem = async (
  module_id: number,
  item_id: number,
  title: string,
  description: string,
  published: boolean
): Promise<ICourseModuleItem> => {
  const response = await fetch(
    `${API_URL}/course-modules/${module_id}/items/${item_id}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        description,
        published,
      }),
    }
  );

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const uploadCourseModuleItemVideo = (
  module_id: number,
  item_id: number,
  video: File,
  onProgress: (percent: number) => void
): Promise<ICourseModuleItem> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", video);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${API_URL}/course-modules/${module_id}/items/${item_id}/video`,
      true
    );
    xhr.withCredentials = true;

    // Progress event
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentComplete));
      }
    };

    // Load event for successful completion
    xhr.onload = () => {
      if (xhr.status === 201) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(new Error("Upload failed: " + xhr.status));
      }
    };

    // Error handling
    xhr.onerror = () => {
      reject(new Error("Network error occurred"));
    };

    // Sending the request
    xhr.send(formData);
  });
};

export const uploadCourseModuleItemThumbnail = async (
  module_id: number,
  item_id: number,
  thumbnail: File
): Promise<ICourseModuleItem> => {
  const formData = new FormData();
  formData.append(`file}`, thumbnail);

  const response = await fetch(
    `${API_URL}/course-modules/${module_id}/items/${item_id}/thumbnail`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const deleteCourseModuleItem = async (
  module_id: number,
  item_id: number
): Promise<boolean> => {
  const response = await fetch(
    `${API_URL}/course-modules/${module_id}/items/${item_id}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );

  return response.status === 200;
};

export const fetchCourseModules = async (
  url: string
): Promise<ICourseModule[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.modules;
};

export const constructCourseModulesUrl = (): string => {
  return `${API_URL}/course-modules`;
};

export const fetchCourseModuleItems = async (
  url: string
): Promise<ICourseModuleItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.items;
};

export const constructCourseModuleItemsUrl = (
  module_id: number,
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/course-modules/${module_id}/items?${queryParams.toString()}`;
};

export const downloadCourseVideo = async (
  module_id: number,
  item_id: number
): Promise<Blob | null> => {
  const response = await fetch(
    `${API_URL}/course-modules/${module_id}/items/${item_id}/video`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
