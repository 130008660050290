import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";

export interface IConstructionProgressItem {
  id: number;
  published_date: string;
  description: string;
  percentage: number;
  created_at: string;
  document: IDocument | null;
}

export const fetchConstructionProgressItems = async (
  url: string
): Promise<IConstructionProgressItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.construction_progress_items;
};

export const constructConstructionProgressItemsUrl = (
  property_id: number
): string => {
  let queryParams = new URLSearchParams();

  if (property_id !== undefined) {
    queryParams.append("property_id", property_id.toString());
  }

  return `${API_URL}/construction-progress?${queryParams.toString()}`;
};

export const createConstructionProgressItem = async (
  property_id: number,
  published_date: string,
  description: string,
  percentage: number
): Promise<IConstructionProgressItem> => {
  const response = await fetch(`${API_URL}/construction-progress`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      property_id,
      published_date,
      description,
      percentage,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.payment_plan_item);
};

export const updateConstructionProgressItem = async (
  item_id: number,
  published_date: string,
  description: string,
  percentage: number
): Promise<IConstructionProgressItem> => {
  const response = await fetch(`${API_URL}/construction-progress/${item_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      published_date,
      description,
      percentage: percentage,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.construction_progress_item);
};

export const deleteConstructionProgressItem = async (
  item_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/construction-progress/${item_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const uploadConstructionProgressDocument = async (
  item_id: number,
  file: File
): Promise<IDocument> => {
  const formData = new FormData();
  formData.append(`file`, file);

  const response = await fetch(
    `${API_URL}/construction-progress/${item_id}/document`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.document;
  else throw new Error(data.message);
};
