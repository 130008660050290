import './expandable-section.css';

import React, { useState, ReactNode, useEffect } from 'react';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';

interface ExpandableSectionProps {
    className?: string | undefined;
    title: string;
    subtitle?: string;
    disabled?: boolean;
    isHeadlineLarge?: boolean;
    isDefaultExpanded?: boolean;
    children: ReactNode;
    onToggle?: (isExpanded: boolean) => void;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
    className,
    title,
    subtitle,
    disabled = false,
    isHeadlineLarge = false,
    isDefaultExpanded = false,
    children,
    onToggle,
}) => {
    const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

    const handleClick = () => {
        if (!disabled) {
            const newIsExpanded = !isExpanded;
            setIsExpanded(newIsExpanded);

            if (onToggle) {
                onToggle(newIsExpanded);
            }
        }
    };

    useEffect(() => {
        if (onToggle && isDefaultExpanded) {
            onToggle(isDefaultExpanded);
        }
    }, [onToggle, isDefaultExpanded]);

    return (
        <div className={`${className} ${disabled ? 'disabled' : ''}`}>
            <div className="d-flex justify-content-between align-items-center" onClick={handleClick} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
                <div>
                    {isHeadlineLarge ? (
                        <>
                            <h4 className="expandable-section-title-large">{title}</h4>
                            {subtitle && isExpanded && (
                                <p className="expandable-section-subtitle-large">{subtitle}</p>
                            )}
                        </>
                    ) : (
                        <>
                            <p className="expandable-section-title">{title}</p>
                            {subtitle && isExpanded && (
                                <p className="expandable-section-subtitle">{subtitle}</p>
                            )}
                        </>
                    )}
                </div>
                <span>
                    {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                </span>
            </div>
            {isExpanded && (
                <div className="mt-3">
                    {children}
                </div>
            )}
        </div>
    );
};

export default ExpandableSection;