import '../base-modal.css';
import './delete-confirmation-modal.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmationModalProps {
    title: string;
    message: string;
    isLoading?: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    title,
    message,
    isLoading = false,
    onClose,
    onDelete
}) => {
    const { t } = useTranslation();

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{title}</h3>
                <p>{message}</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-danger add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onDelete} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.delete")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose()}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;
