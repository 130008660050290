import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import useAvailableUnitFilterStore from '../../../../stores/availableUnitFilterStore';

interface AvailableUnitFiltersProps {
    onFilterUpdated: () => void;
}

const AvailableUnitFilters: React.FC<AvailableUnitFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    // Zustand store
    const { bedrooms, bathrooms, minimumAnnualRent, isExpanded, setFilters, setIsExpanded } = useAvailableUnitFilterStore();

    // Local state for filters
    const [localBedrooms, setLocalBedrooms] = useState(bedrooms);
    const [localBathrooms, setLocalBathrooms] = useState(bathrooms);
    const [localMinimumAnnualRent, setLocalMinimumAnnualRent] = useState(minimumAnnualRent);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localBedrooms !== bedrooms ||
            localBathrooms !== bathrooms ||
            localMinimumAnnualRent !== minimumAnnualRent;

        setFiltersChanged(hasChanges);
    }, [localBedrooms, localBathrooms, localMinimumAnnualRent, bedrooms, bathrooms, minimumAnnualRent]);

    const handleApplyFilters = () => {
        setFilters(localBedrooms, localBathrooms, localMinimumAnnualRent);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Bedrooms Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="bedroomsInput"
                        label={t('labels.filter.bedrooms')}
                        placeholder="1"
                        value={localBedrooms}
                        onChange={setLocalBedrooms}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Bathrooms Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="bathroomsInput"
                        label={t('labels.filter.bathrooms')}
                        placeholder="1-2"
                        value={localBathrooms}
                        onChange={setLocalBathrooms}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Minimum Annual Rent Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="minimumAnnualRentInput"
                        label={t('labels.annual_rent')}
                        placeholder="50,000.00-100,000.00"
                        value={localMinimumAnnualRent}
                        onChange={setLocalMinimumAnnualRent}
                        type="number"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default AvailableUnitFilters;
