import './investor-table.css';

import React, { useState } from 'react';
import { IInvestor, InvestorSortBy, exportInvestorCsv } from '../../services/investorService';
import { useNavigate } from 'react-router-dom';
import DefaultAvatar from '../../assets/images/default-avatar.webp';
import { useTranslation } from 'react-i18next';
import { censorEmail, censorPhoneNumber } from '../../utils/helpers';
import InvestorStatusBadge from '../investor-status-badge/investor-status-badge';
import CustomButton from '../custom-button/custom-button';
import { SortOrder } from '../../@types';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

interface InvestorTableProps {
    investors: IInvestor[];
    selectedSortColumn: InvestorSortBy | null;
    selectedSortDirection: SortOrder | null;
    onSortChange: (sortColumn: InvestorSortBy, sortDirection: SortOrder) => void;
}

const InvestorTable: React.FC<InvestorTableProps> = ({ investors, selectedSortColumn, selectedSortDirection, onSortChange }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // States
    const [privacyMode, setPrivacyMode] = useState<boolean>(true);
    const [selectedInvestors, setSelectedInvestors] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onClickInvestor = (investor: IInvestor) => {
        navigate(`/investors/${investor.investor_id}`);
    }

    const handleSort = (column: InvestorSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleCheckboxChange = (investorId: number) => {
        setSelectedInvestors(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(investorId)) {
                newSelected.delete(investorId);
            } else {
                newSelected.add(investorId);
            }
            return newSelected;
        });
    }

    const handleExport = async () => {
        const selected = Array.from(selectedInvestors);
        setIsLoading(true);
        try {
            const blob = await exportInvestorCsv(selected);

            // Generate current date and time string
            const now = new Date();
            const formattedDate = now.toLocaleDateString().split('/').reverse().join('-'); // yyyy-mm-dd
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-"); // hh-mm-ss    

            const fileName = `investors_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset selected investors
            setSelectedInvestors(new Set());
        } catch (error) {
            console.error("Error exporting investors:", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-3" style={{ height: 50 }}>
                <label className="form-switch ms-2">
                    <input className="form-check-input" type="checkbox" checked={privacyMode} onChange={() => setPrivacyMode(!privacyMode)} />
                    <label className="form-check-label ms-2">{t("labels.privacy_mode_switch")}</label>
                </label>
                <div className="d-flex align-items-center">
                    {selectedInvestors.size > 0 && (
                        <span className="me-3">{selectedInvestors.size} {t("labels.selected")}</span>
                    )}
                    {selectedInvestors.size > 0 && (
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    )}
                </div>
            </div>
            <div className="investor-table-responsive">
                <table className="investor-table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedInvestors(new Set(investors.map(i => i.investor_id)));
                                        } else {
                                            setSelectedInvestors(new Set());
                                        }
                                    }}
                                    checked={selectedInvestors.size === investors.length}
                                />
                            </th>
                            <th onClick={() => handleSort(InvestorSortBy.NAME)} style={{ cursor: 'pointer' }}>
                                {t("investor_table.name_header")}
                                {selectedSortColumn === InvestorSortBy.NAME ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th onClick={() => handleSort(InvestorSortBy.EMAIL)} style={{ cursor: 'pointer' }} className="d-none d-md-table-cell">
                                {t("investor_table.email_header")}
                                {selectedSortColumn === InvestorSortBy.EMAIL ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th onClick={() => handleSort(InvestorSortBy.PHONE_NUMBER)} style={{ cursor: 'pointer' }} className="d-none d-md-table-cell">
                                {t("investor_table.phone_number_header")}
                                {selectedSortColumn === InvestorSortBy.PHONE_NUMBER ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th className="d-none d-xxl-table-cell">{t("investor_table.sales_agent_header")}</th>
                            <th className="d-none d-xl-table-cell">{t("investor_table.status_header")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {investors.map(investor => (
                            <tr key={investor.investor_id} className={`${investor.status.status_name.toLowerCase()}`}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedInvestors.has(investor.investor_id)}
                                        onChange={() => handleCheckboxChange(investor.investor_id)}
                                    />
                                </td>
                                <td onClick={() => onClickInvestor(investor)}>{investor.first_name} {investor.last_name}</td>
                                <td className="d-none d-md-table-cell">
                                    <div className="email-info">
                                        <span>
                                            {privacyMode ? censorEmail(investor.email) : investor.email}
                                        </span>
                                    </div>
                                </td>
                                <td className="d-none d-md-table-cell">
                                    {privacyMode ? censorPhoneNumber(investor.phone_number ?? undefined) : investor.phone_number ?? "-"}
                                </td>
                                <td className="d-none d-xxl-table-cell">
                                    {investor.sales_agent ? (
                                        <div className="sales-agent">
                                            <img src={investor.sales_agent.avatar ?? DefaultAvatar} alt={investor.sales_agent.first_name} />
                                            {investor.sales_agent.first_name} {investor.sales_agent.last_name}
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="d-none d-xl-table-cell">
                                    <InvestorStatusBadge status={investor.status.status_name} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvestorTable;
