import { API_URL } from "../utils/constants";
import { IRole } from "./roleService";

export enum EmployeeRole {
  ADMINISTRATOR = 1,
  SALES_AGENT = 2,
  PROPERTY_MANAGER = 3,
  FINANCE_MANAGER = 4,
}

export interface IEmployee {
  employee_id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar?: string | null;
  phone_number?: string | null;
  role: IRole;
  created_at: string;
  updated_at: string;
}

export const fetchEmployees = async (url: string): Promise<IEmployee[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.employees;
};

export const constructEmployeesUrl = (
  roleId: number | null,
  search: string,
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (roleId !== null && roleId !== 4) {
    queryParams.append("roleId", roleId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/employees?${queryParams.toString()}`;
};

export const fetchEmployee = async (investorId: number): Promise<IEmployee> => {
  const response = await fetch(`${API_URL}/employees/${investorId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const createEmployee = async (
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  role_id: number
): Promise<IEmployee> => {
  const response = await fetch(`${API_URL}/employees`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      role_id,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateEmployee = async (
  employee_id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  role_id: number
): Promise<IEmployee> => {
  const response = await fetch(`${API_URL}/employees/${employee_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      role_id,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteEmployee = async (employee_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/employees/${employee_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};
