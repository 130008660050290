import { API_URL } from "../utils/constants";

export interface IRole {
  role_id: number;
  role_name: string;
  updated_at: string;
}

export const fetchRoles = async (): Promise<IRole[]> => {
  const response = await fetch(`${API_URL}/employees/roles`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.roles;
};
