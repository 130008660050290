import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import SidebarSelectField from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import useInvestorFilterStore from '../../../../stores/investorFilterStore';
import { fetchSalesAgents } from '../../../../services/salesAgentService';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';

interface InvestorFiltersProps {
    onFilterUpdated: () => void;
}

const InvestorFilters: React.FC<InvestorFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    // Stores
    const { salesAgent, ownedUnits, totalPurchasedValue, isExpanded, setFilters, setIsExpanded } = useInvestorFilterStore();

    // States
    const [salesAgents, setSalesAgents] = useState<{ label: string; value: string; }[]>([]);
    const [localSalesAgent, setLocalSalesAgent] = useState(salesAgent);
    const [localOwnedUnits, setLocalOwnedUnits] = useState(ownedUnits);
    const [localTotalPurchasedValue, setLocalTotalPurchasedValue] = useState(totalPurchasedValue);
    const [filtersChanged, setFiltersChanged] = useState(false);

    useEffect(() => {
        const loadSalesAgents = async () => {
            try {
                const agents = await fetchSalesAgents();
                setSalesAgents(agents.map(agent => {
                    return { label: `${agent.first_name} ${agent.last_name}`, value: agent.employee_id.toString() }
                }));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            }
        }
        loadSalesAgents();
    }, []);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges = localSalesAgent !== salesAgent
            || localOwnedUnits !== ownedUnits
            || localTotalPurchasedValue !== totalPurchasedValue;

        setFiltersChanged(hasChanges);
    }, [localSalesAgent, localOwnedUnits, localTotalPurchasedValue, salesAgent, ownedUnits, totalPurchasedValue]);

    const handleApplyFilters = () => {
        setFilters(localSalesAgent, localOwnedUnits, localTotalPurchasedValue);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Sold By Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="investor-filter-sales-agent-selection"
                        label={t("labels.sales_agent")}
                        value={localSalesAgent}
                        onChange={setLocalSalesAgent}
                        options={salesAgents}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Owned Units Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="ownedUnitsInput"
                        label={t('labels.filter.owned_units')}
                        placeholder="1-2"
                        value={localOwnedUnits}
                        onChange={setLocalOwnedUnits}
                        onClear={() => setLocalOwnedUnits("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Total Purchased Value Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="totalPurchasedValueInput"
                        label={t('labels.filter.total_purchased_value')}
                        placeholder="800,000 AED"
                        value={localTotalPurchasedValue}
                        onChange={setLocalTotalPurchasedValue}
                        onClear={() => setLocalTotalPurchasedValue("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default InvestorFilters;
