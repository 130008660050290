import { API_URL } from "../utils/constants";

export interface INews {
  news_id: number;
  title: string;
  content: string;
  published_at: string;
  created_at: string;
  updated_at: string;
}

export const fetchNews = async (url: string): Promise<INews[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.news;
};

export const constructNewsUrl = (
  search: string,
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/news?${queryParams.toString()}`;
};

export const createNews = async (
  title: string,
  content: string,
  published_at?: string
): Promise<INews> => {
  const response = await fetch(`${API_URL}/news`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      content,
      published_at,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateNews = async (
  news_id: number,
  title: string,
  content: string,
  published_at?: string
): Promise<INews> => {
  const response = await fetch(`${API_URL}/news/${news_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      content,
      published_at,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteNews = async (news_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/news/${news_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};
