import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import FileCard from '../../../../components/file-card/file-card';
import FileUpload, { UploadedFile } from '../../../../components/file-upload/file-upload';

interface LeaseDocumentUploadSectionProps {
    files: UploadedFile[];
    onFileSelected: (selectedFiles: UploadedFile[]) => void;
    onRemoveFile: (fileIndex: number) => void;
}

const LeaseDocumentUploadSection: React.FC<LeaseDocumentUploadSectionProps> = ({ files, onFileSelected, onRemoveFile }) => {
    const { t } = useTranslation();

    return (
        <ExpandableSection title={t("modals.create_lease.upload_documents")} className="mt-4">
            <FileUpload
                id="create-lease-documents"
                allowedTypes=".pdf,image/*"
                onFileSelected={onFileSelected}
                currentFileCount={files.length}
            />
            {files.map((file, index) => (
                <FileCard
                    index={index}
                    className="mt-3"
                    key={file.filename}
                    fileName={file.filename}
                    fileSize={file.filesize}
                    progress={file.progress}
                    uploaded={file.uploaded}
                    onClickRemove={() => onRemoveFile(index)}
                />
            ))}
        </ExpandableSection>
    );
};

export default LeaseDocumentUploadSection;
