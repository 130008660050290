import useSWRInfinite from 'swr/infinite';
import { IUnit, UnitSortBy, constructUnitsUrl, fetchUnits } from '../services/unitService';
import { SortOrder } from '../@types';

const PAGE_SIZE = 20;

export const useUnits = (
    propertyId: number | null,
    unitId: number | null,
    search: string,
    bedrooms?: string,
    bathrooms?: string,
    minimum_annual_rent?: string,
    sold_by?: string,
    status?: string,
    purpose?: string,
    pm_contract_signed?: boolean,
    sortBy?: UnitSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchUnits(url);

    const getKey = (pageIndex: number, previousPageData: IUnit[] | null) => {
        if (previousPageData && !previousPageData.length) return null; // reached the end
        return constructUnitsUrl(
            propertyId,
            unitId,
            search,
            pageIndex + 1,
            PAGE_SIZE,
            bedrooms,
            bathrooms,
            minimum_annual_rent,
            sold_by,
            status,
            purpose,
            pm_contract_signed,
            sortBy,
            sortOrder
        );
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IUnit[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        units: data ? ([] as IUnit[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
