import '../base-modal.css';
import './info-modal.css';

import { useTranslation } from 'react-i18next';
import packageInfo from '../../../package.json';

import React from 'react';

interface ModalProps {
    onClose: (deleted: boolean) => void;
}

const InfoModal: React.FC<ModalProps> = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.info.title")}</h3>
                <p>Version: v{packageInfo.version} | Build: 1 | {t("modals.info.developer")}: BitPalm LLC</p>
                <p>{t("modals.info.email_link")} <a href="mailto:support@bitpalm.com">support@bitpalm.ae</a>.</p>
                <p>{t("modals.info.phone_number_link")} +971 58 566 4121.</p>
                <div className="text-end mt-4">
                    <button className="btn btn-outline-secondary me-2 impressum-btn" onClick={() => window.open('https://www.danielgarofoli.com/impressum', '_blank')}>{t("modals.info.imprint_button")}</button>
                    <button className="btn btn-dark close-btn" onClick={() => onClose(false)}>{t("buttons.close")}</button>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;
