import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useEmployeeStore from '../stores/employeeStore';

interface ProtectedRouteProps {
    role_ids?: number[];
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, role_ids = [] }) => {
    const navigate = useNavigate();

    const employee = useEmployeeStore((state) => state.employee);

    useEffect(() => {
        if (!employee) {
            navigate("/login");
        }
        else if (role_ids.length > 0 && !role_ids?.includes(employee.role_id)) {
            navigate("/");
        }
    }, [employee, navigate, role_ids]);

    if (!employee) {
        return <Navigate to="/login" replace />;
    }

    return <>
        {children}
    </>;
};

export default ProtectedRoute;
