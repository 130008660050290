import React, { useCallback, useState } from 'react';
import {
    EmptyText,
    Headline,
    HeadlineWrapper,
    PlusButton,
    ProgressCircle,
    ProgressDate,
    ProgressIcon,
    ProgressItem,
    ProgressItemDetail,
    ProgressLine,
    ProgressList,
    ProgressPercentage,
    ProgressStatus,
    StatusText,
} from './construction-progress-items.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { deleteConstructionProgressItem, IConstructionProgressItem } from '../../../../services/constructionProgressService';
import { useTranslation } from 'react-i18next';
import useEditPermission from '../../../../hooks/useEditPermission';
import DeleteConfirmationModal from '../../../../modals/delete-confirmation-modal/delete-confirmation-modal';
import { EmployeeRole } from '../../../../services/employeeService';
import useEmployeeStore from '../../../../stores/employeeStore';
import ConstructionProgressModal from '../../../../modals/construction-progress-modal/construction-progress-modal';
import { format } from 'date-fns';
import { downloadDocument, IDocument } from '../../../../services/documentService';

interface ConstructionProgressProps {
    property_id: number;
    progressItems: IConstructionProgressItem[];
    onUpdatedConstructionProgressItems: (items: IConstructionProgressItem[]) => void;
}

const ConstructionProgress: React.FC<ConstructionProgressProps> = ({ property_id, progressItems, onUpdatedConstructionProgressItems }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedConstructionProgressItem, setSelectedConstructionProgressItem] = useState<IConstructionProgressItem | null>(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<number | null>(null);

    // Functions
    const onDeleteConstructionProgressItem = useCallback(async () => {
        if (isDeleteModalVisible) {
            try {
                setIsLoading(true);
                const isDeleted = await deleteConstructionProgressItem(isDeleteModalVisible);
                if (isDeleted) {
                    onUpdatedConstructionProgressItems(progressItems.filter(item => item.id !== isDeleteModalVisible));
                }
            } catch (error) {
                console.log(`Error while deleting construction progress item (${isDeleteModalVisible}):`, error);
            } finally {
                setIsLoading(false);
                setIsDeleteModalVisible(null);
            }
        }
    }, [isDeleteModalVisible, progressItems]);

    const onDownloadDLDReport = async (dldReport: IDocument) => {
        try {
            setIsLoading(true);
            const blob: Blob | null = await downloadDocument(dldReport.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = dldReport.file_name;
                document.body.appendChild(anchor);
                anchor.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            }
        } catch (error) {
            console.log("error while downloading file: ", error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="white-card" style={{ padding: 0 }}>
                <HeadlineWrapper>
                    <Headline>{t("pages.property_detail.construction_progress.headline")}</Headline>
                    <PlusButton onClick={() => setIsAddModalVisible(true)}>
                        <MaterialIcon icon="add_road" size={20} color={colorPalette.white} />
                    </PlusButton>
                </HeadlineWrapper>
                {progressItems.length > 0 ? (
                    <ProgressList>
                        <div style={{ display: 'grid', gridTemplateColumns: '40px 100px 1fr auto', alignItems: 'center', paddingLeft: 16 }}>
                            <div></div>
                            <StatusText isFirst={true}>{t("pages.property_detail.construction_progress.current_status")}</StatusText>
                            <div></div>
                            <StatusText isFirst={true}>{t("pages.property_detail.construction_progress.dld_report")}</StatusText>
                        </div>
                        {progressItems
                            .sort((a, b) => new Date(b.published_date).getTime() - new Date(a.published_date).getTime())
                            .map((item, index) => (
                                <ProgressItem
                                    key={index}
                                    isLast={index === progressItems.length - 1}
                                    onClick={() => {
                                        setIsAddModalVisible(true);
                                        setSelectedConstructionProgressItem(item);
                                    }}
                                >
                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <ProgressCircle isFirst={index === 0} />
                                        <ProgressLine
                                            isTopItem={index === 0}
                                            isBottomItem={index === progressItems.length - 1}
                                        />
                                    </div>
                                    <ProgressPercentage isActive={index === 0}>
                                        {Math.round(item.percentage!)}%
                                    </ProgressPercentage>
                                    <ProgressItemDetail>
                                        <ProgressDate>{format(item.published_date, "dd.MM.yyyy")}</ProgressDate>
                                        <ProgressStatus isFirst={index === 0}>{item.description}</ProgressStatus>
                                    </ProgressItemDetail>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                        {item.document && (
                                            <ProgressIcon onClick={(event) => {
                                                event.stopPropagation();
                                                onDownloadDLDReport(item.document!);
                                            }}>
                                                <MaterialIcon icon="file_open" size={24} color={colorPalette.blue500} />
                                            </ProgressIcon>
                                        )}
                                        <ProgressIcon onClick={(event) => {
                                            setIsDeleteModalVisible(item.id);
                                            event.stopPropagation();
                                        }}>
                                            <MaterialIcon icon="delete" size={24} color={colorPalette.errorDark} />
                                        </ProgressIcon>
                                    </div>
                                </ProgressItem>
                            ))}
                    </ProgressList>
                ) : (
                    <EmptyText>{t("pages.property_detail.construction_progress.empty_text")}</EmptyText>
                )}
            </div>

            {/* ADD PAYMENT PLAN ITEM MODAL */}
            {
                isAddModalVisible && hasEditPermission && (
                    <ConstructionProgressModal
                        property_id={property_id}
                        construction_progress_item={selectedConstructionProgressItem}
                        onClose={() => {
                            setIsAddModalVisible(false);
                            setSelectedConstructionProgressItem(null);
                        }}
                        onCreated={(item) => {
                            onUpdatedConstructionProgressItems([...progressItems, item]);
                            setIsAddModalVisible(false);
                            setSelectedConstructionProgressItem(null);
                        }}
                        onUpdated={(updatedItem) => {
                            const updatedList = [...progressItems.map((item) =>
                                item.id === updatedItem.id ? updatedItem : item
                            )];
                            onUpdatedConstructionProgressItems(updatedList);
                            setIsAddModalVisible(false);
                            setSelectedConstructionProgressItem(null);
                        }}
                    />
                )
            }

            {/* DELETE PAYMENT PLAN ITEM MODAL */}
            {
                isDeleteModalVisible && hasEditPermission && (
                    <DeleteConfirmationModal
                        title={t("pages.property_detail.construction_progress.delete_modal.title")}
                        message={t("pages.property_detail.construction_progress.delete_modal.message")}
                        onClose={() => setIsDeleteModalVisible(null)}
                        onDelete={onDeleteConstructionProgressItem}
                        isLoading={isLoading}
                    />
                )
            }
        </>
    );
};

export default ConstructionProgress;
