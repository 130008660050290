import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import useTenantFilterStore from '../../../../stores/tenantFilterStore';
import { useProperties } from '../../../../hooks/useProperties';
import SidebarSelectField from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';

interface TenantFiltersProps {
    onFilterUpdated: () => void;
}

const TenantFilters: React.FC<TenantFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();
    const { properties } = useProperties();

    // Select Options
    const propertyOptions = properties.map(property => ({
        label: `${property.property_name}`,
        value: property.property_id.toString(),
    }));

    // Zustand store
    const { active, selectedProperty, isExpanded, setFilters, setIsExpanded, setSelectedProperty } = useTenantFilterStore();

    // Local state for filters
    const [localActive, setLocalActive] = useState(active);
    const [localProperty, setLocalProperty] = useState(selectedProperty);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localActive !== active ||
            localProperty !== selectedProperty;

        setFiltersChanged(hasChanges);
    }, [localActive, localProperty, active, selectedProperty]);

    const handleApplyFilters = () => {
        setFilters(localActive);
        setSelectedProperty(localProperty)
        onFilterUpdated();
    };

    const handlePropertyClick = (value: string) => {
        const index = Number(value);
        setLocalProperty(index === 0 ? null : index);
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Project Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="units-filter-property-selection"
                        label={t("labels.property")}
                        placeholder={t("placeholders.select")}
                        value={localProperty?.toString()}
                        onChange={handlePropertyClick}
                        options={propertyOptions}
                        showClearIcon
                        labelOnTop
                        isSearchable={false}
                    />
                </div>

                {/* Active Filter */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center form-check form-switch">
                    <input
                        className="form-check-input"
                        style={{ marginLeft: 0 }}
                        type="checkbox"
                        checked={localActive}
                        onChange={(e) => setLocalActive(e.target.checked)}
                    />
                    <label className="form-check-label ms-2">{t("labels.active")}</label>
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default TenantFilters;
