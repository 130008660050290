import useSWR from 'swr';
import { fetchDashboardData, IDashboardData } from '../services/dashboardService';
import { API_URL } from '../utils/constants';


export const useDashboard = () => {
    const fetcher = (url: string) => fetchDashboardData(url);
    const { data, error, mutate } = useSWR<IDashboardData>(`${API_URL}/dashboard/`, fetcher);

    return {
        dashboardData: data,
        isLoading: !error && !data,
        error: error,
        mutate,
    };
};
