// src/pages/units/components/unit-filters/unit-filters.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import SidebarSelectField from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import useLeaseFilterStore from '../../../../stores/leaseFilterStore';
import { SwitchOptions } from '../../../../components/switch/switch';

interface LeaseFiltersProps {
    onFilterUpdated: () => void;
}

const LeaseFilters: React.FC<LeaseFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    const paymentFrequencySwitchOptions: SwitchOptions[] = [
        { label: t("labels.monthly"), value: "monthly" },
        { label: t("labels.quarterly"), value: "quarterly" },
        { label: t("labels.biannual"), value: "biannual" },
        { label: t("labels.yearly"), value: "yearly" }
    ];

    // Zustand store
    const { total_rent_amount, security_deposit, payment_frequency, duration, isExpanded, setFilters, setIsExpanded } = useLeaseFilterStore();

    // Local state for filters
    const [localTotalRentAmount, setLocalTotalRentAmount] = useState<string>(total_rent_amount);
    const [localSecurityDeposit, setLocalSecurityDeposit] = useState<string>(security_deposit);
    const [localPaymentFrequency, setLocalPaymentFrequency] = useState<string>(payment_frequency);
    const [localDuration, setLocalDuration] = useState<string>(duration);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localTotalRentAmount !== total_rent_amount ||
            localSecurityDeposit !== security_deposit ||
            localPaymentFrequency !== payment_frequency ||
            localDuration !== duration;

        setFiltersChanged(hasChanges);
    }, [localTotalRentAmount, localSecurityDeposit, localPaymentFrequency, localDuration, total_rent_amount, security_deposit, payment_frequency, duration]);

    const handleApplyFilters = () => {
        setFilters(localTotalRentAmount, localSecurityDeposit, localPaymentFrequency, localDuration);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Total Rent Amount Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="lease-filters-total-rent-amount"
                        label={t('labels.total_rent')}
                        placeholder="50,000 AED - 70,000 AED"
                        value={localTotalRentAmount}
                        onChange={setLocalTotalRentAmount}
                        onClear={() => setLocalTotalRentAmount("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Security Deposit Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="lease-filters-security-deposit"
                        label={t('labels.security_deposit')}
                        placeholder="3,000 AED"
                        value={localSecurityDeposit}
                        onChange={setLocalSecurityDeposit}
                        onClear={() => setLocalSecurityDeposit("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Duration Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="lease-filters-duration"
                        label={t('labels.duration')}
                        placeholder="1-6 months"
                        value={localDuration}
                        onChange={setLocalDuration}
                        onClear={() => setLocalDuration("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Payment Frequency Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="units-filter-payment-frequency-selection"
                        label={t("labels.payment_frequency")}
                        placeholder={t("placeholders.select")}
                        value={localPaymentFrequency}
                        onChange={setLocalPaymentFrequency}
                        options={paymentFrequencySwitchOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default LeaseFilters;
