import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ITenant } from "../services/tenantService";

interface TenantDetailState {
  tenant: ITenant | null;
  isLoading: boolean;
  isDeleting: boolean;
  setTenant: (tenant: ITenant) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsDeleting: (isDeleting: boolean) => void;
  reset: () => void;
}

const useTenantDetailStore = create<TenantDetailState>()(
  devtools(
    persist(
      (set) => ({
        tenant: null,
        isLoading: true,
        isDeleting: false,
        setTenant: (tenant: ITenant) => set({ tenant }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsDeleting: (isDeleting: boolean) => set({ isDeleting }),
        reset: () => {
          set({ tenant: null, isLoading: true });
        },
      }),
      {
        name: "tenant-detail-storage",
      }
    )
  )
);

export default useTenantDetailStore;
