import useSWRInfinite from 'swr/infinite';
import { AuditLogSortBy, IAuditLog, constructAuditLogsUrl, fetchAuditLogs } from '../services/auditLogService';
import { SortOrder } from '../@types';

const PAGE_SIZE = 20;

export const useAuditLogs = (
    action?: string,
    start_date?: string,
    end_date?: string,
    time?: string,
    entity_name?: string,
    entity_id?: string,
    employee_id?: string,
    sortBy?: AuditLogSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchAuditLogs(url);

    const getKey = (pageIndex: number, previousPageData: IAuditLog[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructAuditLogsUrl(
            pageIndex + 1,
            PAGE_SIZE,
            action,
            start_date,
            end_date,
            time,
            entity_name,
            entity_id,
            employee_id,
            sortBy,
            sortOrder
        );
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IAuditLog[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        audit_logs: data ? ([] as IAuditLog[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
