import "./pie-chart.css";

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
    colors: string[];
    total: number;
    active: number;
    tooltip?: boolean;
}

const PieChart: React.FC<PieChartProps> = ({ colors, total, active, tooltip = false }) => {
    const data = {
        labels: ['Aktiv', 'Inaktiv'],
        datasets: [
            {
                label: 'Aktive Investoren',
                data: [active, total],
                backgroundColor: colors,
                borderWidth: 0,
                cutout: '80%',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: tooltip,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div style={{ position: 'relative' }}>
            <Doughnut data={data} options={options} />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                backgroundColor: colors[0],
                color: "white",
                height: '80px',
                width: '80px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{active}</div>
            </div>
        </div>
    );
};

export default PieChart;
