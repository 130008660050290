import './custom-rich-text-field.css';
import 'react-quill/dist/quill.snow.css';

import React from 'react';
import ReactQuill from 'react-quill';

interface CustomRichTextFieldProps {
    id: string;
    label: string;
    placeholder?: string;
    value: string;
    errorMessage?: string;
    required?: boolean;
    onChange: (value: string) => void;
}

const CustomRichTextField: React.FC<CustomRichTextFieldProps> = ({ id, label, placeholder, value, errorMessage, required = false, onChange }) => {
    return (
        <>
            <div className="rich-text-field">
                <label htmlFor={id}>
                    {label}
                    {required && (<span className="text-red ms-1">*</span>)}
                </label>
                <ReactQuill
                    className="react-quill"
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    modules={modules}
                    formats={formats}
                />
            </div>
            {errorMessage && (
                <small className="ms-2 text-danger">{errorMessage}</small>
            )}
        </>
    );
};

// Specify the necessary toolbar options
const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link']
    ],
};

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

export default CustomRichTextField;
