import './custom-phone-input.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsXCircle } from 'react-icons/bs';
import PhoneInputWithCountrySelect, { Country, Value } from 'react-phone-number-input';
import countries from 'i18n-iso-countries';

type Props = {
    id: string;
    className?: string;
    value: Value | undefined;
    defaultCountry?: Country;
    errorMessage?: string;
    labelOnTop?: boolean;
    disabled?: boolean;
    required?: boolean;
    onChange: (number: string) => void;
    onClear: () => void;
};

const CustomPhoneInput: React.FC<Props> = ({
    id,
    className = "",
    value,
    defaultCountry,
    errorMessage,
    labelOnTop = false,
    disabled = false,
    required = false,
    onChange,
    onClear
}) => {
    const { t, i18n } = useTranslation();
    const [countryLabels, setCountryLabels] = useState<{ [key: string]: string }>({});

    // Load the country names based on the current language
    useEffect(() => {
        countries.registerLocale(require(`i18n-iso-countries/langs/${i18n.language}.json`));

        const getCountryNames = () => {
            const names = countries.getNames(i18n.language);
            setCountryLabels(names);
        };

        getCountryNames();
    }, [i18n.language]);

    const labelClasses = labelOnTop ? 'phone-input-label-on-top' : 'col-sm-5 col-form-label';
    const inputWrapperClasses = labelOnTop ? 'phone-input-wrapper-on-top' : 'col-sm-7';

    return (
        <div className={`phone-input-field ${labelOnTop ? '' : 'row'} ${className}`}>
            <label htmlFor={id} className={labelClasses}>
                {t("labels.phone_number")}
                {required && <span className="text-red ms-1">*</span>}
            </label>
            <div className={inputWrapperClasses}>
                <div className="d-flex align-items-center" style={labelOnTop ? { height: 50 } : {}}>
                    <div className={`input-wrapper ${disabled ? 'disabled' : ''}`}>
                        <PhoneInputWithCountrySelect
                            id={id}
                            defaultCountry={defaultCountry}
                            value={value}
                            onChange={onChange}
                            className="phone-input"
                            disabled={disabled}
                            labels={countryLabels} // Provide the country labels for localization
                        />
                        {value && onClear && !disabled && (
                            <BsXCircle className="clear-icon" onClick={onClear} />
                        )}
                    </div>
                </div>
            </div>
            {errorMessage && (
                <div className="ms-2 error-message">{errorMessage}</div>
            )}
        </div>
    );
};

export default CustomPhoneInput;
