import './investor-status-badge.css';

import React from 'react';

interface InvestorStatusBadgeProps {
    className?: string;
    status: string;
}

const InvestorStatusBadge: React.FC<InvestorStatusBadgeProps> = ({ className, status }) => {
    return (
        <span className={`investor-status-badge ${status.toLowerCase()} ${className ?? ""}`}>
            {status}
        </span>
    );
};

export default InvestorStatusBadge;
