import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { InvestorSortBy } from "../services/investorService";
import { SortOrder } from "../@types";

interface InvestorFilterState {
  salesAgent: string;
  ownedUnits: string;
  totalPurchasedValue: string;
  sortColumn: InvestorSortBy | null;
  sortDirection: SortOrder | null;
  selectedState: number | null;
  isExpanded: boolean;
  setFilters: (
    salesAgent: string,
    ownedUnits: string,
    totalPurchasedValue: string
  ) => void;
  setSort: (column: InvestorSortBy, direction: SortOrder) => void;
  setSelectedState: (state: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useInvestorFilterStore = create<InvestorFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        salesAgent: "",
        ownedUnits: "",
        totalPurchasedValue: "",
        sortColumn: null,
        sortDirection: null,
        selectedState: null,
        isExpanded: false,
        setFilters: (salesAgent, ownedUnits, totalPurchasedValue) =>
          set({ salesAgent, ownedUnits, totalPurchasedValue }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setSelectedState: (selectedState) => set({ selectedState }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "investor-filter-storage",
      }
    )
  )
);

export default useInvestorFilterStore;
