import './custom-input-field.css';

import React, { CSSProperties, useState } from 'react';
import { BsEye, BsEyeSlash, BsXCircle } from 'react-icons/bs';

interface CustomInputFieldProps {
    className?: string;
    id: string;
    label: string;
    placeholder?: string;
    maxLength?: number;
    value: string;
    onClear?: () => void;
    onChange: (value: string) => void;
    type?: 'text' | 'email' | 'password' | 'number';
    required?: boolean;
    disabled?: boolean;
    inputStyle?: CSSProperties | undefined;
    errorMessage?: string;
    isTextArea?: boolean;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
    className,
    id,
    label,
    placeholder,
    maxLength,
    value,
    onChange,
    onClear,
    type = 'text',
    required = false,
    disabled = false,
    inputStyle,
    errorMessage,
    isTextArea = false,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={`input-field ${className}`}>
            <label htmlFor={id}>
                {label}
                {required && <span className="text-red ms-1">*</span>}
            </label>
            <div className="input-wrapper">
                {isTextArea ? (
                    <textarea
                        style={inputStyle}
                        id={id}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        required={required}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        style={inputStyle}
                        id={id}
                        type={showPassword ? 'text' : type}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        required={required}
                        disabled={disabled}
                    />
                )}

                <div className="icon-container">
                    {type === 'password' && (
                        <div className="icon" onClick={togglePasswordVisibility}>
                            {showPassword ? <BsEye /> : <BsEyeSlash />}
                        </div>
                    )}
                    {value && onClear && !disabled && (
                        <BsXCircle className="icon" onClick={onClear} />
                    )}
                </div>
            </div>
            {errorMessage && (
                <small className="ms-2 text-danger">{errorMessage}</small>
            )}
        </div>
    );
};

export default CustomInputField;
