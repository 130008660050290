import './lease-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILease, LeaseSortBy, exportLeaseCsv } from '../../services/leaseService';
import { calculateDurationInMonths, formatMoney } from '../../utils/helpers';
import CustomButton from '../custom-button/custom-button';
import { PaymentPurpose } from '../../services/leasePaymentService';
import { SortOrder } from '../../@types';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

interface LeaseTableProps {
    leases: ILease[];
    selectedSortColumn: LeaseSortBy | null;
    selectedSortDirection: SortOrder | null;
    onSortChange: (sortColumn: LeaseSortBy, sortDirection: SortOrder) => void;
    onClickLease: (lease_id: number) => void;
}

const LeaseTable: React.FC<LeaseTableProps> = ({ leases, selectedSortColumn, selectedSortDirection, onSortChange, onClickLease }) => {
    const { t } = useTranslation();

    // State management
    const [selectedLeases, setSelectedLeases] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSort = (column: LeaseSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleCheckboxChange = (leaseId: number) => {
        setSelectedLeases(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(leaseId)) {
                newSelected.delete(leaseId);
            } else {
                newSelected.add(leaseId);
            }
            return newSelected;
        });
    };

    const handleExport = async () => {
        const selected = Array.from(selectedLeases);
        setIsLoading(true);
        try {
            const blob = await exportLeaseCsv(selected);

            // Generate current date and time string
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // yyyy-mm-dd
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-"); // hh-mm-ss    

            const fileName = `leases_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset selected leases
            setSelectedLeases(new Set());
        } catch (error) {
            console.error("Error exporting leases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getPaymentFrequency = (paymentCount: number): string => {
        switch (paymentCount) {
            case 1:
                return t("labels.yearly");
            case 2:
                return t("labels.biannual");
            case 4:
                return t("labels.quarterly");
            case 12:
                return t("labels.monthly");
            case 0:
                return "-";
            default:
                return paymentCount.toString();
        }
    };

    return (
        <div>
            {selectedLeases.size > 0 && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center ms-auto">
                        <span className="me-3">{selectedLeases.size} {t("labels.selected")}</span>
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    </div>
                </div>
            )}
            {leases.length > 0 ? (
                <div className="lease-table-responsive">
                    <table className="lease-table">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedLeases(new Set(leases.map(l => l.lease_id)));
                                            } else {
                                                setSelectedLeases(new Set());
                                            }
                                        }}
                                        checked={selectedLeases.size === leases.length}
                                    />
                                </th>
                                <th onClick={() => handleSort(LeaseSortBy.TENANT)} style={{ cursor: 'pointer' }}>
                                    {t("lease_table.tenant_name_header")}
                                    {selectedSortColumn === LeaseSortBy.TENANT ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th onClick={() => handleSort(LeaseSortBy.UNIT_NUMBER)} style={{ cursor: 'pointer' }}>
                                    {t("lease_table.unit_header")}
                                    {selectedSortColumn === LeaseSortBy.UNIT_NUMBER ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th onClick={() => handleSort(LeaseSortBy.RENT)} style={{ cursor: 'pointer' }}>
                                    {t("lease_table.rent_header")} (AED)
                                    {selectedSortColumn === LeaseSortBy.RENT ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th onClick={() => handleSort(LeaseSortBy.SECURITY_DEPOSIT)} style={{ cursor: 'pointer' }}>
                                    {t("lease_table.deposit_header")} (AED)
                                    {selectedSortColumn === LeaseSortBy.SECURITY_DEPOSIT ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th>{t("labels.payment_frequency")}</th>
                                <th>{t("lease_table.duration_header")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leases.map(lease => (
                                <tr key={lease.lease_id} className="aktiv" onClick={() => onClickLease(lease.lease_id)}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedLeases.has(lease.lease_id)}
                                            onChange={() => handleCheckboxChange(lease.lease_id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </td>
                                    <td>{lease.tenant.first_name} {lease.tenant.last_name}</td>
                                    <td>{lease.unit.unit_number}</td>
                                    <td>
                                        <span className="total-rent-amount-badge">
                                            {formatMoney(lease.total_rent_amount)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="deposit-amount-badge">
                                            {formatMoney(lease.deposit_amount)}
                                        </span>
                                    </td>
                                    <td>{getPaymentFrequency(lease.payments.filter(payment => payment.purpose === PaymentPurpose.RENT).length)}</td>
                                    <td>
                                        {t("lease_table.duration_name", { duration: calculateDurationInMonths(lease.start_date, lease.end_date).toFixed(0) })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-center my-0">{t("pages.leases.empty_message")}</p>
            )}
        </div>
    );
};

export default LeaseTable;
