import './upcoming-units.css';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ILease } from '../../../../services/leaseService';
import { formatMoney } from '../../../../utils/helpers';

interface UpcomingUnitsProps {
    leases: ILease[];
}

const UpcomingUnits: React.FC<UpcomingUnitsProps> = ({ leases }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onClickUnit = (unitId: number) => {
        navigate(`/units/${unitId}`);
    }

    const addOneDay = (dateString: string): string => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    return (
        <div className="upcoming-units">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="title">{t("pages.property_detail.soon_available_units.title")}</h5>
                <p className="month-badge">{t("pages.property_detail.soon_available_units.badge")}</p>
            </div>
            {isLoading ? (
                <div className="available-units-table-responsive">
                    <table className="available-units-table">
                        <thead>
                            <tr>
                                <th>{t("unit_table.name_header")}</th>
                                <th>{t("unit_table.bedroom_header")}</th>
                                <th>{t("unit_table.bathroom_header")}</th>
                                <th>{t("unit_table.minimum_annual_rent_header")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(5)].map((_, idx) => (
                                <tr key={idx}>
                                    <td><span className="skeleton unit-id"></span></td>
                                    <td><span className="skeleton bedroom"></span></td>
                                    <td><span className="skeleton bathroom"></span></td>
                                    <td><span className="skeleton rent"></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                leases.length > 0 ? (
                    <div className="upcoming-units-table-responsive">
                        <table className="upcoming-units-table">
                            <thead>
                                <tr>
                                    <th>{t("unit_table.name_header")}</th>
                                    <th>{t("unit_table.bedroom_header")}</th>
                                    <th>{t("unit_table.bathroom_header")}</th>
                                    <th>{t("unit_table.minimum_annual_rent_header")}</th>
                                    <th>Available from</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leases.map(lease => (
                                    <tr key={lease.unit.unit_id} onClick={() => onClickUnit(lease.unit.unit_id)}>
                                        <td>{lease.unit.unit_id}</td>
                                        <td>{lease.unit.bedroom}</td>
                                        <td>{lease.unit.bathroom}</td>
                                        <td>
                                            <span className="total-rent-amount-badge">
                                                {lease.unit.minimum_annual_rent ? formatMoney(lease.unit.minimum_annual_rent) : "-"}
                                            </span>
                                        </td>
                                        <td>
                                            {t("pages.property_detail.soon_available_units.available_from", { date: addOneDay(lease.end_date) })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="upcoming-units-card">
                        <p className="no-units-text">{t("pages.property_detail.soon_available_units.no_units_text")}</p>
                    </div>
                )
            )}
        </div>
    );
};

export default UpcomingUnits;
