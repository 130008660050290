import useSWRInfinite from 'swr/infinite';
import { ITenant, TenantSortBy, constructTenantsUrl, fetchTenants } from '../services/tenantService';
import { SortOrder } from '../@types';

const PAGE_SIZE = 20;

export const useTenants = (
    propertyId: number | null,
    search: string,
    active?: boolean,
    sortBy?: TenantSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchTenants(url);

    const getKey = (pageIndex: number, previousPageData: ITenant[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructTenantsUrl(propertyId, search, pageIndex + 1, PAGE_SIZE, active, sortBy, sortOrder);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<ITenant[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        tenants: data ? ([] as ITenant[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
