import { IoConstructOutline } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

export const PropertyCardWrapper = styled.div<{ deactivated?: boolean }>`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
  position: relative;
  height: 100%;
  cursor: ${({ deactivated }) => (deactivated ? "not-allowed" : "pointer")};
  transform: ${({ deactivated }) => (deactivated ? "none" : "scale(1)")};

  &:hover {
    transform: ${({ deactivated }) => (deactivated ? "none" : "scale(0.97)")};
  }
`;

export const PropertyImageWrapper = styled.div`
  background-size: cover !important;
  background-position: center !important;
  height: 150px;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
`;

export const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: block;
  position: relative;
`;

export const PropertyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const ConstructionIcon = styled(IoConstructOutline)`
  color: #fff;
  font-size: 40px;
  z-index: 2;
`;

export const PropertyContent = styled.div`
  padding: 16px;
`;

export const PropertyTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

export const PropertyLocationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PropertyLocation = styled.a`
  font-size: 12px;
  color: #6b7280;
  text-decoration: none;
  margin-left: 8px;
`;

export const PropertyMapIcon = styled.div`
  color: #6b7280;
`;
