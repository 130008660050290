// MaterialIcon.tsx
import { MaterialSymbol } from 'material-symbols';
import React from 'react';

interface MaterialIconProps {
    icon: MaterialSymbol;
    style?: 'outlined' | 'round' | 'sharp' | 'two-tone' | 'filled';
    size?: number | string;
    color?: string;
    marginRight?: string;
    marginLeft?: string;
    zIndex?: number;
    onClick?: () => void | undefined;
}

const MaterialIcon: React.FC<MaterialIconProps> = ({
    icon,
    style = 'outlined',
    size = 24,
    color = 'inherit',
    marginRight = '0px',
    marginLeft = '0px',
    zIndex,
    onClick
}) => {
    return (
        <span
            className={`material-symbols-${style}`}
            style={{
                fontSize: size,
                color,
                marginRight,
                marginLeft,
                cursor: onClick ? "pointer" : "inherit",
                zIndex: zIndex
            }}
            onClick={onClick}
        >
            {icon}
        </span>
    );
};

export default MaterialIcon;
