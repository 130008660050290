// NewsPage.tsx

import { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useSearchStore from "../../stores/searchStore";
import { useTranslation } from "react-i18next";
import useAlertStore from "../../stores/alertStore";
import useDebounce from "../../hooks/useDebounce";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { useNews } from "../../hooks/useNews";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import NewsList from "./components/news-list";
import NewsModal from "../../modals/news-modal/news-modal";
import { deleteNews, INews } from "../../services/newsService";

const NewsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.news.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedNews, setSelectedNews] = useState<INews | null>(null);
    const [isDeleteNewsModalVisible, setIsDeleteNewsModalVisible] = useState<number | null>(null);
    const [isNewsModalVisible, setIsNewsModalVisible] = useState<boolean>(false);

    const debouncedKeyword = useDebounce(search, 500);
    const {
        news,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useNews(debouncedKeyword);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const onClickDeleteNews = useCallback(async () => {
        if (isDeleteNewsModalVisible) {
            try {
                const news_id: number = isDeleteNewsModalVisible;
                setIsDeleting(true);
                const deleted = await deleteNews(news_id);
                if (deleted) {
                    mutate(
                        (data: INews[][] | undefined) => {
                            if (!data) return [];
                            return data.map((page) =>
                                page.filter((emp) => emp.news_id !== news_id)
                            );
                        },
                        false
                    );
                    showAlert("success", t("modals.delete_employee.success_message"));
                    setIsDeleteNewsModalVisible(null);
                }
            } catch (error) {
                console.log("Error while deleting news: ", error);
            } finally {
                setIsDeleting(false);
            }
        }
    }, [isDeleteNewsModalVisible]);

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">({news.length}) {t("pages.news.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.news.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                <div className="d-flex d-none d-sm-inline">
                    <CustomButton
                        title={t("pages.news.create_news_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => {
                            setSelectedNews(null);
                            setIsNewsModalVisible(true);
                        }}
                    />
                </div>
            </div>

            {/* MOBILE BUTTONS */}
            <div className="d-flex d-sm-none mt-3">
                <CustomButton
                    title={t("pages.news.create_news_button")}
                    icon={BsPlus}
                    isLoading={false}
                    onClick={() => {
                        setSelectedNews(null);
                        setIsNewsModalVisible(true);
                    }}
                />
            </div>

            {/* NEWS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <div className="mt-4">
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={news.length}
                        next={loadMore}
                        hasMore={!isReachingEnd}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <NewsList
                            news={news}
                            onClickNews={(news) => {
                                setSelectedNews(news);
                                setIsNewsModalVisible(true);
                            }}
                            onDeleteNews={setIsDeleteNewsModalVisible}
                        />
                    </InfiniteScroll>
                </div>
            )}

            {isDeleteNewsModalVisible && (
                <DeleteConfirmationModal
                    title={t("pages.news.delete_modal.title")}
                    message={t("pages.news.delete_modal.message")}
                    onClose={() => setIsDeleteNewsModalVisible(null)}
                    onDelete={onClickDeleteNews}
                    isLoading={isDeleting}
                />
            )}

            {isNewsModalVisible && (
                <NewsModal
                    news={selectedNews ?? undefined}
                    onClose={() => {
                        setIsNewsModalVisible(false);
                        setSelectedNews(null);
                    }}
                    onNewsCreated={(news) => {
                        mutate(
                            (data: INews[][] | undefined) => {
                                if (!data) return [];
                                return [
                                    [news, ...data[0]],
                                    ...data.slice(1)
                                ];
                            },
                            false
                        );
                        setIsNewsModalVisible(false);
                        setSelectedNews(null);
                    }}
                    onNewsUpdated={(news) => {
                        mutate(
                            (data: INews[][] | undefined) => {
                                if (!data) return [];
                                return data.map((page) =>
                                    page.map((item) =>
                                        item.news_id === news.news_id ? news : item
                                    )
                                );
                            },
                            false
                        );
                        setIsNewsModalVisible(false);
                        setSelectedNews(null);
                    }}
                />
            )}
        </div>
    );
};

export default NewsPage;
