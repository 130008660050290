import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";
import { AuditLogSortBy } from "../services/auditLogService";

interface AuditLogFilterState {
  start_date: string;
  end_date: string;
  time: string;
  entity_id: string;
  entity_name: string;
  employee_id: string;
  sortColumn: AuditLogSortBy | null;
  sortDirection: SortOrder | null;
  selectedAction: string | null;
  isExpanded: boolean;
  setFilters: (
    start_date: string,
    end_date: string,
    time: string,
    entity_id: string,
    entity_name: string,
    employee_id: string
  ) => void;
  setSort: (column: AuditLogSortBy, direction: SortOrder) => void;
  setSelectedAction: (action: string | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useAuditLogFilterStore = create<AuditLogFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        start_date: "",
        end_date: "",
        time: "",
        entity_id: "",
        entity_name: "",
        employee_id: "",
        sortColumn: null,
        sortDirection: null,
        selectedAction: null,
        isExpanded: false,
        setFilters: (
          start_date,
          end_date,
          time,
          entity_id,
          entity_name,
          employee_id
        ) =>
          set({
            start_date,
            end_date,
            time,
            entity_id,
            entity_name,
            employee_id,
          }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setSelectedAction: (selectedAction) => set({ selectedAction }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "audit-log-filter-storage",
      }
    )
  )
);

export default useAuditLogFilterStore;
