import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import SidebarSelectField from '../components/sidebar-select-field/sidebar-select-field';
import { IInvestor, createInvestor, uploadInvestorDocuments } from '../../../services/investorService';
import { fetchSalesAgents } from '../../../services/salesAgentService';
import { Value } from 'react-phone-number-input';
import CustomPhoneInput from '../../../components/custom-phone-input/custom-phone-input';
import AddressForm from '../../../components/address-form/address-form';
import { IAddress, updateAddress } from '../../../services/addressService';
import useAlertStore from '../../../stores/alertStore';
import { INVESTOR_STATUS_OPTIONS } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import { DocumentType } from '../../../services/documentService';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';

interface InvestorSidebarModalProps {
    isVisible: boolean;
    onClose: () => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const InvestorSidebarModal: React.FC<InvestorSidebarModalProps> = ({ isVisible, onClose, mutate }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [status, setStatus] = useState<string>("1");
    const [salesAgent, setSalesAgent] = useState<string>("");
    const [salesAgents, setSalesAgents] = useState<{ label: string; value: string; }[]>([]);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [passportFiles, setPassportFiles] = useState<UploadedFile[]>([]);
    const [proofOfAddressFile, setProofOfAddressFile] = useState<UploadedFile | null>(null);
    const [firstnameError, setFirstnameError] = useState<string>("");
    const [lastnameError, setLastnamError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<Value | undefined>(undefined);
    const [address, setAddress] = useState<IAddress>({ address_id: 0 } as IAddress);

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setFirstname("");
            setLastname("");
            setEmail("");
            setStatus("1");
            setSalesAgent("");
            setPhoneNumber("");
            setFirstnameError("");
            setLastnamError("");
            setEmailError("");
            setPhoneNumberError("");
            setError("");
            setFiles([]);
        } else {
            const loadSalesAgents = async () => {
                try {
                    const agents = await fetchSalesAgents();
                    setSalesAgents(agents.map(agent => {
                        return { label: `${agent.first_name} ${agent.last_name}`, value: agent.employee_id.toString() }
                    }));
                    setSalesAgent(salesAgents.length > 0 ? salesAgents[0].value : "");
                } catch (error) {
                    // @ts-ignore
                    setError(error.message);
                }
            }
            loadSalesAgents();
        }
    }, [isVisible, salesAgents]);

    const onFileSelected = (selectedFiles: UploadedFile[]) => {
        setFiles(selectedFiles);
    };

    const onHandleCreateInvestor = useCallback(async () => {
        let isValid = true;

        // Check if the firstname is not empty
        if (!firstname.trim()) {
            setFirstnameError(t("invalid_input.firstname_empty"));
            isValid = false;
        }

        // Check if the lastname is not empty
        if (!lastname.trim()) {
            setLastnamError(t("invalid_input.lastname_empty"));
            isValid = false;
        }

        // Basic email validation
        if (!email.trim()) {
            setEmailError(t("invalid_input.email_empty"));
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
        }

        // Check if the phone number is not empty
        if (!phoneNumber?.trim()) {
            setPhoneNumberError(t("invalid_input.phone_number_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setError("");
                setIsLoading(true);
                const createdInvestor = await createInvestor(firstname, lastname, email, phoneNumber ?? "", Number(salesAgent), Number(status));
                await updateAddress({ ...address, address_id: createdInvestor.address.address_id });

                // Upload files
                if (files.length > 0) {
                    await uploadInvestorDocuments(
                        createdInvestor.investor_id,
                        files.map(uploadedFiles => uploadedFiles.file),
                        null,
                        [],
                        files.map(file => file.internal_document ? "true" : "false")
                    );
                }

                if (passportFiles.length > 0) {
                    await uploadInvestorDocuments(
                        createdInvestor.investor_id,
                        passportFiles.map(file => file.file),
                        DocumentType.PASSPORT,
                        passportFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                    );
                }

                if (proofOfAddressFile) {
                    await uploadInvestorDocuments(
                        createdInvestor.investor_id,
                        [proofOfAddressFile.file],
                        DocumentType.PROOF_OF_ADDRESS
                    );
                }

                mutate(
                    (data: IInvestor[][]) => {
                        if (!data) return [];
                        return [
                            [createdInvestor, ...data[0]],
                            ...data.slice(1)
                        ];
                    },
                    false
                );

                onClose();
                showAlert("success", t("modals.create_investor.create_investor_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [salesAgent, status, firstname, lastname, email, phoneNumber, address, files, passportFiles, proofOfAddressFile, mutate, onClose, showAlert, t]);

    const onPassportFileSelected = (selectedFiles: UploadedFile[]) => {
        setPassportFiles(selectedFiles);
    };

    const onProofOfAddressFileSelected = (selectedFiles: UploadedFile[]) => {
        setProofOfAddressFile(selectedFiles[0]);
    };

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.create_investor.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="firstname"
                                    type="text"
                                    label={t("labels.firstname")}
                                    placeholder="Max"
                                    value={firstname}
                                    errorMessage={firstnameError}
                                    onClear={() => setFirstname("")}
                                    onChange={(value: string) => {
                                        setFirstname(value);
                                        setFirstnameError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="lastname"
                                    type="text"
                                    label={t("labels.lastname")}
                                    placeholder="Mustermann"
                                    value={lastname}
                                    errorMessage={lastnameError}
                                    onClear={() => setLastname("")}
                                    onChange={(value: string) => {
                                        setLastname(value);
                                        setLastnamError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="email"
                                    type="email"
                                    label={t("labels.email")}
                                    placeholder="investor@email.com"
                                    value={email}
                                    errorMessage={emailError}
                                    onClear={() => setEmail("")}
                                    onChange={(value: string) => {
                                        setEmail(value);
                                        setEmailError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <CustomPhoneInput
                                    id="phone-number"
                                    defaultCountry='DE'
                                    value={phoneNumber}
                                    errorMessage={phoneNumberError}
                                    onChange={setPhoneNumber}
                                    onClear={() => {
                                        setPhoneNumber(undefined);
                                        setPhoneNumberError("");
                                    }}
                                    labelOnTop
                                    required
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarSelectField
                                    id="status"
                                    label={t("labels.status")}
                                    value={status}
                                    onChange={setStatus}
                                    options={INVESTOR_STATUS_OPTIONS}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarSelectField
                                    id="agent"
                                    label={t("labels.sales_agent")}
                                    placeholder={t("placeholders.select")}
                                    value={salesAgent}
                                    onChange={setSalesAgent}
                                    options={salesAgents}
                                    labelOnTop
                                />
                            </div>
                        </div>
                        <div className="sidebar-modal-divider mb-4" />
                        <ExpandableSection title={t("modals.create_investor.address")} className="mb-4">
                            <AddressForm
                                address={address}
                                onAddressChanged={setAddress}
                            />
                        </ExpandableSection>

                        {/* UPLOAD PASSPORT SECTION */}
                        <FileUploadSection
                            fileUploadId="create-investor-upload-passport"
                            title={t("modals.create_investor.upload_passport")}
                            documents={[]}
                            uploadedFiles={passportFiles}
                            onUpdateUploadedFiles={onPassportFileSelected}
                            maxFilesToUpload={2}
                            allowExpirationDate
                            isHeadlineLarge={false}
                            removePadding
                            hasEditPermission
                        />

                        {/* UPLOAD PROOF OF ADDRESS SECTION */}
                        <FileUploadSection
                            fileUploadId="create-investor-upload-proof-of-address"
                            title={t("modals.create_investor.upload_proof_of_address")}
                            documents={[]}
                            uploadedFiles={proofOfAddressFile ? [proofOfAddressFile] : []}
                            onUpdateUploadedFiles={onProofOfAddressFileSelected}
                            maxFilesToUpload={1}
                            isHeadlineLarge={false}
                            removePadding
                            hasEditPermission
                        />

                        {/* UPLOAD OTHER DOCUMENTS SECTION */}
                        <FileUploadSection
                            fileUploadId="create-investor-upload-other-documents"
                            title={t("modals.create_investor.upload_documents")}
                            documents={[]}
                            uploadedFiles={files}
                            onUpdateUploadedFiles={onFileSelected}
                            allowInternalDocument
                            isHeadlineLarge={false}
                            removePadding
                            hasEditPermission
                        />
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("modals.create_investor.create_investor_button")}
                            isLoading={isLoading}
                            onClick={onHandleCreateInvestor}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvestorSidebarModal;
