// TenantsPage.tsx
import { useEffect, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useSearchStore from "../../stores/searchStore";
import { useTranslation } from "react-i18next";
import TenantTable from "./components/tenant-table/tenant-table";
import TenantSidebarModal from "../../modals/sidebar/tenant-sidemodal/tenant-sidemodal";
import { useTenants } from "../../hooks/useTenants";
import useAlertStore from "../../stores/alertStore";
import useDebounce from "../../hooks/useDebounce";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import TenantFilters from "./components/tenant-filters/tenant-filters";
import useTenantFilterStore from "../../stores/tenantFilterStore";

const TenantsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.tenants.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        active,
        selectedProperty,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedProperty,
    } = useTenantFilterStore();

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const {
        tenants,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useTenants(selectedProperty, debouncedKeyword, active);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.tenants.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.tenants.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                <div className="d-flex d-none d-sm-inline">
                    <CustomButton
                        title={t("pages.tenants.create_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            </div>

            {/* MOBILE BUTTONS */}
            <div className="d-flex d-sm-none mt-3">
                <CustomButton
                    title={t("pages.tenants.create_button")}
                    icon={BsPlus}
                    isLoading={false}
                    onClick={() => setIsSidebarModalVisible(true)}
                />
            </div>

            {/* INVESTORS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <TenantFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={tenants.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <TenantTable tenants={tenants} />
                        </InfiniteScroll>
                    </div>
                </>
            )}

            <TenantSidebarModal
                isVisible={isSidebarModalVisible}
                onClose={() => setIsSidebarModalVisible(false)}
                mutate={mutate}
            />
        </div>
    );
};

export default TenantsPage;
