import { useTranslation } from 'react-i18next';
import { formatUnitStatus, UnitStatus } from '../../services/unitService';
import './unit-status-badge.css';

import React from 'react';

interface UnitStatusBadgeProps {
    className?: string;
    status: UnitStatus;
}

const UnitStatusBadge: React.FC<UnitStatusBadgeProps> = ({ className, status }) => {
    const { t } = useTranslation();
    
    return (
        <span className={`unit-status-badge ${status.toLowerCase()} ${className ?? ""}`}>
            {formatUnitStatus(status, t)}
        </span>
    );
};

export default UnitStatusBadge;
