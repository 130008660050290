import './employee-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEmployee } from '../../services/employeeService';
import { censorEmail, censorPhoneNumber } from '../../utils/helpers';

interface EmployeeTableProps {
    employees: IEmployee[];
    onClickEmployee: (employee_id: number) => void;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({ employees, onClickEmployee }) => {
    const { t } = useTranslation();

    // States
    const [privacyMode, setPrivacyMode] = useState<boolean>(true);

    return (
        <div>
            {employees.length > 0 ? (
                <>
                    <label className="form-switch ms-2 mb-3">
                        <input className="form-check-input" type="checkbox" checked={privacyMode} onChange={() => setPrivacyMode(!privacyMode)} />
                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">{t("labels.privacy_mode_switch")}</label>
                    </label>
                    <div className="employee-table-responsive">
                        <table className="employee-table">
                            <thead>
                                <tr>
                                    <th>{t("investor_table.name_header")}</th>
                                    <th className="d-none d-md-table-cell">{t("investor_table.email_header")}</th>
                                    <th className="d-none d-md-table-cell">{t("investor_table.phone_number_header")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map(employee => (
                                    <tr key={employee.employee_id} className="aktiv" onClick={() => onClickEmployee(employee.employee_id)}>
                                        <td>{employee.first_name} {employee.last_name}</td>
                                        <td className="d-none d-md-table-cell">
                                            <div className="email-info">
                                                {privacyMode ? censorEmail(employee.email) : employee.email}
                                            </div>
                                        </td>
                                        {/* Hide the following columns on xs to md screens */}
                                        <td className="d-none d-md-table-cell">{privacyMode ? censorPhoneNumber(employee.phone_number ?? undefined) : employee.phone_number ?? "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <p className="text-center my-0">Keine Daten vorhanden.</p>
            )}
        </div>
    );
};

export default EmployeeTable;
