import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ConstructionUpdateState {
  selectedPropertyId: number;
  setSelectedPropertyId: (propertyId: number) => void;
  reset: () => void;
}

const useConstructionUpdateStore = create<ConstructionUpdateState>()(
  devtools(
    persist(
      (set) => ({
        selectedPropertyId: 3,
        setSelectedPropertyId: (propertyId: number) =>
          set({ selectedPropertyId: propertyId }),
        reset: () => {
          set({
            selectedPropertyId: 3,
          });
        },
      }),
      {
        name: "construction-update-storage",
      }
    )
  )
);

export default useConstructionUpdateStore;
