import './audit-logs-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAuditLog, AuditLogAction, AuditLogSortBy } from '../../../../services/auditLogService';
import { format, parseISO } from 'date-fns';
import { FaCheckCircle, FaSort, FaSortDown, FaSortUp, FaTimesCircle } from 'react-icons/fa';
import { SortOrder } from '../../../../@types';

interface AuditLogsTableProps {
    auditLogs: IAuditLog[];
    selectedSortColumn: AuditLogSortBy | null;
    selectedSortDirection: SortOrder | null;
    onSortChange: (sortColumn: AuditLogSortBy, sortDirection: SortOrder) => void;
}

const AuditLogsTable: React.FC<AuditLogsTableProps> = ({ auditLogs, selectedSortColumn, selectedSortDirection, onSortChange }) => {
    const { t } = useTranslation();

    // States
    const [expandedLog, setExpandedLog] = useState<number | null>(null);

    const handleSort = (column: AuditLogSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleRowClick = (logId: number) => {
        setExpandedLog(expandedLog === logId ? null : logId);
    };

    function renderValue(value: any): string {
        if (value === null || value === undefined) {
            return 'N/A';
        }
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2);
        }
        return value.toString();
    }

    const renderChanges = (oldValue: any, newValue: any, action: AuditLogAction): JSX.Element[] => {
        let changes: JSX.Element[] = [];

        switch (action) {
            case AuditLogAction.UPDATE:
                changes = Object.keys(newValue).map(key => {
                    if (newValue[key] !== oldValue[key]) {
                        return (
                            <div key={key} className="change-item">
                                <div className="change-key">
                                    <strong>{key}:</strong>
                                </div>
                                <div className="change-value">
                                    <span className="old-value"><FaTimesCircle /> {renderValue(oldValue[key])}</span>
                                    <span className="arrow"> → </span>
                                    <span className="new-value"><FaCheckCircle /> {renderValue(newValue[key])}</span>
                                </div>
                            </div>
                        );
                    }
                    return null;
                }).filter(item => item !== null) as JSX.Element[];
                break;
            case AuditLogAction.DELETE:
                changes = Object.keys(oldValue).map(key => (
                    <div key={key} className="change-item">
                        <div className="change-key">
                            <strong>{key}:</strong>
                        </div>
                        <div className="change-value">
                            <span className="old-value"><FaTimesCircle /> {renderValue(oldValue[key])}</span>
                        </div>
                    </div>
                ));
                break;
            case AuditLogAction.CREATE:
                changes = Object.keys(newValue).map(key => (
                    <div key={key} className="change-item">
                        <div className="change-key">
                            <strong>{key}:</strong>
                        </div>
                        <div className="change-value">
                            <span className="new-value"><FaCheckCircle /> {renderValue(newValue[key])}</span>
                        </div>
                    </div>
                ));
                break;
        }

        if (changes.length > 0) {
            return changes;
        } else {
            return [<p key="no_changes">{t("audit_logs_table.no_changes")}</p>];
        }
    };

    return (
        <div>
            {auditLogs.length > 0 ? (
                <>
                    <div className="audit-logs-table-responsive">
                        <table className="audit-logs-table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort(AuditLogSortBy.DATE)} style={{ cursor: 'pointer' }}>
                                        {t("audit_logs_table.date_header")}
                                        {selectedSortColumn === AuditLogSortBy.DATE ? (
                                            selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                        ) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort(AuditLogSortBy.TIME)} style={{ cursor: 'pointer' }}>
                                        {t("audit_logs_table.time_header")}
                                        {selectedSortColumn === AuditLogSortBy.TIME ? (
                                            selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                        ) : <FaSort />}
                                    </th>
                                    <th>{t("audit_logs_table.entity_name_header")}</th>
                                    <th>{t("audit_logs_table.entity_id_header")}</th>
                                    <th>{t("audit_logs_table.employee_header")}</th>
                                    <th>{t("audit_logs_table.action_header")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditLogs.map(log => {
                                    return (
                                        <React.Fragment key={log.audit_log_id}>
                                            <tr className={`log-row ${expandedLog === log.audit_log_id ? 'expanded' : ''}`} onClick={() => handleRowClick(log.audit_log_id)}>
                                                <td>{format(parseISO(log.log_date), 'dd.MM.yyyy')}</td>
                                                <td>{log.log_time}</td>
                                                <td>{log.entity_name}</td>
                                                <td>{log.entity_id}</td>
                                                <td>{`${log.employee.first_name} ${log.employee.last_name}`}</td>
                                                <td className={`row-${log.action.toLowerCase()}`}>{log.action}</td>
                                            </tr>
                                            {expandedLog === log.audit_log_id && (
                                                <tr className="details-row">
                                                    <td colSpan={6} className="details-content">
                                                        {renderChanges(log.old_value, log.new_value, log.action)}
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <p className="text-center my-0">{t("audit_logs_table.empty_message")}</p>
            )}
        </div>
    );
};

export default AuditLogsTable;
