import "./tenant-general-section.css";

import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import useEditPermission from "../../../../hooks/useEditPermission";
import { ITenant, LeadType } from "../../../../services/tenantService";
import CustomPhoneInput from "../../../../components/custom-phone-input/custom-phone-input";
import NationalitySelect from "../../../../components/nationality-select/nationality-select";
import GenderSelect from "../../../../components/gender-select/gender-select";
import SidebarSelectField, { SidebarSelectFieldOptions } from "../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field";
import DateSelect from "../../../../components/date-select/date-select";

interface TenantGeneralSectionProps {
    employeeRoleId: number | undefined;
    tenant: ITenant;
    firstnameError: string;
    lastnameError: string;
    nationalityError: string;
    emailError: string;
    phoneNumberError: string;
    genderError: string;
    leadError: string;
    birthdateError: string;
    onUpdateTenant: (tenant: ITenant) => void;
    onClearError: (field: String) => void;
}

const TenantGeneralSection: React.FC<TenantGeneralSectionProps> = ({
    employeeRoleId,
    tenant,
    firstnameError,
    lastnameError,
    nationalityError,
    emailError,
    phoneNumberError,
    genderError,
    leadError,
    birthdateError,
    onUpdateTenant,
    onClearError
}) => {
    const hasEditPermission = useEditPermission(employeeRoleId);

    const leadOptions: SidebarSelectFieldOptions[] = Object.values(LeadType).map((lead) => ({
        label: lead.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
        value: lead,
    }));

    return (
        <ExpandableSection
            className="tenant-general-section"
            title={t("pages.tenant_detail.general_information_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                {/* BLOCKED SWITCH */}
                <div className="form-check form-switch mb-4">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="tenant-blocked-switch"
                        checked={tenant.blocked}
                        onChange={(event) => onUpdateTenant({ ...tenant, blocked: event.currentTarget.checked })}
                    />
                    <label className="form-check-label" htmlFor="tenant-blocked-switch">
                        {t("labels.blocked")}
                    </label>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="tenant-firstname"
                            label={t("labels.firstname")}
                            value={tenant.first_name}
                            errorMessage={firstnameError}
                            onChange={(text: string) => {
                                onUpdateTenant({ ...tenant, first_name: text });
                                onClearError("first_name");
                            }}
                            onClear={() => {
                                onUpdateTenant({ ...tenant, first_name: "" });
                                onClearError("first_name");
                            }}
                            placeholder="Max"
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="tenant-lastname"
                            label={t("labels.lastname")}
                            placeholder="Mustermann"
                            value={tenant.last_name}
                            errorMessage={lastnameError}
                            onChange={(text: string) => {
                                onUpdateTenant({ ...tenant, last_name: text });
                                onClearError("last_name");
                            }}
                            onClear={() => {
                                onUpdateTenant({ ...tenant, last_name: "" });
                                onClearError("last_name");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <NationalitySelect
                            nationality={tenant.nationality_code}
                            errorMessage={nationalityError}
                            onChange={(value: string) => {
                                onUpdateTenant({ ...tenant, nationality_code: value });
                                onClearError("nationality_code");
                            }}
                            required
                            labelOnTop
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <GenderSelect
                            gender={tenant.gender}
                            errorMessage={genderError}
                            onChange={(value: string) => {
                                onUpdateTenant({ ...tenant, gender: value });
                                onClearError("gender");
                            }}
                            required
                            labelOnTop
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="tenant-email"
                            type="email"
                            label={t("labels.email")}
                            placeholder="max@mustermann.de"
                            value={tenant.email}
                            errorMessage={emailError}
                            onChange={(text: string) => {
                                onUpdateTenant({ ...tenant, email: text });
                                onClearError("email");
                            }}
                            onClear={() => {
                                onUpdateTenant({ ...tenant, email: "" });
                                onClearError("email");
                            }}
                            disabled={!hasEditPermission}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomPhoneInput
                            id="tenant-phone-number"
                            defaultCountry="AE"
                            value={tenant.phone_number ?? undefined}
                            errorMessage={phoneNumberError}
                            onChange={(text: string) => {
                                onUpdateTenant({ ...tenant, phone_number: text });
                                onClearError("phone_number");
                            }}
                            onClear={() => {
                                onClearError("phone_number");
                            }}
                            disabled={!hasEditPermission}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <SidebarSelectField
                            id="tenant-creation-source-of-lead-selection"
                            label={t("labels.source_of_lead")}
                            placeholder={t("placeholders.select")}
                            value={tenant.source_of_lead}
                            onChange={(value: string) => {
                                onUpdateTenant({ ...tenant, source_of_lead: value });
                                onClearError("source_of_lead");
                            }}
                            options={leadOptions}
                            errorMessage={leadError}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <DateSelect
                            id="tenant-birthdate"
                            label={t("labels.birthdate")}
                            placeholder={t("placeholders.select_date_and_time")}
                            date={new Date(tenant.birth_date) ?? null}
                            onChange={(date) => {
                                const formattedDate = date.toISOString().split('T')[0];
                                onUpdateTenant({ ...tenant, birth_date: formattedDate });
                                onClearError("birth_date");
                            }}
                            labelOnTop
                            required
                        />
                    </div>
                </div>
            </div>
        </ExpandableSection >
    );
}

export default TenantGeneralSection;
