// CoursePage.tsx

import { useEffect } from "react";
import Switch from "../components/switch/switch";
import useCourseStore from "../stores/courseStore";
import CourseModuleItemCard from "../components/course-module-item-card/course-module-item-card";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useCourseModules } from "../hooks/useCourseModules";
import { useCourseModuleItems } from "../hooks/useCourseModuleItems";
import useAlertStore from "../stores/alertStore";
import usePageTitle from "../hooks/usePageTitle";

const CoursePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.courses.page_title"));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { selectedModuleId, setSelectedModuleId, setSelectedModuleItem } = useCourseStore();

    // Hooks
    const { courseModules } = useCourseModules();
    const {
        courseModuleItems,
        isLoading,
        size,
        setSize,
        isReachingEnd,
        error,
    } = useCourseModuleItems(selectedModuleId);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        setSelectedModuleId(Number(value));
        setSize(1);
    }

    const onClickCourseItem = (itemId: number) => {
        const selectedModuleItem = courseModuleItems.find(item => item.item_id === itemId);
        setSelectedModuleItem(selectedModuleItem ?? null);

        if (selectedModuleItem) {
            navigate(`/course/${itemId}?moduleName=${encodeURIComponent(selectedModuleItem.title)}&moduleId=${selectedModuleId}`);
        }
    }

    const onClickNewUpdate = () => {
        const module = courseModules.find(module => module.module_id === selectedModuleId);
        if (module) {
            navigate(`/course?moduleName=${encodeURIComponent(module.title)}&moduleId=${selectedModuleId}`);
        }
    }

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <Switch
                    options={courseModules.map(modules => {
                        return { label: modules.title, value: modules.module_id.toString() }
                    })}
                    value={selectedModuleId.toString()}
                    onChange={handleFilterClick}
                //onClickAdd={() => {}}
                />
                <div className="d-flex d-none d-sm-inline">
                    <CustomButton
                        title={t("pages.courses.new_video_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={onClickNewUpdate}
                    />
                </div>
            </div>

            {/* COURSE ITEMS */}
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="my-0">{t("pages.courses.title", { number: courseModuleItems.length })}</h4>
            </div>

            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <div className="spinner-border text-primary" role="status" />
                </div>
            ) : (
                courseModuleItems.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <p>{t("pages.courses.empty_message")}</p>
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={courseModuleItems.length}
                        next={loadMore}
                        hasMore={!isReachingEnd}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <div className="row mt-1 mb-2 gy-4">
                            {courseModuleItems.map((module, index) => (
                                <div className="col-12 col-sm-6 col-xl-4" key={index}>
                                    <CourseModuleItemCard
                                        title={module.title}
                                        description={module.description}
                                        thumbnail={module.thumbnail_path}
                                        published={module.published}
                                        created_at={module.created_at}
                                        onClick={() => onClickCourseItem(module.item_id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                )
            )}
        </div>
    );
};

export default CoursePage;
