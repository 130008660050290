import { API_URL } from "../utils/constants";

export interface ISalesAgent {
  employee_id: number;
  first_name: string;
  last_name: string;
  avatar: string | null;
}

export const fetchSalesAgents = async (): Promise<ISalesAgent[]> => {
  const response = await fetch(`${API_URL}/employees/sales-agents`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.salesAgents;
};
